export const schoolGradesData = {
  'Grade 1': {
 'Maths - Number and operations': [
      ' Counting review - up to 10',
      ' Count to fill a ten frame',
      ' Counting tens and ones - up to 20',
      ' Write numbers as tens and ones up to 20',
      ' Counting tens and ones - up to 99',
      ' Convert between tens and ones - multiples of ten',
      ' Write numbers as tens and ones',
      ' Regroup tens and ones - ways to make a number',
      ' Regroup tens and ones',
      ' Write numbers as hundreds, tens, and ones - up to 120',
      ' Counting review - up to 20',
      ' Count on ten frames - up to 40',
      ' Counting objects to 100',
      ' Number lines - up to 100',
      ' Counting objects to 120',
      ' Number lines - up to 120',
      ' Counting tens and ones - up to 20',
      ' Place value models up to 20',
      ' Counting tens and ones - up to 99',
      ' Place value models up to 100',
      ' Place value models up to 120',
      ' Writing numbers up to 120 in expanded form',
      ' Ten more or less',
      ' One more or less - up to 120',
      ' Comparing: more or fewer?',
      ' Compare numbers up to 10 using words',
      ' Use place value to compare numbers up to 100',
      ' Compare numbers up to 100 using words',
      ' Compare numbers up to 120 using words',
      ' Use place value to compare numbers up to 120',
      ' Number lines - up to 100',
      ' Number lines - up to 120',
      ' Put numbers in order - up to 100',
      ' Put numbers in order - up to 120',
      ' Compare numbers up to 10 using symbols',
      ' Compare numbers up to 100 using symbols',
      ' Make teen numbers: addition sentences',
      ' Use models to add a multiple of ten and a one-digit number',
      ' Add a multiple of ten and a one-digit number',
      ' Addition word problems with pictures - sums up to 10',
      ' Write addition sentences for word problems with pictures - sums up to 10',
      ' Build cube trains to solve addition word problems - sums up to 10',
      ' Addition word problems - sums up to 10',
      ' Model and write addition sentences for word problems - sums up to 10',
      ' Addition sentences for word problems - sums up to 10',
      ' Subtraction word problems with pictures - up to 10',
      ' Write subtraction sentences for word problems with pictures - up to 10',
      ' Use cube trains to solve subtraction word problems - up to 10',
      ' Subtraction word problems - up to 10',
      ' Model and write subtraction sentences for word problems - up to 10',
      ' Subtraction sentences for take apart word problems - up to 10',
      ' Subtraction sentences for word problems - up to 10',
      ' Comparison word problems up to 10: how many more or fewer?',
      ' Comparison word problems up to 10: what is the larger amount?',
      ' Comparison word problems up to 10: what is the smaller amount?',
      ' Comparison word problems up to 10',
      ' Word problems with unknown sums and differences - up to 10',
      ' Word problems with one addend unknown - up to 10',
      ' Word problems with both addends unknown - up to 10',
      ' Word problems involving addition and subtraction - up to 10',
      ' Addition word problems with models - sums up to 20',
      ' Addition word problems - sums up to 20',
      ' Addition sentences for word problems - sums up to 20',
      ' Subtraction word problems with models - up to 20',
      ' Subtraction word problems - up to 20',
      ' Subtraction sentences for word problems - up to 20',
      ' Comparison word problems up to 20: how many more or fewer?',
      ' Comparison word problems up to 20: what is the larger amount?',
      ' Comparison word problems up to 20: what is the smaller amount?',
      ' Comparison word problems up to 20: part 1',
      ' Comparison word problems up to 20: part 2',
      ' Word problems with one addend unknown - up to 20',
      ' Word problems with both addends unknown - up to 20',
      ' Use models to solve word problems involving addition and subtraction - up to 20',
      ' Word problems involving addition and subtraction without comparisons - up to 20',
      ' Word problems involving addition and subtraction - up to 20',
      ' Count to fill a ten frame',
      ' Complete the addition sentence to make ten - with models',
      ' Complete the addition sentence to make ten',
      ' Add three numbers - make ten',
      ' Addition sentences up to 10: which model matches?',
      ' Add in any order',
      ' Add by counting on - sums up to 10',
      ' Complete the addition sentence to make ten',
      ' Addition facts - sums up to 10',
      ' Take apart numbers in different ways - sums up to 10',
      ' Make a number using addition - sums up to 10',
      ' Ways to make a number - addition sentences up to 10',
      ' Subtraction sentences up to 10: which model matches?',
      ' Subtract by counting back - up to 10',
      ' Subtract by counting on - up to 10',
      ' Use addition to subtract - up to 10',
      ' Subtraction facts - up to 10',
      ' Make a number using subtraction - up to 10',
      ' Ways to make a number - subtraction sentences up to 10',
      ' Ways to subtract from a number - subtraction sentences up to 10',
      ' Fact families - up to 10',
      ' Addition and subtraction facts - up to 10',
      ' Sort addition and subtraction facts - up to 10',
      ' Related addition facts',
      ' Add using doubles plus one',
      ' Add using doubles minus one',
      ' Add three numbers - use doubles',
      ' Use ten frames to add',
      ' Make ten to add',
      ' Add three numbers - make ten',
      ' Adding 1',
      ' Adding 2',
      ' Adding 3',
      ' Adding 4',
      ' Adding 5',
      ' Adding 6',
      ' Adding 7',
      ' Adding 8',
      ' Adding 9',
      ' Adding 10',
      ' Adding 0',
      ' Addition facts - sums up to 20',
      ' Sort addition facts - sums up to 20',
      ' Make a number using addition - sums up to 20',
      ' Add three numbers',
      ' Related subtraction facts',
      ' Subtract by counting back - up to 20',
      ' Subtracting to and from 10',
      ' Use ten frames to subtract',
      ' Use ten to subtract',
      ' Use addition to subtract - up to 20',
      ' Subtract by counting on - up to 20',
      ' Count on and use ten to subtract - up to 20',
      ' Subtract doubles',
      ' Subtracting 1',
      ' Subtracting 2',
      ' Subtracting 3',
      ' Subtracting 4',
      ' Subtracting 5',
      ' Subtracting 6',
      ' Subtracting 7',
      ' Subtracting 8',
      ' Subtracting 9',
      ' Subtracting 10',
      ' Subtracting 0',
      ' Subtraction facts - up to 20',
      ' Sort subtraction facts - up to 20',
      ' Make a number using subtraction - up to 20',
      ' Addition and subtraction facts - up to 20',
      ' Addition and subtraction - ways to make a number',
      ' Use ten to add - sums up to 20',
      ' Add by counting on from teen numbers - sums up to 20',
      ' Add with cubes - sums up to 10',
      ' Add with pictures - sums up to 10',
      ' Addition sentences up to 10: what does the model show?',
      ' Turn words into an addition sentence - sums up to 10',
      ' Subtract with cubes - up to 10',
      ' Subtract with pictures - up to 10',
      ' Subtraction sentences up to 10: what does the model show?',
      ' Subtraction sentences up to 10: what does the cube train show?',
      ' Turn words into a subtraction sentence - up to 10',
      ' Use ten frames to add',
      ' Match word problems to addition and subtraction sentences - up to 10',
      ' Match word problems to addition and subtraction sentences - up to 20'
    ],
    'Maths - Algebraic reasoning': [
      ' Names and values of common coins',
      ' Names and values of all coins',
      ' Equivalent groups of coins',
      ' Exchanging coins - with pictures',
      ' Exchanging coins',
      ' Names and values of common coins',
      ' Names and values of all coins',
      ' Names and values of common coins',
      ' Names and values of all coins',
      ' Count groups of like coins: pennies, nickels, or dimes',
      ' Count pennies, nickels, and dimes',
      ' Counting forward - up to 100',
      ' Counting backward - up to 100',
      ' Counting on the hundred chart',
      ' Count up to find the next number - up to 120',
      ' Count down to find the next number - up to 120',
      ' Counting on a number chart - up to 120',
      ' Sequences - count up and down by 1',
      ' Skip-counting by twos',
      ' Skip-counting by fives',
      ' Skip-counting by tens - up to 120',
      ' Skip-counting by twos, fives, and tens - up to 120',
      ' Ten more or less',
      ' Ten more or less - up to 120',
      ' Hundred chart - ten more or less',
      ' Number chart up to 120 - ten more or less',
      ' Addition sentences for word problems - sums up to 10',
      ' Subtraction sentences for word problems - up to 10',
      ' Addition sentences for word problems - sums up to 20',
      ' Subtraction sentences for word problems - up to 20',
      ' Addition sentences: true or false?',
      ' Addition sentences: which is true?',
      ' Subtraction sentences: true or false?',
      ' Subtraction sentences: which is true?',
      ' Addition and subtraction sentences to 20: which is true?',
      ' Which sign makes the number sentence true?',
      ' Complete the addition sentence to make ten',
      ' Use addition to subtract - up to 10',
      ' Relate addition and subtraction sentences - up to 20',
      ' Use addition to subtract - up to 20',
      ' Complete the addition sentence - sums up to 10',
      ' Complete the subtraction sentence - up to 10',
      ' Complete the addition sentence - sums up to 20',
      ' Complete the subtraction sentence - up to 20',
      ' Complete the addition and subtraction equations - up to 20',
      ' Balance addition and subtraction equations - up to 10',
      ' Balance addition and subtraction equations - up to 20',
      ' Add three numbers - use doubles',
      ' Add three numbers - make ten',
      ' Add three numbers - word problems'
    ],
    'Maths - Geometry and measurement': [
      ' Select two-dimensional shapes',
      ' Compare shapes using attributes',
      ' Select two-dimensional shapes',
      ' Compare sides and corners',
      ' Two-dimensional and three-dimensional shapes',
      ' Compare vertices, edges, and faces',
      ' Identify faces of three-dimensional shapes',
      ' Identify shapes traced from solids',
      ' Symmetry',
      ' Select three-dimensional shapes',
      ' Compose two-dimensional shapes using up to 4 smaller shapes',
      ' Two-dimensional shapes in the real world',
      ' Select two-dimensional shapes',
      ' Name the two-dimensional shape',
      ' Rhombuses',
      ' Count sides and corners',
      ' Cubes and rectangular prisms',
      ' Name the three-dimensional shape',
      ' Count vertices, edges, and faces',
      ' Three-dimensional shapes in the real world I',
      ' Three-dimensional shapes in the real world II',
      ' Select three-dimensional shapes',
      ' Compose two-dimensional shapes',
      ' Equal parts - 2 and 4 equal parts',
      ' Make halves',
      ' Make fourths',
      ' Make halves and fourths',
      ' Make halves and fourths in different ways',
      ' Identify halves',
      ' Identify fourths',
      ' Identify halves and fourths',
      ' Measure using an inch ruler',
      ' Measure using a centimeter ruler',
      ' Measure length with objects',
      ' Build cube trains to measure length',
      ' Measure length with objects',
      ' Build cube trains to measure length',
      ' Measure length with objects',
      ' Build cube trains to measure length',
      ' Measure using an inch ruler',
      ' Customary units of length: word problems',
      ' Measure using a centimeter ruler',
      ' Metric units of length: word problems',
      ' Match digital clocks and times',
      ' Match analog clocks and times: to the half hour',
      ' Match analog and digital clocks: to the half hour',
      ' Read clocks and write times'
    ],
    'Maths - Data analysis': [
      ' Which picture graph is correct?',
      ' Which tally chart is correct?',
      ' Which table is correct?',
      ' Which picture graph is correct?',
      ' Which bar graph is correct?',
      ' Interpret picture graphs',
      ' Interpret tally charts',
      ' Interpret data in tables',
      ' Interpret bar graphs I',
      ' Interpret bar graphs II'
    ],
    'Maths - Miscellaneous': [
      'Counting to 120',
      'Understanding place value (ones and tens)',
      'Addition and subtraction within 20',
      'Adding three numbers',
      'Basic word problems with addition and subtraction',
      'Recognizing and drawing shapes',
      'Understanding halves and fourths',
      'Telling time to the half-hour',
      'Measuring length using standard units',
      'Collecting and organizing data into charts'
    ],
    Science: [
      'Basic life cycles (plants, animals)',
      'Introduction to the five senses',
      'Exploring weather and seasons',
      'Identifying materials and their uses',
      'Understanding how things move (pushes and pulls)',
      'Basic astronomy (sun, moon, and stars)',
      'Living vs. non-living things',
      'Introduction to simple experiments'
    ]
  },
  "Grade 2": {
    'Maths - Number and operations': [
      ' Place value models - tens and ones',
      ' Place value models - up to hundreds',
      ' Convert to/from a number - tens and ones',
      ' Regroup tens and ones - ways to make a number',
      ' Regroup tens and ones',
      ' Convert to/from a number - up to hundreds',
      ' Convert between place values - ones and hundreds',
      ' Convert between place values - ones, tens, and hundreds',
      ' Place value models - up to 1,200',
      ' Convert to/from a number - up to 1,200',
      ' Regroup numbers up to 1,200 - ways to make a number',
      ' Spell word names for numbers up to 20',
      ' Writing numbers up to 100 in words - convert words to digits',
      ' Writing numbers up to 100 in words - convert digits to words',
      ' Writing numbers up to 1,000 in words - convert words to digits',
      ' Writing numbers up to 1,000 in words - convert digits to words',
      ' Writing numbers up to 1,000 in words',
      ' Identify a digit up to the hundreds place',
      ' Value of a digit - tens and ones',
      ' Value of a digit - up to hundreds',
      ' Value of a digit - up to thousands',
      ' Convert from expanded form - up to hundreds',
      ' Writing numbers up to to 1,200 in words',
      ' Convert to/from a number - up to 1,200',
      ' Count forward and backward by fives and tens',
      ' Count forward and backward by twos, fives, and tens',
      ' Count forward and backward by fives, tens, and hundreds',
      ' Count forward and backward by twos, fives, tens, and hundreds',
      ' Comparing numbers up to 100',
      ' Comparing numbers up to 1,000',
      ' Put numbers up to 100 in order',
      ' Put numbers up to 1,000 in order',
      ' Comparing numbers up to 1,200',
      ' Put numbers up to 1,200 in order',
      ' Number lines - up to 100',
      ' Number lines - up to 1,000',
      ' Fill in numbers on number lines - up to 1,200',
      ' Number lines - up to 100',
      ' Number lines - up to 1,000',
      ' Fill in numbers on number lines - up to 1,200',
      ' Equal parts',
      ' Make halves',
      ' Make fourths',
      ' Make halves, fourths, and eighths',
      ' Make eighths',
      ' Select fractions equivalent to whole numbers using models',
      ' Identify halves',
      ' Identify fourths',
      ' Identify eighths',
      ' Identify the fraction - halves, fourths, and eighths',
      ' Which shape illustrates the fraction? - halves, fourths, and eighths',
      ' Add zero',
      ' Add one-digit numbers - sums to 10',
      ' Add one-digit numbers - sums to 20',
      ' Sort addition facts - sums to 20',
      ' Complete the addition sentence - sums to 20',
      ' Subtract zero or all',
      ' Subtract one-digit numbers - up to 10',
      ' Subtract a one-digit number from a two-digit number up to 20',
      ' Complete the subtraction sentence - up to 20',
      ' Addition and subtraction - up to 20',
      ' Ways to make a number using subtraction - up to 10',
      ' Break apart a one-digit number to add',
      ' Use number lines to add two-digit numbers',
      ' Break apart a two-digit number to add - sums to 100',
      ' Use compensation to add on a number line - up to two digits',
      ' Use compensation to add - up to two digits',
      ' Use models to add two-digit numbers - without regrouping',
      ' Use models to add two-digit numbers - with regrouping',
      ' Use place value to add two-digit numbers - without regrouping',
      ' Use place value to add two-digit numbers - with regrouping',
      ' Add a two-digit and a one-digit number - without regrouping',
      ' Add a two-digit and a one-digit number - with regrouping',
      ' Add two-digit numbers without regrouping - sums to 100',
      ' Add two-digit numbers with regrouping - sums to 100',
      ' Add two-digit numbers - sums to 200',
      ' Add three numbers up to two digits each',
      ' Add four numbers up to two digits each',
      ' Break apart a one-digit number to subtract',
      ' Use models to subtract a one-digit number from a two-digit number - without regrouping',
      ' Use models to subtract a one-digit number from a two-digit number - with regrouping',
      ' Use number lines to subtract two-digit numbers',
      ' Break apart a two-digit number to subtract',
      ' Use compensation to subtract on a number line - up to two digits',
      ' Use compensation to subtract - up to two digits',
      ' Count on to subtract two-digit numbers',
      ' Use models to subtract two-digit numbers - without regrouping',
      ' Use models to subtract two-digit numbers - with regrouping',
      ' Use place value to subtract two-digit numbers - without regrouping',
      ' Use place value to subtract two-digit numbers - with regrouping',
      ' Subtract a one-digit number from a two-digit number - without regrouping',
      ' Subtract a one-digit number from a two-digit number - with regrouping',
      ' Subtract two-digit numbers - without regrouping',
      ' Subtract two-digit numbers - with regrouping',
      ' Use models to add three-digit numbers - without regrouping',
      ' Use models to add three-digit numbers - with regrouping',
      ' Use expanded form to add three-digit numbers - without regrouping',
      ' Use expanded form to add three-digit numbers - with regrouping',
      ' Addition word problems - sums to 20',
      ' Addition word problems - three one-digit numbers',
      ' Subtraction word problems - up to 20',
      ' Comparison word problems - up to 20',
      ' Use models to solve addition and subtraction word problems - up to 20',
      ' Addition and subtraction word problems - up to 20',
      ' Two-step addition and subtraction word problems - up to 20',
      ' Addition word problems - up to two digits',
      ' Addition word problems - three numbers up to two digits each',
      ' Addition word problems - four numbers up to two digits each',
      ' Subtraction word problems - up to two digits',
      ' Use models to solve addition and subtraction word problems - up to 100',
      ' Addition and subtraction word problems - up to 100',
      ' Two-step addition and subtraction word problems - up to 100',
      ' Addition word problems - up to three digits',
      ' Subtraction word problems - up to three digits',
      ' Do you have enough money? - up to $1',
      ' Correct amount of change',
      ' Add and subtract money up to $1: word problems',
      ' Perimeter: word problems',
      ' Match word problems to addition and subtraction sentences - up to 20',
      ' Match addition and subtraction word problems to equations - up to 100',
      ' Names and values of common coins',
      ' Names and values of all coins',
      ' Count money - pennies, nickels, and dimes only',
      ' Count money up to $1',
      ' Equivalent amounts of money up to $1',
      ' Exchanging money',
      ' Exchanging money II',
      ' Do you have enough money? - up to $1',
      ' How much more to make a dollar?',
      ' Names and values of common coins',
      ' Names and values of all coins',
      ' Count money - pennies, nickels, and dimes only',
      ' Count money up to $1',
      ' Count money up to $5',
      ' Do you have enough money? - up to $5',
      ' Write dollar amounts - up to $1',
      ' Count equal groups',
      ' Identify repeated addition for equal groups - sums to 25',
      ' Write addition sentences for equal groups - sums to 25',
      ' Identify repeated addition for arrays - sums to 25',
      ' Write addition sentences for arrays - sums to 25',
      ' Solve word problems using repeated addition - sums to 25',
      ' Identify repeated addition for arrays - sums to 10',
      ' Write addition sentences for arrays - sums to 10',
      ' Divide by counting equal groups',
      ' Write division sentences for groups'
    ],
    'Maths - Algebraic reasoning': [
      ' Even or odd number of shapes - up to 20',
      ' Identify even and odd numbers - up to 20',
      ' Add 10 or 100',
      ' Subtract 10 or 100',
      ' Input/output tables - add and subtract by 10',
      ' Input/output tables - add and subtract by 100',
      ' Input/output tables - add and subtract by 10 or 100',
      ' Addition word problems - sums to 20',
      ' Addition sentences for word problems - sums to 20',
      ' Subtraction word problems - up to 20',
      ' Subtraction sentences for word problems - up to 20',
      ' Addition and subtraction word problems - up to 20',
      ' Two-step addition and subtraction word problems - up to 20',
      ' Addition word problems - up to two digits',
      ' Write the addition sentence - up to two digits',
      ' Subtraction word problems - up to two digits',
      ' Write the subtraction sentence - up to two digits',
      ' Use models to solve addition and subtraction word problems - up to 100',
      ' Addition and subtraction word problems - up to 100',
      ' Match addition and subtraction word problems to equations - up to 100',
      ' Two-step addition and subtraction word problems - up to 100',
      ' Addition word problems - up to three digits',
      ' Write the addition sentence - up to three digits',
      ' Subtraction word problems - up to three digits',
      ' Write the subtraction sentence - up to three digits'
    ],
    'Maths - Geometry and measurement': [
      ' Draw polygons',
      ' Three-dimensional shapes in the real world I',
      ' Three-dimensional shapes in the real world II',
      ' Select three-dimensional shapes',
      ' Count sides and vertices',
      ' Identify quadrilaterals',
      ' Identify pentagons',
      ' Name polygons: up to 12 sides',
      ' Classify polygons: up to 12 sides',
      ' Sort two-dimensional shapes',
      ' Compose two-dimensional shapes',
      ' Measure length with inch cubes',
      ' Measure with different customary units: inches, feet, and yards',
      ' Measure with different metric units',
      ' Number lines - up to 100',
      ' Number lines - up to 1,000',
      ' Measure using an inch ruler',
      ' Measure using a centimeter ruler',
      ' Measure to compare length in inches',
      ' Which customary unit of length is appropriate: inches or feet?',
      ' Which customary unit of length is appropriate: inches, feet, or yards?',
      ' Compare lengths: customary units',
      ' Customary units of length: word problems',
      ' Measure to compare length in centimeters',
      ' Which metric unit of length is appropriate?',
      ' Compare lengths: metric units',
      ' Metric units of length: word problems',
      ' Tile a rectangle with squares',
      ' Area',
      ' Tile a rectangle and find the area',
      ' Select figures with a given area',
      ' Create rectangles with a given area',
      ' A.M. or P.M.',
      ' Match analog clocks and times to the minute',
      ' Match analog and digital clocks to the minute',
      ' Read analog clocks and write times to the minute',
      ' Compare clocks to the minute',
      ' Time patterns to the minute'
    ],
    'Maths - Data analysis': [
      ' Interpret picture graphs',
      ' Interpret bar graphs I',
      ' Create picture graphs',
      ' Create bar graphs',
      ' Create picture graphs II',
      ' Interpret picture graphs',
      ' Interpret bar graphs II',
      ' Interpret picture graphs II',
      ' Interpret picture graphs',
      ' Interpret bar graphs II',
      ' Which bar graph is correct?',
      ' Interpret picture graphs II'
    ],
    "Maths - Miscellaneous": [
      "Counting to 1,000: Learn to count sequentially up to 1,000 through number charts and counting exercises.",
      "Skip counting by 2s, 5s, and 10s: Practice skip counting using songs, number lines, and skip counting worksheets.",
      "Place value (hundreds, tens, and ones): Understand the value of each digit in a three-digit number with place value blocks and charts.",
      "Comparing and ordering 3-digit numbers: Use comparison symbols and ordering activities to arrange numbers from smallest to largest.",
      "Addition within 100: Solve addition problems using objects, drawings, and number sentences up to 100.",
      "Subtraction within 100: Practice subtracting numbers up to 100 using subtraction mats and story problems.",
      "Addition and subtraction within 1,000: Extend addition and subtraction skills to larger numbers with regrouping exercises.",
      "Adding and subtracting whole hundreds: Focus on adding and subtracting multiples of 100 through practical examples.",
      "Adding up to four 2-digit numbers: Combine multiple addition problems into single sums using column addition methods.",
      "Word problems involving addition and subtraction: Enhance problem-solving skills by solving real-life addition and subtraction scenarios.",
      "Introduction to 3-digit numbers: Explore the structure and reading of three-digit numbers through interactive games.",
      "Working with 3-digit numbers: Engage in activities that involve comparing, ordering, and performing operations with three-digit numbers.",
      "Odd and even numbers: Identify and classify numbers as odd or even using sorting activities and number charts.",
      "Ordinal numbers: Learn the position of objects in a sequence using ordinal number exercises and ordering tasks.",
      "Mental arithmetic strategies (e.g., making tens): Develop mental math skills by practicing strategies like making tens and breaking numbers apart.",
      "Basic multiplication concepts (e.g., repeated addition): Introduce multiplication as repeated addition through grouping activities and arrays.",
      "Basic division concepts (e.g., sharing equally): Explain division as sharing equally using manipulatives and simple division problems.",
      "Time telling to the hour and half-hour: Read analog and digital clocks to tell time to the nearest hour and half-hour with clock worksheets.",
      "Time telling to the nearest five minutes: Practice reading time to the nearest five minutes using clock models and time-telling exercises.",
      "Reading analog and digital clocks: Compare and interpret both analog and digital clock formats through matching and drawing activities.",
      "Understanding a.m. and p.m.: Differentiate between morning and afternoon times using daily schedules and clock activities.",
      "Coin values and counting coins: Identify different coins and their values, and practice counting money with coin manipulatives.",
      "Making coin combinations to make up given amounts: Solve money problems by creating various combinations of coins to reach specific amounts.",
      "Money concepts and calculations: Learn to add and subtract money amounts through practical transactions and money worksheets.",
      "Identifying and comparing shapes (2D and 3D shapes): Recognize and describe different two-dimensional and three-dimensional shapes using shape sorting activities.",
      "Understanding symmetry: Explore symmetrical shapes and create symmetrical drawings using mirrors and symmetry worksheets.",
      "Basic fractions (halves, thirds, quarters): Introduce simple fractions through dividing shapes and objects into equal parts.",
      "Measurement of length, weight, and capacity using standard units: Measure objects using rulers, scales, and measuring cups with hands-on activities.",
      "Comparing measurements: Compare the length, weight, and capacity of different objects using measurement tools and comparison charts.",
      "Collecting and organizing data: Gather data through surveys and sort information using tables and charts.",
      "Reading and creating simple graphs (bar graphs, picture graphs, line plots): Interpret and create various types of simple graphs using data collected from the classroom.",
      "Patterns and sequences (identifying and extending patterns): Identify, create, and extend patterns using colors, shapes, and numbers in sequence activities.",
      "Recognizing and creating patterns with numbers and shapes: Develop pattern recognition skills by working with numerical and geometric patterns through interactive games.",
      "Introduction to basic geometry (lines, corners, and angles): Learn about basic geometric terms and identify different types of lines and angles in shapes.",
      "Understanding and using tools for measurement (ruler, measuring tape): Practice using measurement tools accurately through guided measurement activities.",
      "Basic data interpretation (reading simple charts and graphs): Enhance data interpretation skills by reading and analyzing simple charts and graphs from various sources."
    ],
    "Science": [
      "Plants and their parts (roots, stems, leaves, flowers): Study the different parts of a plant and complete a label-the-plant diagram as homework.",
      "Plant life cycles: Learn about the stages of a plant's life cycle through drawing and observation activities.",
      "Animal life and habitats: Explore various animal lives and their natural habitats using habitat dioramas and research projects.",
      "Animal classification (mammals, birds, reptiles, amphibians, fish, insects): Classify animals into different groups and create classification charts.",
      "Food chains and life cycles: Understand how animals and plants are interconnected in food chains through diagram drawing and storytelling.",
      "Ecosystems and habitats (deserts, forests, oceans): Investigate different ecosystems by creating habitat models and completing related worksheets.",
      "Weather and seasons: Study different weather patterns and seasonal changes through weather charts and seasonal activities.",
      "The water cycle: Learn about the stages of the water cycle with interactive diagrams and water cycle crafts.",
      "Earth’s resources (soil, rocks, water, air): Identify and explore Earth's natural resources through hands-on exploration and resource sorting tasks.",
      "Conservation and recycling: Understand the importance of conservation and recycling by participating in classroom recycling projects.",
      "Matter and its states (solid, liquid, gas): Explore the three states of matter with experiments that demonstrate changes in state.",
      "Physical properties of objects (texture, flexibility, strength): Investigate and describe objects based on their physical properties through tactile activities.",
      "Simple machines (lever, pulley, wheel and axle, inclined plane, screw): Learn about basic machines by building simple models and identifying their uses.",
      "Force and motion (pushes and pulls): Explore how forces affect motion through experiments with pushing and pulling objects.",
      "Gravity: Understand the concept of gravity by observing how objects fall and discussing why things stay grounded.",
      "Magnets and their properties: Discover how magnets work by testing magnetic and non-magnetic materials through hands-on experiments.",
      "Heat and its effects (melting, boiling): Observe and record how heat changes the state of different materials through heating experiments.",
      "Sound and vibrations: Learn how sound is produced and travels by experimenting with different sound sources and vibration activities.",
      "Light and shadows: Explore how light creates shadows through shadow tracing and light experiments using flashlights.",
      "Basic astronomy concepts: Introduce basic astronomy by learning about celestial bodies and observing the night sky.",
      "The sun, moon, planets, and stars: Study the main components of our solar system through models and space-related projects.",
      "Phases of the moon: Learn about the different phases of the moon by tracking the moon over several weeks and drawing observations.",
      "Day and night: Understand the cycle of day and night by observing daily changes and discussing the Earth's rotation.",
      "Space exploration: Discover basic facts about space exploration and famous space missions through videos and presentations.",
      "Simple investigations and experiments: Conduct simple science experiments to explore hypotheses and observe outcomes.",
      "Making observations: Develop observation skills by using senses to explore and record findings in science journals.",
      "Recording data (charts, graphs): Practice recording scientific data using charts and graphs to represent observations.",
      "Scientific inquiry (asking questions, making predictions): Engage in scientific inquiry by formulating questions and making predictions before experiments.",
      "Health and human body basics (senses, nutrition, hygiene): Learn about the human body, senses, and the importance of nutrition and hygiene through interactive lessons.",
      "Life cycles of insects (e.g., butterfly, beetle): Study the life cycles of different insects by observing metamorphosis and creating life cycle charts.",
      "Environmental awareness (pollution, conservation): Discuss environmental issues and ways to help conserve the environment through projects and discussions.",
      "Properties of water (floating and sinking): Explore why some objects float or sink in water through hands-on experiments and observation activities.",
      "Introduction to fossils and paleontology: Learn about fossils and how scientists study ancient life forms through fossil digging activities and creating fossil replicas.",
      "Weather instruments and measurements: Identify and use basic weather instruments like thermometers and rain gauges to measure and record weather data.",
      "Introduction to ecosystems and biodiversity: Understand the variety of life in different ecosystems and the importance of biodiversity through interactive lessons and habitat projects."
    ]
  },
  'Grade 3': {
    'Maths - Number and operations': [
      ' Place value models up to thousands',
      ' Value of a digit up to ten thousands',
      ' Convert to/from a number',
      ' Convert between standard and expanded form',
      ' Convert from expanded form',
      ' Convert between place values',
      ' Rounding: up to ten thousands place',
      ' Round using a number line',
      ' Which number is greatest/least?',
      ' Compare numbers up to 100,000',
      ' Order numbers up to 100,000',
      ' Understand fractions: fraction bars',
      ' Understand fractions: area models',
      ' Show fractions: fraction bars',
      ' Show fractions: area models',
      ' Match fractions to models: halves, thirds, and fourths',
      ' Match unit fractions to models',
      ' Match fractions to models',
      ' Fractions of number lines: unit fractions',
      ' Fractions of number lines: halves, fourths, and eighths',
      ' Fractions of number lines',
      ' Graph unit fractions on number lines',
      ' Graph fractions on number lines',
      ' Fractions of number lines: unit fractions',
      ' Identify unit fractions on number lines',
      ' Identify fractions on number lines',
      ' Match unit fractions to models',
      ' Unit fractions: modeling word problems',
      ' Understand fractions: fraction bars',
      ' Understand fractions: area models',
      ' Decompose fractions into unit fractions',
      ' Fractions of number lines',
      ' Unit fractions: modeling word problems',
      ' Unit fractions: word problems',
      ' Fractions of a whole: modeling word problems',
      ' Fractions of a whole: word problems',
      ' Find equivalent fractions using fraction strips',
      ' Find equivalent fractions using area models: one model',
      ' Identify equivalent fractions on number lines',
      ' Graph equivalent fractions on number lines',
      ' Graph fractions equivalent to 1 on number lines',
      ' Find equivalent fractions using area models: two models',
      ' Find equivalent fractions using area models: one model',
      ' Find equivalent fractions using number lines',
      ' Graph and compare fractions with like denominators on number lines',
      ' Graph and compare fractions with like numerators on number lines',
      ' Compare fractions using models',
      ' Compare fractions using number lines',
      ' Graph and compare fractions on number lines',
      ' Compare fractions',
      ' Compare fractions in recipes',
      ' Use number lines to add three-digit numbers',
      ' Use compensation to add: up to three digits',
      ' Use expanded form to add three-digit numbers',
      ' Add two numbers up to three digits',
      ' Add two numbers up to three digits: word problems',
      ' Complete the addition sentence: up to three digits',
      ' Balance addition equations: up to three digits',
      ' Addition up to three digits: fill in the missing digits',
      ' Add three numbers up to three digits each',
      ' Add three numbers up to three digits each: word problems',
      ' Use number lines to subtract three-digit numbers',
      ' Use compensation to subtract: up to three digits',
      ' Subtract numbers up to three digits',
      ' Subtract numbers up to three digits: word problems',
      ' Complete the subtraction sentence: up to three digits',
      ' Balance subtraction equations: up to three digits',
      ' Complete the addition or subtraction sentence: up to three digits',
      ' Addition and subtraction word problems',
      ' Complete the equation using properties of addition',
      ' Add, subtract, multiply, and divide',
      ' Addition, subtraction, multiplication, and division word problems',
      ' Two-step addition and subtraction word problems',
      ' Comparing: with addition and subtraction',
      ' Round to the nearest ten or hundred',
      ' Rounding puzzles',
      ' Estimate sums by rounding: up to 1,000',
      ' Estimate sums using compatible numbers',
      ' Estimate differences by rounding: up to 1,000',
      ' Estimate differences using compatible numbers',
      ' Count coins and bills - up to $5 bill',
      ' Count coins and bills word problems - up to $5 bill',
      ' Purchases - do you have enough money - up to $10',
      ' Which picture shows more?',
      ' Count equal groups',
      ' Skip-counting',
      ' Skip-counting puzzles',
      ' Identify multiplication expressions for equal groups',
      ' Write multiplication sentences for equal groups',
      ' Relate addition and multiplication for equal groups',
      ' Multiply by 0 or 1 with equal groups',
      ' Identify multiplication expressions for arrays',
      ' Write multiplication sentences for arrays',
      ' Make arrays to model multiplication',
      ' Write multiplication sentences for number lines',
      ' Relate addition and multiplication',
      ' Write two multiplication sentences for an array',
      ' Multiply one-digit numbers using grids',
      ' Multiply one-digit numbers by two-digit numbers using area models I',
      ' Multiply by 2',
      ' Multiply by 3',
      ' Multiply by 4',
      ' Multiply by 5',
      ' Multiply by 6',
      ' Multiply by 7',
      ' Multiply by 8',
      ' Multiply by 9',
      ' Multiply by 10',
      ' Multiplication facts for 2, 3, 4, 5, and 10',
      ' Multiplication facts for 2, 3, 4, 5, and 10: true or false?',
      ' Multiplication facts for 6, 7, 8, and 9',
      ' Multiplication facts for 6, 7, 8, and 9: true or false?',
      ' Multiplication facts up to 10',
      ' Multiplication facts up to 10: true or false?',
      ' Multiplication sentences up to 10: true or false?',
      ' Divide by 2',
      ' Divide by 3',
      ' Divide by 4',
      ' Divide by 5',
      ' Divide by 6',
      ' Divide by 7',
      ' Divide by 8',
      ' Divide by 9',
      ' Divide by 10',
      ' Division facts for 2, 3, 4, 5, and 10',
      ' Division facts for 2, 3, 4, 5, and 10: true or false?',
      ' Division facts for 6, 7, 8, and 9',
      ' Division facts for 6, 7, 8, and 9: true or false?',
      ' Division facts up to 10',
      ' Division facts up to 10: true or false?',
      ' Division sentences up to 10: true or false?',
      ' Multiplication and division facts up to 5: true or false?',
      ' Multiplication and division facts up to 10: true or false?',
      ' Properties of multiplication',
      ' Solve using properties of multiplication',
      ' Distributive property: find the missing factor',
      ' Multiply using the distributive property',
      ' Multiply by a multiple of ten using place value',
      ' Multiply by a multiple of ten',
      ' Multiply one-digit numbers by teen numbers using grids',
      ' Multiply one-digit numbers by two-digit numbers using area models I',
      ' Multiply one-digit numbers by two-digit numbers using area models II',
      ' Multiply one-digit numbers by two-digit numbers',
      ' Multiply one-digit numbers by two-digit numbers: word problems',
      ' Divide by counting equal groups',
      ' Write division sentences for groups',
      ' Even or odd',
      ' Multiplication facts up to 10: find the missing factor',
      ' Multiplication word problems with factors up to 10: find the missing number',
      ' Relate multiplication and division',
      ' Division facts up to 10: find the missing number',
      ' Use equal groups and arrays to solve multiplication word problems',
      ' Use strip models to solve multiplication word problems',
      ' Multiplication word problems with factors up to 10',
      ' Compare numbers using multiplication: word problems',
      ' Use equal groups to solve division word problems',
      ' Use arrays to solve division word problems',
      ' Use equal groups and arrays to solve division word problems',
      ' Division word problems',
      ' Multiplication and division word problems',
      ' Two-step multiplication and division word problems'
    ],
    'Maths - Algebraic reasoning': [
      ' Add two numbers up to three digits: word problems',
      ' Subtract numbers up to three digits: word problems',
      ' Addition and subtraction word problems',
      ' Two-step addition and subtraction word problems',
      ' Multiplication word problems with factors up to 10',
      ' Division word problems',
      ' Two-step multiplication and division word problems',
      ' Compare numbers using multiplication',
      ' Compare numbers using multiplication: word problems',
      ' Comparison word problems: addition or multiplication?',
      ' Multiplication facts up to 10: find the missing factor',
      ' Multiplication facts up to 12: find the missing factor',
      ' Multiplication facts up to 12: select the missing factors',
      ' Division facts up to 10: find the missing number',
      ' Division facts up to 12: find the missing number',
      ' Solve for the unknown number: multiplication and division only',
      ' Multiplication input/output tables',
      ' Multiplication input/output tables: find the rule',
      ' Addition input/output tables: up to three digits',
      ' Subtraction input/output tables: up to three digits',
      ' Division input/output tables',
      ' Division input/output tables: find the rule'
    ],
    'Maths - Geometry and measurement': [
      ' Three-dimensional shapes in the real world I',
      ' Three-dimensional shapes in the real world II',
      ' Attributes of polygons',
      ' Identify polygons: up to 12 sides',
      ' Count and compare sides and vertices',
      ' Acute, obtuse, and right triangles',
      ' Scalene, isosceles, and equilateral triangles',
      ' Identify three-dimensional shapes',
      ' Count vertices, edges, and faces',
      ' Identify faces of three-dimensional shapes',
      ' Parallel sides in quadrilaterals',
      ' Attributes of quadrilaterals',
      ' Identify rectangles',
      ' Identify parallelograms',
      ' Identify rhombuses',
      ' Identify trapezoids',
      ' Classify squares, rectangles, rhombuses, parallelograms, and trapezoids',
      ' Draw squares, rectangles, rhombuses, parallelograms, and trapezoids',
      ' Find the area of rectangles and squares',
      ' Find the missing side length of a rectangle',
      ' Find the area of rectangles with missing unit squares',
      ' Find the area of rectangles: word problems',
      ' Find the area of complex figures by dividing them into rectangles',
      ' Find the area of complex figures',
      ' Fractions of number lines: halves, fourths, and eighths',
      ' Graph unit fractions on number lines',
      ' Graph fractions on number lines',
      ' Perimeter of figures on grids',
      ' Perimeter of rectangles',
      ' Perimeter of rectilinear shapes',
      ' Perimeter of polygons',
      ' Perimeter: find the missing side length',
      ' Perimeter: word problems',
      ' Compare area and perimeter of two figures',
      ' Find the end time',
      ' Time patterns',
      ' Which customary unit of weight is appropriate?',
      ' Which customary unit of volume is appropriate?',
      ' Which metric unit of volume is appropriate?',
      ' Choose the appropriate measuring tool'
    ],
    'Maths - Data analysis': [
      ' Create scaled bar graphs',
      ' Create scaled picture graphs',
      ' Create line plots',
      ' Interpret tally charts',
      ' Interpret scaled bar graphs',
      ' Use bar graphs to solve problems',
      ' Interpret scaled picture graphs',
      ' Interpret line plots',
      ' Read a table'
    ],
    'Maths - Miscellaneous': [
      'Single Digit to Single Digit Multiplications',
      'Multiplication facts (0-10)',
      'Division concepts',
      'Division facts (0-10)',
      'Multiplication and division word problems',
      'Four-digit numbers',
      'Addition and subtraction with regrouping',
      'Introduction to fractions',
      'Equivalent fractions',
      'Comparing fractions',
      'Area and perimeter',
      'Data analysis and graphs',
      'Measurement of time, liquid volume, and mass',
      'Basic geometry: shapes and their properties',
      'Lines and polygons',
      'Scaled pictographs',
      'Problem-solving strategies',
      'Number patterns',
      'Place value',
      'Rounding numbers'
    ],
    Science: [
      'Ecosystems and their components',
      'Forces and motion',
      'Adaptations of plants and animals',
      'Fossils and the study of the past',
      'Climate and weather patterns',
      'The solar system',
      'Earth and space',
      'Plant structures and functions',
      'Plant responses',
      'Animal classification',
      'Animal adaptations',
      'Properties of matter',
      'Changes in matter',
      'Forms of energy: motion, heat, and light',
      'Gravity',
      'Stars',
      'Electricity and magnetism',
      'Heat and light',
      'Scientific investigations',
      'Making predictions'
    ]
  },
  'Grade 4': {
    'Maths - Number and operations': [
      ' Convert between place values',
      ' Place value review',
      ' Place values in decimal numbers',
      ' Relationship between place values',
      ' Place value names',
      ' Place value word problems',
      ' Place value models for decimal numbers',
      ' Convert decimals between standard and expanded form using fractions',
      ' Understanding decimals expressed in words',
      ' Convert between standard and expanded form: up to one billion',
      ' Value of a digit: up to one billion',
      ' Place value review: up to one billion',
      ' Value of a digit in a decimal number',
      ' Convert decimals between standard and expanded form',
      ' Compare numbers up to one billion',
      ' Rounding: up to hundred thousands place',
      ' Rounding: up to millions place',
      ' Round a number to any place: up to hundred thousands',
      ' Rounding input/output tables',
      ' What decimal number is illustrated?',
      ' Decimal number lines',
      ' Place values in decimal numbers',
      ' Relate decimals and money',
      ' Understanding decimals expressed in words',
      ' Count coins and bills - up to $20 bill',
      ' Compare decimals using models',
      ' Compare decimals on number lines',
      ' Compare decimal numbers',
      ' Put decimal numbers in order I',
      ' Put decimal numbers in order II',
      ' Compare money amounts',
      ' Compare decimals using place value blocks',
      ' Put decimal numbers in order using a number line',
      ' Put decimal numbers in order using models',
      ' Put decimal numbers less than one in order',
      ' Graph fractions as decimals on number lines',
      ' Convert fractions and mixed numbers to decimals - denominators of 10 and 100',
      ' Convert decimals between standard and expanded form using fractions',
      ' Compare decimals and fractions',
      ' Graph decimals on number lines',
      ' Decimal number lines',
      ' Put decimal numbers in order using a number line',
      ' Decompose fractions into unit fractions using models',
      ' Decompose fractions into unit fractions',
      ' Fractions review',
      ' Decompose fractions into unit fractions using models',
      ' Decompose fractions',
      ' Decompose fractions multiple ways',
      ' Fractions review',
      ' Find equivalent fractions using area models',
      ' Identify equivalent fractions using number lines',
      ' Graph equivalent fractions on number lines',
      ' Find equivalent fractions using multiplication and division',
      ' Identify equivalent fractions',
      ' Equivalent fractions: find the missing numerator or denominator',
      ' Patterns of equivalent fractions',
      ' Fractions with denominators of 10 and 100',
      ' Write fractions in lowest terms',
      ' Are the fractions equivalent?',
      ' Compare fractions using models',
      ' Benchmark fractions',
      ' Compare fractions using benchmarks',
      ' Compare fractions using benchmarks: find the missing numerator',
      ' Compare fractions',
      ' Compare fractions: find the missing numerator or denominator',
      ' Compare fractions in recipes',
      ' Graph and order fractions on number lines',
      ' Order fractions',
      ' Add fractions with like denominators using area models',
      ' Add fractions with like denominators using strip models',
      ' Add fractions with like denominators using number lines',
      ' Subtract fractions with like denominators using area models',
      ' Subtract fractions with like denominators using strip models',
      ' Subtract fractions with like denominators using number lines',
      ' Add and subtract fractions with like denominators using number lines',
      ' Add fractions with like denominators',
      ' Subtract fractions with like denominators',
      ' Add and subtract fractions with like denominators',
      ' Add and subtract fractions with like denominators: word problems',
      ' Add and subtract fractions with like denominators in recipes',
      ' Add 3 or more fractions with like denominators',
      ' Compare sums and differences of fractions with like denominators',
      ' Add mixed numbers',
      ' Add and subtract mixed numbers with like denominators',
      ' Add fractions and mixed numbers using properties',
      ' Add fractions with like denominators: pick the model',
      ' Subtract fractions with like denominators: pick the model',
      ' Subtract mixed numbers without regrouping',
      ' Add and subtract fractions with like denominators: pick the area model',
      ' Add and subtract fractions with like denominators: complete the equation',
      ' Break apart fractions and mixed numbers to add or subtract',
      ' Determine whether the sum or difference of two fractions is reasonable',
      ' Compare sums of unit fractions',
      ' Compare differences of unit fractions',
      ' Compare sums and differences of unit fractions',
      ' Fractions of number lines',
      ' Graph fractions as decimals on number lines',
      ' Graph decimals on number lines',
      ' Decimal number lines',
      ' Add 3 or more numbers up to millions',
      ' Addition: fill in the missing digits',
      ' Choose numbers with a particular sum',
      ' Subtraction: fill in the missing digits',
      ' Choose numbers with a particular difference',
      ' Add decimal numbers',
      ' Subtract decimal numbers',
      ' Add and subtract decimals: word problems',
      ' Choose decimals with a particular sum or difference',
      ' Add 3 or more decimals',
      ' Add 3 or more decimals: word problems',
      ' Add and subtract money amounts',
      ' Add two numbers up to seven digits',
      ' Add two numbers up to seven digits: word problems',
      ' Subtract numbers up to seven digits',
      ' Subtract numbers up to seven digits: word problems',
      ' Multiply by 10 or 100',
      ' Multiplication patterns over increasing place values: up to thousands',
      ' Multiply by numbers ending in zeros: find the missing number',
      ' Multiply 2-digit numbers by 2-digit numbers: choose the area model',
      ' Multiply 2-digit numbers by 2-digit numbers using area models',
      ' Box multiplication',
      ' Lattice multiplication',
      ' Multiplication facts to 12',
      ' Properties of multiplication',
      ' Choose the multiples of a given number up to 12',
      ' Multiply 1-digit numbers by teen numbers using grids',
      ' Multiply 1-digit numbers by 2-digit numbers: choose the area model',
      ' Multiply 1-digit numbers by 2-digit numbers using area models',
      ' Multiply 1-digit numbers by 2-digit numbers',
      ' Multiply 1-digit numbers by 3-digit or 4-digit numbers: choose the area model',
      ' Multiply 1-digit numbers by 3-digit or 4-digit numbers using area models',
      ' Multiply 1-digit numbers by 3-digit or 4-digit numbers using expanded form',
      ' Multiply 1-digit numbers by multi-digit numbers using partial products',
      ' Multiply 1-digit numbers by 3-digit or 4-digit numbers',
      ' Multiply 2-digit numbers by 2-digit numbers: choose the area model',
      ' Multiply 2-digit numbers by 2-digit numbers using area models',
      ' Multiply 2-digit numbers by 2-digit numbers using partial products',
      ' Multiply a 2-digit number by a 2-digit number: complete the missing steps',
      ' Multiply a 2-digit number by a 2-digit number',
      ' Inequalities with multiplication',
      ' Use one multiplication fact to complete another',
      ' Multiply 1-digit numbers by teen numbers',
      ' Multiply a two-digit number by a multiple of ten',
      ' Multiply using the associative and commutative properties',
      ' Divide 2-digit numbers by 1-digit numbers using arrays',
      ' Divide 2-digit numbers by 1-digit numbers using area models',
      ' Divide 2-digit numbers by 1-digit numbers: quotients up to 10',
      ' Divide 2-digit numbers by 1-digit numbers: complete the table',
      ' Divide 3-digit numbers by 1-digit numbers using area models',
      ' Divide 2-digit numbers by 1-digit numbers: word problems',
      ' Divide larger numbers by 1-digit numbers: word problems',
      ' Pick the model that shows a division expression',
      ' Divide larger numbers by 1-digit numbers: complete the table',
      ' Division facts to 12',
      ' Properties of division',
      ' Divide numbers ending in zeros by 1-digit numbers',
      ' Divide using the distributive property',
      ' Divide 2-digit numbers by 1-digit numbers',
      ' Divide 2-digit numbers by 1-digit numbers: complete the table',
      ' Divide using partial quotients',
      ' Divide larger numbers by 1-digit numbers',
      ' Divide numbers ending in zeros by 1-digit numbers: word problems',
      ' Divide larger numbers by 1-digit numbers: complete the table',
      ' Divide numbers ending in zeros by 1-digit numbers: complete the sentence',
      ' Divide using repeated subtraction',
      ' Estimate sums',
      ' Estimate sums: word problems',
      ' Estimate differences',
      ' Estimate differences: word problems',
      ' Estimate products word problems: identify reasonable answers',
      ' Estimate products: multiply by 2-digit numbers',
      ' Estimate products: word problems',
      ' Estimate quotients using compatible numbers: 1-digit divisors',
      ' Estimate sums, differences, products, and quotients: word problems',
      ' Estimate sums using compatible numbers',
      ' Estimate differences using compatible numbers',
      ' Estimate quotients',
      ' Estimate quotients: word problems',
      ' Multiplication facts to 12: find the missing factor',
      ' Compare numbers using multiplication: word problems',
      ' Choose numbers with a particular product',
      ' Multiply 1-digit numbers by 2-digit numbers: word problems',
      ' Multiply 1-digit numbers by 2-digit numbers: multi-step word problems',
      ' Multiply 1-digit numbers by 3-digit or 4-digit numbers: word problems',
      ' Multiply 1-digit numbers by 3-digit or 4-digit numbers: multi-step word problems',
      ' Multiply two multiples of ten: word problems',
      ' Multiply a 2-digit number by a 2-digit number: word problems',
      ' Multiply a 2-digit number by a 2-digit number: multi-step word problems',
      ' Division facts to 12',
      ' Division facts to 12: word problems',
      ' Choose numbers with a particular quotient',
      ' Divide 2-digit numbers by 1-digit numbers: interpret remainders',
      ' Divide 2-digit numbers by 1-digit numbers: word problems',
      ' Divide larger numbers by 1-digit numbers: interpret remainders',
      ' Divide larger numbers by 1-digit numbers: word problems',
      ' Multi-step word problems involving remainders',
      ' Multi-step word problems: identify reasonable answers',
      ' One-step word problems involving remainders'
    ],
    'Maths - Algebraic reasoning': [
      ' Write equations to represent word problems',
      ' Use equations to solve multi-step addition and subtraction word problems',
      ' Multi-step word problems with strip diagrams',
      ' Use strip diagrams to represent and solve multi-step word problems',
      ' Write variable expressions',
      ' Write variable expressions: word problems',
      ' Multi-step addition word problems',
      ' Multi-step word problems involving subtraction',
      ' Identify mistakes in number patterns',
      ' Multiplication input/output tables',
      ' Input/output tables with addition, subtraction, multiplication, and division',
      ' Complete the input/output table',
      ' Write the rule for an input/output table',
      ' Perimeter of polygons',
      ' Perimeter: find the missing side length',
      ' Find the perimeter of rectangles using formulas',
      ' Find the area of rectangles using formulas',
      ' Find the area or missing side length of a rectangle',
      ' Understanding area and perimeter of rectangles',
      ' Perimeter: find the missing side length',
      ' Perimeter: word problems',
      ' Create rectangles with a given area',
      ' Area: word problems',
      ' Area of complex figures',
      ' Relationship between area and perimeter',
      ' Area and perimeter: word problems',
      ' Rectangles: relationship between perimeter and area word problems',
      ' Use area and perimeter to determine cost',
      ' Understanding area and perimeter of rectangles'
    ],
    'Maths - Geometry and measurement': [
      ' Points, lines, line segments, rays, and angles',
      ' Parallel, perpendicular, and intersecting lines',
      ' Identify parallel, perpendicular, and intersecting lines',
      ' Acute, right, obtuse, and straight angles',
      ' Angles of 90, 180, 270, and 360 degrees',
      ' Identify lines of symmetry',
      ' Draw lines of symmetry',
      ' Count lines of symmetry',
      ' Classify triangles',
      ' Classify triangles: acute, obtuse, and right',
      ' Acute, obtuse, and right triangles',
      ' Scalene, isosceles, and equilateral triangles',
      ' Classify triangles',
      ' Parallel sides in quadrilaterals',
      ' Sides and angles of quadrilaterals',
      ' Identify parallelograms',
      ' Identify trapezoids',
      ' Identify rectangles',
      ' Identify rhombuses',
      ' Classify quadrilaterals',
      ' Pick all the names for a quadrilateral',
      ' Identify relationships between quadrilaterals',
      ' Angles as fractions of a circle',
      ' Measure angles on a circle',
      ' Use fractions to find the measure of an angle',
      ' Angles of 90, 180, 270, and 360 degrees',
      ' Measure angles on a circle',
      ' Estimate angle measurements',
      ' Use a protractor to measure angles',
      ' Draw angles with a protractor',
      ' Adjacent angles',
      ' Angle measures: word problems'
    ],
    'Maths - Data analysis': [
      ' Create line plots with fractions',
      ' Create stem-and-leaf plots',
      ' Create frequency charts with numerical data',
      ' Create line plots with whole numbers',
      ' Interpret line plots',
      ' Create and interpret line plots with fractions',
      ' Interpret frequency charts',
      ' Interpret stem-and-leaf plots',
      ' Interpret frequency charts with numerical data'
    ],
    'Maths - Personal financial literacy': [
      ' Identify fixed and variable expenses',
      ' Calculate profit: word problems',
      ' Compare savings options',
      ' Budget a weekly allowance: word problems',
      ' Financial institution vocabulary'
    ],
    'Maths - Miscellaneous': [
      'Place value to millions',
      'Multi-digit multiplication',
      'Division strategies',
      'Factors and multiples',
      'Rounding',
      'Addition and subtraction with regrouping',
      'Fraction operations (addition, subtraction)',
      'Comparing fractions and decimals',
      'Equivalent fractions',
      'Introduction to decimals',
      'Geometric measurements (area, perimeter)',
      'Lines and angles',
      'Data representation in tables and graphs',
      'Analyzing data',
      'Solving word problems with multiple steps',
      'Multiplication by 1 and 2 digit numbers',
      'Decimal points',
      'Creating number sentences and equations',
      'Perimeter and area of shapes',
      'Introduction to data representation'
    ],
    Science: [
      'States of matter (solid, liquid, gas)',
      'Properties of matter',
      'Matter and mass',
      'Heat and thermal energy',
      'Physical and chemical changes',
      'Mixtures and solutions',
      'Erosion',
      'Waves and their properties',
      'Human body systems',
      'Light and energy transfer',
      'Electricity and magnetism',
      'Structure and function in organisms',
      'The water cycle',
      'The solar system and movement of Earth',
      'Photosynthesis',
      'Rock formations',
      'Aquatic biomes',
      'Scientific investigations',
      'Representing data in graphs and tables',
      'Using science notebooks'
    ]
  },
  'Grade 5': {
    'Maths - Number and operations': [
      ' Understanding decimals expressed in words',
      ' Place values in decimal numbers',
      ' Relationship between decimal place values',
      ' Convert decimals between standard and expanded form',
      ' Convert decimals between standard and expanded form using fractions',
      ' Compose and decompose decimals in multiple ways',
      ' Value of a digit in a decimal number',
      ' Compare decimals using grids',
      ' Compare decimals on number lines',
      ' Compare decimal numbers',
      ' Put decimal numbers in order',
      ' Compare, order, and round decimals: word problems',
      ' Put a mix of decimals and fractions in order',
      ' Round decimals',
      ' Compare, order, and round decimals: word problems',
      ' Estimate sums and differences of whole numbers',
      ' Estimate sums and differences: word problems',
      ' Estimate products: word problems',
      ' Estimate quotients: 2-digit divisors',
      ' Estimate quotients',
      ' Multi-step word problems: identify reasonable answers',
      ' Estimate sums and differences of fractions using benchmarks',
      ' Estimate sums and differences of mixed numbers',
      ' Estimate sums and differences of decimals using rounding',
      ' Estimate sums and differences of decimals using benchmarks',
      ' Estimate products of decimals',
      ' Estimate decimal quotients',
      ' Estimate products: multiply by 2-digit numbers',
      ' Estimate quotients: word problems',
      ' Determine whether the sum or difference of two fractions is reasonable',
      ' Multiply 2-digit numbers by 3-digit numbers',
      ' Divide multi-digit numbers by 1-digit numbers',
      ' Divide by 1-digit numbers: interpret remainders',
      ' Divide multi-digit numbers by 1-digit numbers: word problems',
      ' Divide by 2-digit numbers: estimate and adjust',
      ' Divide by 2-digit numbers using models',
      ' Divide by 2-digit numbers using partial quotients',
      ' Divide 2-digit and 3-digit numbers by 2-digit numbers',
      ' Divide 2-digit and 3-digit numbers by 2-digit numbers: word problems',
      ' Divide 4-digit numbers by 2-digit numbers',
      ' Divide 4-digit numbers by 2-digit numbers: word problems',
      ' Adjust quotients',
      ' Complete the division sentence: 2-digit divisors',
      ' Choose numbers with a particular quotient',
      ' Multi-step word problems: multiplicative comparison',
      ' Multiply a decimal by a one-digit whole number using blocks',
      ' Multiply a decimal by a two-digit whole number using area models',
      ' Complete the decimal multiplication sentence using grids',
      ' Multiply decimals using grids',
      ' Multiply decimals using area models',
      ' Properties of multiplication',
      ' Multiply by a power of ten with decimals: find the missing number',
      ' Multiply a decimal by a one-digit whole number: tenths or hundredths',
      ' Multiply a decimal by a one-digit whole number using the distributive property',
      ' Multiply a decimal by a one-digit whole number',
      ' Multiply a decimal by a multi-digit whole number',
      ' Multiply decimals and whole numbers: word problems',
      ' Multiply two decimals: where does the decimal point go?',
      ' Multiply two decimals: products up to hundredths',
      ' Multiply money amounts: word problems',
      ' Price lists: multiplication',
      ' Divide decimals using blocks: complete the equation',
      ' Divide decimals using area models: complete the equation',
      ' Decimal division patterns over increasing place values',
      ' Divide by a power of ten with decimals: find the missing number',
      ' Divide decimals by whole numbers without adding zeros',
      ' Division with decimal quotients',
      ' Division with decimal quotients and rounding',
      ' Division with decimal quotients: word problems',
      ' Divide money amounts: word problems',
      ' Divide decimals by whole numbers using place value',
      ' Add fractions with unlike denominators using models',
      ' Subtract fractions with unlike denominators using models',
      ' Represent addition of fractions with unlike denominators using objects',
      ' Represent subtraction of fractions with unlike denominators using objects',
      ' Add fractions with unlike denominators using objects',
      ' Subtract fractions with unlike denominators using objects',
      ' Add 3 fractions or mixed numbers using properties of addition',
      ' Add or subtract three fractions with unlike denominators',
      ' Multiply unit fractions by whole numbers using number lines',
      ' Multiply unit fractions and whole numbers: sorting',
      ' Multiply fractions by whole numbers: choose the model',
      ' Multiply fractions by whole numbers using models: complete the equation',
      ' Multiply fractions by whole numbers using number lines',
      ' Multiply fractions by whole numbers using arrays',
      ' Fractions of a number: model and multiply',
      ' Multiply fractions and whole numbers: sorting',
      ' Multiply unit fractions by whole numbers',
      ' Divide unit fractions by whole numbers using models',
      ' Divide whole numbers by unit fractions using models',
      ' Divide unit fractions and whole numbers using area models',
      ' Divide unit fractions and whole numbers using number lines',
      ' Divide unit fractions by whole numbers',
      ' Divide whole numbers and unit fractions',
      ' Price lists: addition and subtraction',
      ' Add and subtract whole numbers up to billions',
      ' Add and subtract whole numbers: word problems',
      ' Complete addition and subtraction sentences',
      ' Subtract fractions with unlike denominators',
      ' Add and subtract fractions with unlike denominators',
      ' Add 3 or more fractions: word problems',
      ' Complete addition and subtraction sentences with fractions',
      ' Compare sums and differences of fractions',
      ' Add mixed numbers with unlike denominators',
      ' Subtract mixed numbers with unlike denominators: with renaming',
      ' Add and subtract mixed numbers: word problems',
      ' Add and subtract fractions in recipes',
      ' Complete addition and subtraction sentences with mixed numbers',
      ' Compare sums and differences of mixed numbers',
      ' Use properties to add three decimals',
      ' Add and subtract decimal numbers',
      ' Use compensation to add and subtract decimals',
      ' Add and subtract decimal numbers: word problems',
      ' Choose decimals with a particular sum or difference',
      ' Inequalities with decimal addition and subtraction',
      ' Add and subtract money amounts',
      ' Add and subtract money: word problems',
      ' Add and subtract fractions with like denominators: word problems',
      ' Choose numbers with a particular sum or difference',
      ' Add decimal numbers: up to thousandths',
      ' Subtract decimal numbers: up to thousandths',
      ' Complete the decimal addition or subtraction sentence',
      ' Add and subtract fractions with like denominators',
      ' Add and subtract fractions with unlike denominators: word problems',
      ' Add 3 or more fractions with unlike denominators',
      ' Add fractions with unlike denominators',
      ' Add and subtract mixed numbers with like denominators',
      ' Add and subtract mixed numbers with unlike denominators',
      ' Divide whole numbers by unit fractions',
      ' Divide whole numbers and unit fractions',
      ' Divide unit fractions and whole numbers: word problems'
    ],
    'Maths - Algebraic reasoning': [
      ' Prime and composite numbers',
      ' Multi-step word problems',
      ' Multi-step word problems involving remainders',
      ' Which value makes the equation true?',
      ' Represent multi-step problems using equations',
      ' Compare patterns',
      ' Complete a table for a two-variable relationship',
      ' Complete a table from a graph',
      ' Graph patterns using rules',
      ' Complete an input/output table',
      ' Write a two-variable equation',
      ' Find pattern rules from graphs',
      ' Find the rule from a table: word problems',
      ' Write numerical expressions: two operations',
      ' Identify mistakes involving the order of operations',
      ' Evaluate numerical expressions with and without parentheses',
      ' Evaluate numerical expressions with parentheses',
      ' Evaluate numerical expressions with parentheses and brackets',
      ' Evaluate numerical expressions with parentheses in different places',
      ' Volume of rectangular prisms made of unit cubes',
      ' Volume of rectangular prisms',
      ' Perimeter with whole number side lengths',
      ' Perimeter with decimal side lengths',
      ' Perimeter with fractional side lengths',
      ' Perimeter of figures on grids',
      ' Area of squares and rectangles',
      ' Area of compound figures',
      ' Area of figures on grids',
      ' Area and perimeter: word problems',
      ' Volume of cubes and rectangular prisms',
      ' Volume of cubes and rectangular prisms: word problems',
      ' Volume of cubes and rectangular prisms with decimal side lengths',
      ' Area of rectangles with a fractional side',
      ' Volume of rectangular prisms',
      ' Volume of cubes'
    ],
    'Maths - Geometry and measurement': [
      ' Acute, obtuse, and right triangles',
      ' Scalene, isosceles, and equilateral triangles',
      ' Classify triangles',
      ' Parallel sides in quadrilaterals',
      ' Identify parallelograms',
      ' Identify trapezoids',
      ' Identify rectangles',
      ' Identify rhombuses',
      ' Classify quadrilaterals',
      ' Identify the relationships between quadrilaterals',
      ' Describe relationships among quadrilaterals',
      ' Regular and irregular polygons',
      ' Sort polygons into Venn diagrams',
      ' Properties of polygons',
      ' Volume of irregular figures made of unit cubes',
      ' Volume of rectangular prisms made of unit cubes',
      ' Volume of rectangular prisms made of unit cubes II',
      ' Volume of rectangular prisms made of unit cubes: expressions',
      ' Volume of rectangular prisms made of unit cubes: word problems',
      ' Compare and convert customary units of length',
      ' Compare and convert customary units of weight',
      ' Compare and convert customary units of volume',
      ' Compare and convert customary units',
      ' Conversion tables - customary units',
      ' Compare customary units by multiplying',
      ' Convert mixed customary units',
      ' Add and subtract mixed customary units',
      ' Multi-step problems with customary unit conversions',
      ' Compare and convert metric units of length',
      ' Compare and convert metric units of mass',
      ' Compare and convert metric units of volume',
      ' Compare and convert metric units',
      ' Conversion tables - metric units',
      ' Convert metric mixed units',
      ' Add and subtract metric mixed units',
      ' Multi-step problems with metric unit conversions',
      ' Multi-step problems with customary or metric unit conversions',
      ' Convert metric units involving decimals',
      ' Describe the coordinate plane',
      ' Objects on a coordinate plane',
      ' Graph points on a coordinate plane',
      ' Follow directions on a coordinate plane',
      ' Graph triangles and quadrilaterals',
      ' Graph points from a table',
      ' Use a rule to complete a table and a graph',
      ' Coordinate planes as maps',
      ' Graph a two-variable relationship',
      ' Find pattern rules from graphs',
      ' Find the rule from a table: word problems'
    ],
    'Maths - Data analysis': [
      ' Create line plots with fractions',
      ' Create bar graphs',
      ' Create frequency charts',
      ' Create stem-and-leaf plots',
      ' Create scatter plots',
      ' Interpret line plots with whole numbers',
      ' Create and interpret line plots with fractions',
      ' Interpret bar graphs',
      ' Interpret bar graphs: multi-step problems',
      ' Interpret frequency charts: one-step problems',
      ' Interpret frequency charts: multi-step problems',
      ' Interpret stem-and-leaf plots',
      ' Interpret scatter plots',
      ' Predictions and trends in scatter plots'
    ],
    'Maths - Personal financial literacy': [
      ' Income and payroll taxes: word problems',
      ' Sales and property taxes: word problems',
      ' Identify types of taxes',
      ' Understand gross and net income',
      ' Calculate gross and net income',
      ' Identify advantages and disadvantages of payment methods',
      ' Evaluate payment methods',
      ' Reading financial records',
      ' Keeping financial records',
      ' Adjust a budget',
      ' Balance a budget'
    ],
    'Maths - Miscellaneous': [
      'Decimal operations (addition, subtraction, multiplication, division)',
      'Advanced fraction operations (addition, subtraction, multiplication, division)',
      'Converting decimals and fractions',
      'Fractions vs decimals',
      'Mixed operations with whole numbers',
      'Volume and geometry',
      'Order of operations',
      'Powers of ten',
      'Exponents',
      'Pattern analysis',
      'Integers (introduction to negative numbers)',
      'Metric and customary measurements',
      'Coordinate planes',
      'Numerical expressions',
      'Multiplying and dividing by powers of ten',
      'Multiplying and dividing large numbers',
      'Rectangular arrays',
      'Word problems with fractions and decimals',
      'Factors, multiples and divisibility',
      'Data and graphing'
    ],
    Science: [
      'Properties of matter',
      'Chemical changes',
      'Physical changes',
      'Food webs and food chains',
      'The water cycle',
      'Moon phases and the lunar cycle',
      'The particle nature of matter',
      'Sun and other stars',
      'Interactions of Earth\'s spheres',
      'Earth\'s orbit and rotation',
      'How we use food',
      'Water quality and distribution',
      'Matter and its properties',
      'Ecosystems and their interactions',
      'Energy transformations',
      'Atoms and molecules',
      'Force and motion',
      'Magnets',
      'The solar system and its components',
      'Environmental changes and evolution'
    ]
  },
  'Grade 6': {
    'Maths - Number and operations': [
      ' Classify rational numbers using a diagram',
      ' Classify numbers',
      ' Understanding opposite integers',
      ' Understanding absolute value',
      ' Absolute value of integers on number lines',
      ' Absolute value',
      ' Absolute value and integers: word problems',
      ' Absolute value of rational numbers',
      ' Absolute values and opposites of rational numbers',
      ' Integers on number lines',
      ' Graph integers on horizontal and vertical number lines',
      ' Compare integers',
      ' Put integers in order',
      ' Rational numbers on number lines',
      ' Compare and order rational numbers using number lines',
      ' Put a mix of decimals and fractions in order',
      ' Put a mix of decimals, fractions, and mixed numbers in order',
      ' Put integers in order',
      ' Integer inequalities with absolute values',
      ' Rational numbers: equal or not equal',
      ' Compare rational numbers',
      ' Put rational numbers in order',
      ' Compare and order rational numbers: word problems',
      ' Compare temperatures above and below zero',
      ' Convert between improper fractions and mixed numbers',
      ' Convert fractions to decimals',
      ' Convert decimals to fractions',
      ' Convert between fractions and repeating decimals',
      ' Convert between decimals and fractions',
      ' Convert between decimals and mixed numbers',
      ' Understand fractions as division: word problems',
      ' Write rational numbers in the form a/b',
      ' Reciprocals',
      ' Divide fractions using models',
      ' Divide fractions and mixed numbers using models',
      ' Scaling whole numbers by fractions: justify your answer',
      ' Scaling by fractions and mixed numbers',
      ' Estimate products of mixed numbers',
      ' Add integers using counters',
      ' Subtract integers using counters',
      ' Subtract integers using number lines',
      ' Understand multiplying by a negative integer using a number line',
      ' Add two negative integers using number lines',
      ' Add two integers with different signs using number lines',
      ' Add integers',
      ' Subtract integers',
      ' Add and subtract integers: find the sign',
      ' Add and subtract integers: word problems',
      ' Add and subtract integers: input/output tables',
      ' Add three or more integers',
      ' Multiply integers: find the sign',
      ' Multiply integers',
      ' Divide integers: find the sign',
      ' Divide integers',
      ' Add, subtract, multiply, or divide two integers',
      ' Multiply mixed numbers',
      ' Multiply decimals',
      ' Divide decimals by whole numbers',
      ' Divide decimals by whole numbers: word problems',
      ' Multiply and divide decimals by powers of ten',
      ' Division with decimal divisors',
      ' Multiply and divide decimals: word problems',
      ' Multiply fractions and whole numbers',
      ' Multiply fractions by whole numbers: word problems',
      ' Multiply two fractions using models',
      ' Multiply two fractions',
      ' Multiply fractions: word problems',
      ' Multiply mixed numbers: word problems',
      ' Multiply three or more mixed numbers, fractions, and/or whole numbers',
      ' Divide whole numbers and fractions using models',
      ' Divide fractions by whole numbers in recipes',
      ' Divide fractions using models',
      ' Divide fractions',
      ' Divide fractions and mixed numbers using models',
      ' Divide fractions and mixed numbers',
      ' Divide fractions and mixed numbers using models: word problems',
      ' Divide fractions and mixed numbers: word problems',
      ' Divide decimals',
      ' Divide fractions: word problems'
    ],
    'Maths - Proportionality': [
      ' Solve one-step equations: word problems',
      ' Write an equation from a graph using a table',
      ' Write a two-variable equation',
      ' Additive and multiplicative relationships',
      ' Identify equivalent ratios',
      ' Write an equivalent ratio',
      ' Equivalent ratios: word problems',
      ' Unit rates',
      ' Rates: word problems',
      ' Calculate speed, distance, or time: word problems',
      ' Compare ratios: word problems',
      ' Compare rates: word problems',
      ' Ratios and rates: word problems',
      ' Do the ratios form a proportion?',
      ' Solve the proportion',
      ' Compare percents and fractions: word problems',
      ' Unit prices with fractions and decimals',
      ' Write a ratio: word problems',
      ' Write an equivalent ratio',
      ' Ratio tables',
      ' Unit rates',
      ' Unit prices',
      ' Unit prices with customary unit conversions',
      ' Write a ratio using a colon',
      ' Write a ratio using a fraction',
      ' Which model represents the ratio?',
      ' Benchmark percents with strip models: multiples of 10, 20, 25, 33, and 50',
      ' Convert fractions to percents using grid models',
      ' Fractions on number lines',
      ' Graph percents on number lines',
      ' Represent fractions using strip diagrams',
      ' Equivalent ratios: word problems',
      ' Convert between percents, fractions, and decimals: word problems',
      ' Fractions of a group: word problems',
      ' Convert and compare customary units',
      ' Convert, compare, add, and subtract mixed customary units',
      ' Customary unit conversions involving fractions and mixed numbers',
      ' Convert and compare metric units',
      ' Convert customary and metric units using proportions',
      ' Unit prices with customary unit conversions',
      ' Convert metric and customary units using conversion factors',
      ' Ratio tables',
      ' Unit rates',
      ' Rates: word problems',
      ' Ratios and rates: complete a table and make a graph',
      ' Ratios and rates: word problems',
      ' Do the ratios form a proportion?',
      ' Scale drawings: word problems',
      ' Solve percent problems using grid models',
      ' Solve percent problems using strip models',
      ' Percents of numbers and money amounts',
      ' Percents of numbers: word problems',
      ' Percents of numbers: fractional and decimal percents',
      ' Find what percent one number is of another',
      ' Find what percent one number is of another: word problems',
      ' Find the total given a part and a percent',
      ' Solve percent problems',
      ' Solve percent word problems',
      ' Represent percents with grid models, decimals, and fractions'
    ],
    'Maths - Expressions, equations, and relationships': [
      ' Identify independent and dependent variables in tables and graphs',
      ' Identify independent and dependent variables: word problems',
      ' Write a two-variable equation from a table',
      ' Write an equation from a graph using a table',
      ' Solve word problems by finding two-variable equations',
      ' Complete a table for a two-variable relationship',
      ' Write a two-variable equation',
      ' Identify the graph of an equation',
      ' Complete a table and graph a two-variable equation',
      ' Graph a two-variable equation',
      ' Complete a table and make a graph: word problems',
      ' Write multiplication expressions using exponents',
      ' Write powers of ten with exponents',
      ' Evaluate numerical expressions involving whole numbers',
      ' Identify mistakes involving the order of operations',
      ' Prime factorization',
      ' Prime factorization with exponents',
      ' Write variable expressions: one operation',
      ' Write an equation from words',
      ' Identify expressions and equations',
      ' Identify equivalent expressions using strip models',
      ' Identify equivalent expressions I',
      ' Identify equivalent expressions II',
      ' Properties of addition',
      ' Properties of multiplication',
      ' Multiply using the distributive property: area models',
      ' Multiply using the distributive property',
      ' Factor numerical expressions using the distributive property',
      ' Factor variable expressions: area models',
      ' Factor variable expressions using the distributive property',
      ' Write equivalent expressions using properties',
      ' Combine like terms',
      ' Add and subtract like terms',
      ' Triangle inequality',
      ' Find missing angles in triangles',
      ' Find missing angles in special triangles',
      ' Relationship between sides and angles in a triangle',
      ' Understanding area of a parallelogram',
      ' Understanding area of a triangle',
      ' Understanding area of a trapezoid',
      ' Area of quadrilaterals and triangles: word problems',
      ' Area of rectangles and squares',
      ' Area of parallelograms',
      ' Area of triangles',
      ' Area of trapezoids',
      ' Area of quadrilaterals',
      ' Area of compound figures',
      ' Area of compound figures with triangles',
      ' Area between two rectangles',
      ' Area between two triangles',
      ' Volume of cubes and rectangular prisms',
      ' Volume of cubes and rectangular prisms: word problems',
      ' Write variable expressions: one operation',
      ' Write an equation from words',
      ' Write a one-step equation: word problems',
      ' One-step inequalities: word problems',
      ' Solve one-step addition and subtraction equations and graph their solutions',
      ' Graph inequalities on number lines',
      ' Write inequalities from number lines',
      ' Graph solutions to one-step inequalities',
      ' Solve one-step multiplication and division equations and graph their solutions',
      ' Solve and graph one-step addition and subtraction inequalities',
      ' Solve and graph one-step multiplication and division inequalities with positive numbers',
      ' Solve and graph one-step multiplication and division inequalities with rational numbers',
      ' Which word problem matches the one-step equation?',
      ' Model and solve equations using algebra tiles',
      ' Model and solve equations using diagrams',
      ' Write and solve equations that represent diagrams',
      ' Solve one-step equations with whole numbers',
      ' Solve one-step addition and subtraction equations with decimals and fractions',
      ' Solve one-step multiplication and division equations with decimals and fractions',
      ' Solve one-step equations with decimals and fractions',
      ' Solve one-step addition and subtraction equations: word problems',
      ' Solve one-step multiplication and division equations: word problems',
      ' Write a one-step equation: word problems',
      ' Solve one-step equations: word problems',
      ' Solve one-step inequalities',
      ' One-step inequalities: word problems',
      ' Does x satisfy an equation?',
      ' Which x satisfies an equation?',
      ' Solutions to inequalities'
    ],
    'Maths - Measurement and data': [
      ' Graph points on a coordinate plane',
      ' Graph points on a coordinate plane',
      ' Quadrants',
      ' Coordinate planes as maps',
      ' Create line plots',
      ' Create and interpret line plots with fractions',
      ' Create frequency charts',
      ' Create histograms',
      ' Create stem-and-leaf plots',
      ' Box plots',
      ' Box plots',
      ' Interpret charts and graphs to find mean, median, mode, and range',
      ' Describe distributions in line plots',
      ' Calculate range, quartiles, and interquartile range',
      ' Identify an outlier',
      ' Describe distributions in line plots',
      ' Calculate mean and median',
      ' Mean and median: find the missing number',
      ' Changes in mean and median',
      ' Interpret median and interquartile range',
      ' Interpret categorical data',
      ' Create relative frequency tables',
      ' Create percent bar graphs',
      ' Interpret circle graphs',
      ' Interpret line plots',
      ' Create and interpret line plots with fractions',
      ' Interpret histograms',
      ' Interpret stem-and-leaf plots',
      ' Box plots',
      ' Identify statistical questions'
    ],
    'Maths - Personal financial literacy': [
      ' Compare checking accounts',
      ' Debit cards and credit cards',
      ' Check registers',
      ' Credit reports',
      ' Credit reports',
      ' Credit reports',
      ' Paying for college',
      ' Occupations, education, and income'
    ],
    'Maths - Miscellaneous': [
      'Ratios and rates',
      'Unit rates and percentages',
      'Dividing fractions by fractions',
      'Operations with multi-digit decimals',
      'Greatest common factor and least common multiple',
      'Distributive property',
      'Understanding positive and negative numbers',
      'Absolute value',
      'Coordinate plane (four quadrants)',
      'Writing and evaluating numerical expressions with exponents',
      'Writing algebraic expressions',
      'Evaluating algebraic expressions',
      'Solving one-variable equations and inequalities',
      'Dependent and independent variables',
      'Area of triangles and quadrilaterals',
      'Volume of right rectangular prisms',
      'Surface area using nets',
      'Statistical variability',
      'Measures of center (mean, median, mode)',
      'Data displays (histograms, box plots)',
      'Summarizing data distributions',
      'Proportional relationships'
    ],
    Science: [
      'Structure and function of cells',
      'Cell theory',
      'Photosynthesis and respiration',
      'Ecosystems and biomes',
      'Food webs and energy pyramids',
      'Classification of organisms',
      'Adaptations of organisms',
      'Human body systems (digestive, circulatory, respiratory)',
      'Genetics and heredity (basic concepts)',
      'Natural selection and evolution (introduction)',
      'Earth\'s layers and plate tectonics',
      'Rocks and minerals',
      'Weathering and erosion',
      'The water cycle and weather patterns',
      'Climate and climate change',
      'Renewable and nonrenewable resources',
      'Forces and motion (Newton\'s Laws)',
      'Simple machines',
      'Energy forms and transformations',
      'Waves (sound and light)',
      'The scientific method and experimental design',
      'Engineering design process'
    ]
  },
  'Grade 7': {
    'Maths - Number and operations': [
      'Add, subtract, multiply, and divide rational numbers fluently',
      'Add integers using counters',
      'Integer addition rules',
      'Add integers',
      'Subtract integers using counters',
      'Integer subtraction rules',
      'Subtract integers',
      'Integer addition and subtraction rules',
      'Add and subtract integers',
      'Complete addition and subtraction equations with integers',
      'Integer multiplication rules',
      'Multiply integers',
      'Integer division rules',
      'Equal quotients of integers',
      'Divide integers',
      'Integer multiplication and division rules',
      'Multiply and divide integers',
      'Complete multiplication and division equations with integers',
      'Add, subtract, multiply, and divide integers',
      'Add and subtract decimals',
      'Multiply decimals',
      'Divide decimals',
      'Add, subtract, multiply, or divide two decimals',
      'Add and subtract fractions',
      'Add and subtract mixed numbers',
      'Multiply fractions',
      'Multiply mixed numbers',
      'Divide fractions',
      'Divide mixed numbers',
      'Add and subtract positive and negative decimals',
      'Add and subtract positive and negative fractions',
      'Add and subtract rational numbers',
      'Apply addition and subtraction rules',
      'Multiply and divide positive and negative decimals',
      'Multiply and divide positive and negative fractions',
      'Multiply and divide rational numbers',
      'Apply multiplication and division rules',
      'Multiply fractions and whole numbers',
      'Add and subtract integers: word problems',
      'Evaluate numerical expressions involving integers',
      'Add and subtract decimals: word problems',
      'Multiply decimals and whole numbers: word problems',
      'Divide decimals by whole numbers: word problems',
      'Add, subtract, multiply, and divide decimals: word problems',
      'Maps with decimal distances',
      'Evaluate numerical expressions involving decimals',
      'Add and subtract fractions: word problems',
      'Add and subtract mixed numbers: word problems',
      'Multiply fractions and mixed numbers: word problems',
      'Divide fractions and mixed numbers: word problems',
      'Add, subtract, multiply, and divide fractions and mixed numbers: word problems',
      'Maps with fractional distances',
      'Evaluate numerical expressions involving fractions',
      'Multi-step word problems with positive rational numbers',
      'Add, subtract, multiply, and divide money amounts: word problems',
      'Price lists',
      'Unit prices: find the total price'
    ],
    'Maths - Proportionality': [
      'Write equations for proportional relationships from tables',
      'Identify proportional relationships by graphing',
      'Write equations for proportional relationships from graphs',
      'Identify proportional relationships from graphs and equations',
      'Interpret graphs of proportional relationships',
      'Write and solve equations for proportional relationships',
      'Constant rate of change: tables',
      'Constant rate of change: graphs',
      'Calculate unit rates from rates in mathematical and real-world problems',
      'Unit rates',
      'Calculate unit rates with fractions',
      'Find the constant of proportionality from a table',
      'Unit prices',
      'Unit prices with unit conversions',
      'Determine the constant of proportionality within mathematical and real-world problems',
      'Find the constant of proportionality from a graph',
      'Solve problems involving ratios, rates, and percents, including multi-step problems involving percent increase and percent decrease',
      'Compare ratios: word problems',
      'Solve word problems using unit rates',
      'Percents of numbers: word problems',
      'Solve percent equations: word problems',
      'Percent of change',
      'Percent of change: word problems',
      'Percent of change: find the original amount word problems',
      'Percent error: word problems',
      'Unit prices: find the total price',
      'Percent of a number: tax, discount, and more',
      'Which is the better coupon?',
      'Find the percent: tax, discount, and more',
      'Sale prices: find the original price',
      'Multi-step problems with percents',
      'Estimate tips',
      'Convert between customary and metric systems'
    ],
    'Maths - Expressions, equations, and relationships': [
      'Identify independent and dependent variables',
      'Find a value using two-variable equations',
      'Evaluate two-variable equations: word problems',
      'Complete a table for a two-variable relationship',
      'Write a two-variable equation',
      'Identify the graph of an equation',
      'Graph a two-variable equation',
      'Interpret a graph: word problems',
      'Write an equation from a graph using a table',
      'Write a linear equation from a graph',
      'Write one-variable two-step equations',
      'Write one-variable two-step inequalities',
      'Represent equations and inequalities on number lines',
      'Determine solutions to equations and inequalities',
      'Solve equations using geometric concepts',
      'Algebraic reasoning with angles and sides of geometric figures'
    ],
    'Maths - Measurement and data': [
      'Volume of rectangular prisms and pyramids',
      'Surface area of rectangular prisms and pyramids',
      'Relationship between volume of prisms and pyramids',
      'Circumference and area of circles',
      'Area of composite figures',
      'Comparing data using box plots and dot plots',
      'Inference from random samples',
      'Comparing populations based on random samples',
      'Measures of central tendency and variability',
      'Volume of cubes and prisms',
      'Volume of cubes and rectangular prisms: word problems',
      'Volume of pyramids',
      'Parts of a circle',
      'Circumference of circles',
      'Area of circles',
      'Circles: word problems',
      'Semicircles: calculate area, perimeter, radius, and diameter',
      'Quarter circles: calculate area, perimeter, and radius',
      'Area of compound figures with triangles',
      'Area of compound figures with triangles, semicircles, and quarter circles',
      'Nets of prisms and pyramids',
      'Surface area of cubes and prisms',
      'Surface area of pyramids',
      'Lateral area of prisms and pyramids'
    ],
    'Maths - Personal financial literacy': [
      'Calculate sales tax for a given purchase',
      'Calculate income tax for earned wages',
      'Identify components of a personal budget',
      'Calculate percentage of each budget category',
      'Create financial records and net worth statements',
      'Use budget estimators for household budgeting',
      'Calculate simple interest',
      'Calculate compound interest',
      'Analyze monetary incentives (sales, rebates, coupons)',
      'Solve percent equations',
      'Unit prices',
      'Percent of a number: tax, discount, and more',
      'Which is the better coupon?',
      'Find the percent: tax, discount, and more',
      'Sale prices: find the original price',
      'Multi-step problems with percents'
    ],
    'Maths - Probability and statistics': [
      'Represent sample spaces for simple events using lists and tree diagrams',
      'Represent sample spaces for compound events using lists and tree diagrams',
      'Find the number of outcomes for compound events',
      'Select and use different simulations to represent events',
      'Make predictions using experimental probability',
      'Determine solutions using experimental probability',
      'Make predictions using theoretical probability',
      'Find probabilities of simple events and their complements',
      'Estimate population size using proportions',
      'Solve problems using data in bar graphs, dot plots, and circle graphs',
      'Interpret line plots with fractions',
      'Interpret bar graphs',
      'Interpret circle graphs',
      'Estimate population size using proportions',
      'Make predictions using experimental and theoretical probability',
      'Determine experimental and theoretical probabilities for events',
      'Identify independent and dependent events',
      'Find probabilities of independent and dependent events'
    ],
    'Science - Scientific investigation and reasoning': [
      'Laboratory safety: Demonstrate safe practices during laboratory and field investigations as outlined in TEA-approved safety standards',
      'Conservation of resources: Practice appropriate use and conservation of resources, including disposal, reuse, or recycling of materials',
      'Scientific investigations: Plan and implement comparative and descriptive investigations by making observations, asking well-defined questions, and using appropriate equipment and technology',
      'Scientific investigations: The process of scientific inquiry',
      'Scientific investigations: Identify laboratory tools',
      'Experimental design: Design and implement experimental investigations by making observations, asking well-defined questions, formulating testable hypotheses, and using appropriate equipment and technology',
      'Experimental design: Identify control and experimental groups',
      'Experimental design: Identify independent and dependent variables',
      'Experimental design: Identify the experimental question',
      'Experimental design: Identify questions that can be investigated with a set of materials',
      'Experimental design: Understand an experimental protocol about plant growth',
      'Experimental design: Understand an experimental protocol about diffusion',
      'Data collection: Collect and record data using the International System of Units (SI) and qualitative means such as labeled drawings, writing, and graphic organizers',
      'Data collection: Choose metric units of distance, mass, and volume',
      'Data analysis: Construct tables and graphs, using repeated trials and means, to organize data and identify patterns',
      'Data analysis: Use data from tests to compare engineering-design solutions',
      'Data analysis: Analyze data to formulate reasonable explanations, communicate valid conclusions supported by the data, and predict trends',
      'Critical thinking: Evaluate claims about natural resource use: groundwater',
      'Critical thinking: Factors affecting climate: altitude',
      'Critical thinking: Analyze data to compare properties of planets',
      'Models: Use models to represent aspects of the natural world such as human body systems and plant and animal cells',
      'Models: Plant cell diagrams - label parts',
      'Models: Animal cell diagrams - label parts',
      'Models: Organization in the human body',
      'Advantages and limitations of models: Analyze models of the Earth-Sun-Moon system',
      'Impact of research: Explore the engineering-design process: going to the Moon!',
      'Laboratory tools: Identify laboratory tools',
      'Safety equipment: Laboratory safety equipment'
    ],
    'Science - Matter and energy': [
      'Photosynthesis: Recognize that radiant energy from the Sun is transformed into chemical energy through photosynthesis',
      'Photosynthesis: How do plants use and change energy?',
      'Photosynthesis: Identify the photosynthetic organism',
      'Energy flow: Diagram the flow of energy through living systems, including food chains, food webs, and energy pyramids',
      'Energy flow: How does matter move in food chains?',
      'Energy flow: Interpret food webs I',
      'Energy flow: Interpret food webs II',
      'Energy flow: The carbon cycle',
      'Physical and chemical changes: Distinguish between physical and chemical changes in matter',
      'Physical and chemical changes: Compare physical and chemical changes'
    ],
    'Science - Force, motion, and energy': [
      'Energy transformations: Illustrate the transformation of energy within an organism such as the transfer from chemical energy to thermal energy',
      'Energy transformations: Explore energy transformations: bike ride',
      'Energy transformations: How do plants use and change energy?',
      'Forces affecting motion in organisms: Demonstrate and illustrate forces that affect motion in organisms such as emergence of seedlings, turgor pressure, geotropism, and circulation of blood'
    ],
    'Science - Earth and space': [
      'Catastrophic events: Predict and describe how catastrophic events such as floods, hurricanes, or tornadoes impact ecosystems',
      'Weathering and erosion: Analyze the effects of weathering, erosion, and deposition on the environment in ecoregions of Texas',
      'Weathering and erosion: How do rock layers form?',
      'Human impact on water: Model the effects of human activity on groundwater and surface water in a watershed',
      'Human impact on water: Evaluate claims about natural resource use: groundwater',
      'Solar system and life: Analyze the characteristics of objects in our solar system that allow life to exist such as the proximity of the Sun, presence of water, and composition of the atmosphere',
      'Space exploration: Identify the accommodations that enabled manned space exploration, considering the characteristics of our solar system',
      'Space exploration: Explore the engineering-design process: going to the Moon!'
    ],
    'Science - Organisms and environments': [
      'Ecosystems: Observe and describe how different environments support different varieties of organisms',
      'Ecosystems: Describe populations, communities, and ecosystems',
      'Ecosystems: Identify ecosystems',
      'Ecosystems: Describe ecosystems',
      'Biodiversity: Describe how biodiversity contributes to the sustainability of an ecosystem',
      'Biodiversity: Coral reef biodiversity and human uses - explore a problem',
      'Biodiversity: Coral reef biodiversity and human uses - evaluate solutions',
      'Ecological succession: Observe, record, and describe the role of ecological succession such as in a microhabitat of a garden with weeds',
      'Ecological succession: Investigate primary succession on a volcanic island',
      'Variation and survival: Explain variation within a population or species by comparing external features, behaviors, or physiology of organisms',
      'Variation and survival: How can animal behaviors affect reproductive success? Identify evidence to support a claim',
      'Variation and survival: Introduction to natural selection',
      'Variation and survival: Construct explanations of natural selection',
      'Genetic traits: Identify some changes in genetic traits that have occurred over several generations through natural selection and selective breeding',
      'Genetic traits: Calculate the percentages of traits in a population',
      'Genetic traits: Calculate the averages of traits in a population',
      'Genetic traits: Construct explanations of natural selection',
      'Human body systems: Identify the main functions of the systems of the human organism',
      'Human body systems: Body systems - circulation and respiration',
      'Human body systems: Body systems - digestion',
      'Human body systems: Body systems - removing waste',
      'Human body systems: Body systems - perception and motion',
      'Human body systems: Organization in the human body',
      'Human body systems: Science literacy - How does the nervous system produce phantom pain?',
      'Levels of organization: Recognize levels of organization in plants and animals, including cells, tissues, organs, organ systems, and organisms',
      'Cell organelles: Differentiate between structure and function in plant and animal cell organelles',
      'Cell organelles: Identify functions of plant cell parts',
      'Cell organelles: Identify functions of animal cell parts',
      'Cell organelles: Plant cell diagrams - label parts',
      'Cell organelles: Animal cell diagrams - label parts',
      'Cell organelles: Compare cells and cell parts',
      'Cell theory: Recognize the components of cell theory',
      'Cell theory: Understanding cells',
      'Heredity: Define heredity as the passage of genetic instructions from one generation to the next generation',
      'Heredity: Genetic variation in sexual reproduction',
      'Heredity: Genes, proteins, and traits - understanding the genetic code',
      'Reproduction: Compare the results of uniform or diverse offspring from asexual or sexual reproduction',
      'Reproduction: Cell division',
      'Reproduction: Genetics vocabulary - genotype and phenotype',
      'Reproduction: Genetics vocabulary - dominant and recessive',
      'Reproduction: Complete and interpret Punnett squares',
      'Reproduction: Use Punnett squares to calculate ratios of offspring types',
      'Reproduction: Use Punnett squares to calculate probabilities of offspring types',
      'Reproduction: Angiosperm and conifer life cycles',
      'Reproduction: Moss and fern life cycles',
      'Genes and chromosomes: Recognize that inherited traits of individuals are governed in the genetic material found in the genes within chromosomes in the nucleus',
      'Genes and chromosomes: Genes, proteins, and traits - understanding the genetic code',
      'Genes and chromosomes: Describe the effects of gene mutations on organisms'
    ],
  },
  'Grade 8': {
    'Maths - Number and operations': [
      'Number and operations - extend previous knowledge of sets and subsets using a visual representation to describe relationships between sets of real numbers',
      'Number and operations - Classify numbers',
      'Number and operations - approximate the value of an irrational number, including π and square roots of numbers less than 225, and locate that rational number approximation on a number line',
      'Number and operations - Estimate positive square roots',
      'Number and operations - Estimate positive and negative square roots',
      'Number and operations - Irrational numbers on number lines',
      'Number and operations - convert between standard decimal notation and scientific notation',
      'Number and operations - Convert between standard and scientific notation',
      'Number and operations - Compare numbers written in scientific notation',
      'Number and operations - order a set of real numbers arising from mathematical and real-world contexts',
      'Number and operations - Compare and order rational and irrational numbers',
      'Number and operations - Checkpoint: Classify real numbers',
      'Number and operations - Checkpoint: Approximate irrational numbers',
      'Number and operations - Checkpoint: Scientific notation'
    ],
    'Maths - Proportionality': [
      'Proportionality - generalize that the ratio of corresponding sides of similar shapes are proportional, including a shape and its dilation',
      'Proportionality - Similar and congruent figures',
      'Proportionality - Identify similar triangles',
      'Proportionality - Side lengths and angle measures of similar figures',
      'Proportionality - Similar solids',
      'Proportionality - compare and contrast the attributes of a shape and its dilation(s) on a coordinate plane',
      'Proportionality - Dilations: find the scale factor',
      'Proportionality - use an algebraic representation to explain the effect of a given positive rational scale factor applied to two-dimensional figures on a coordinate plane with the origin as the center of dilation',
      'Proportionality - Dilations: graph the image',
      'Proportionality - Dilations: find the coordinates',
      'Proportionality - Checkpoint: Dilations and similarity',
      'Proportionality - use similar right triangles to develop an understanding that slope, m, given as the rate comparing the change in y-values to the change in x-values, (y2 - y1)/ (x2 - x1), is the same for any two points (x1, y1) and (x2, y2) on the same line',
      'Proportionality - graph proportional relationships, interpreting the unit rate as the slope of the line that models the relationship',
      'Proportionality - Find the constant of proportionality from a graph',
      'Proportionality - Graph proportional relationships and find the slope',
      'Proportionality - use data from a table or graph to determine the rate of change or slope and y-intercept in mathematical and real-world problems',
      'Proportionality - Find the constant of proportionality from a table',
      'Proportionality - Find the constant of proportionality from a graph',
      'Proportionality - Find the slope from a graph',
      'Proportionality - Find the slope from a table',
      'Proportionality - Rate of change of a linear function: tables',
      'Proportionality - Rate of change of a linear function: graphs',
      'Proportionality - Interpret the slope and y-intercept of a linear function',
      'Proportionality - Write a linear function from a table',
      'Proportionality - Checkpoint: Interpret slopes and y-intercepts',
      'Proportionality - represent linear proportional situations with tables, graphs, and equations in the form of y = kx',
      'Proportionality - Proportional relationships: complete a table and make a graph',
      'Proportionality - Write equations for proportional relationships from tables',
      'Proportionality - Write equations for proportional relationships from graphs',
      'Proportionality - Graph proportional relationships and find the slope',
      'Proportionality - Interpret graphs of proportional relationships',
      'Proportionality - represent linear non-proportional situations with tables, graphs, and equations in the form of y = mx + b, where b ≠ 0',
      'Proportionality - Graph a line from an equation in slope-intercept form',
      'Proportionality - Complete a table for a linear relationship',
      'Proportionality - Interpret points on the graph of a linear function',
      'Proportionality - Write a linear function from a table',
      'Proportionality - Compare linear functions: tables, graphs, and equations',
      'Proportionality - Write linear functions: word problems',
      'Proportionality - Evaluate a linear function: word problems',
      'Proportionality - Complete a table and graph a linear relationship',
      'Proportionality - contrast bivariate sets of data that suggest a linear relationship with bivariate sets of data that do not suggest a linear relationship from a graphical representation',
      'Proportionality - Identify trends with scatter plots',
      'Proportionality - Outliers in scatter plots',
      'Proportionality - use a trend line that approximates the linear relationship between bivariate sets of data to make predictions',
      'Proportionality - Make predictions with scatter plots',
      'Proportionality - Identify lines of best fit',
      'Proportionality - Write equations for lines of best fit',
      'Proportionality - Interpret lines of best fit: word problems',
      'Proportionality - solve problems involving direct variation',
      'Proportionality - Interpret graphs of proportional relationships',
      'Proportionality - Write and solve equations for proportional relationships',
      'Proportionality - Find the constant of variation',
      'Proportionality - Identify direct variation',
      'Proportionality - Write direct variation equations',
      'Proportionality - Write and solve direct variation equations',
      'Proportionality - distinguish between proportional and non-proportional situations using tables, graphs, and equations in the form y = kx or y = mx + b, where b ≠ 0',
      'Proportionality - Identify proportional relationships by graphing',
      'Proportionality - Identify proportional relationships from graphs and equations',
      'Proportionality - Identify proportional relationships from tables',
      'Proportionality - identify functions using sets of ordered pairs, tables, mappings, and graphs',
      'Proportionality - Identify functions',
      'Proportionality - Identify functions: graphs',
      'Proportionality - identify examples of proportional and non-proportional functions that arise from mathematical and real-world problems',
      'Proportionality - Identify proportional relationships by graphing',
      'Proportionality - Identify proportional relationships from graphs and equations',
      'Proportionality - Identify proportional relationships from tables',
      'Proportionality - Identify proportional relationships: word problems',
      'Proportionality - write an equation in the form y = mx + b to model a linear relationship between two quantities using verbal, numerical, tabular, and graphical representations',
      'Proportionality - Write a linear equation from a slope and y-intercept',
      'Proportionality - Write a linear equation from a graph',
      'Proportionality - Write a linear equation from a slope and a point',
      'Proportionality - Write a linear equation from two points',
      'Proportionality - Convert a linear equation in standard form to slope-intercept form',
      'Proportionality - Write a linear function from a table',
      'Proportionality - Write linear functions: word problems',
      'Proportionality - Checkpoint: Proportional relationships',
      'Proportionality - Checkpoint: Interpret proportional and non-proportional relationships',
      'Proportionality - Checkpoint: Linear models',
      'Proportionality - Checkpoint: Understand functions',
      'Proportionality - Checkpoint: Construct and interpret linear functions'
    ],
    'Maths - Expressions, equations, and relationships': [
      'Expressions, equations, and relationships - describe the volume formula V = Bh of a cylinder in terms of its base area and its height',
      'Expressions, equations, and relationships - model the relationship between the volume of a cylinder and a cone having both congruent bases and heights and connect that relationship to the formulas',
      'Expressions, equations, and relationships - use models and diagrams to explain the Pythagorean theorem',
      'Expressions, equations, and relationships - solve problems involving the volume of cylinders, cones, and spheres',
      'Expressions, equations, and relationships - Volume of cylinders',
      'Expressions, equations, and relationships - Volume of cones',
      'Expressions, equations, and relationships - Volume of spheres',
      'Expressions, equations, and relationships - use previous knowledge of surface area to make connections to the formulas for lateral and total surface area and determine solutions for problems involving rectangular prisms, triangular prisms, and cylinders',
      'Expressions, equations, and relationships - Surface area of prisms',
      'Expressions, equations, and relationships - Surface area of cylinders',
      'Expressions, equations, and relationships - Lateral area of prisms',
      'Expressions, equations, and relationships - Lateral area of cylinders',
      'Expressions, equations, and relationships - use the Pythagorean Theorem and its converse to solve problems',
      'Expressions, equations, and relationships - Pythagorean theorem: find the length of the hypotenuse',
      'Expressions, equations, and relationships - Pythagorean theorem: find the missing leg length',
      'Expressions, equations, and relationships - Pythagorean theorem: find the missing leg or hypotenuse length',
      'Expressions, equations, and relationships - Pythagorean theorem: find the perimeter',
      'Expressions, equations, and relationships - Pythagorean theorem: word problems',
      'Expressions, equations, and relationships - Converse of the Pythagorean theorem: is it a right triangle?',
      'Expressions, equations, and relationships - determine the distance between two points on a coordinate plane using the Pythagorean Theorem',
      'Expressions, equations, and relationships - Find the distance between two points',
      'Expressions, equations, and relationships - Checkpoint: Volume',
      'Expressions, equations, and relationships - Checkpoint: Surface area of prisms and cylinders',
      'Expressions, equations, and relationships - Checkpoint: The Pythagorean theorem',
      'Expressions, equations, and relationships - write one-variable equations or inequalities with variables on both sides that represent problems using rational number coefficients and constants',
      'Expressions, equations, and relationships - Solve equations with variables on both sides: word problems',
      'Expressions, equations, and relationships - Create equations with no solutions or infinitely many solutions',
      'Expressions, equations, and relationships - write a corresponding real-world problem when given a one-variable equation or inequality with variables on both sides of the equal sign using rational number coefficients and constants',
      'Expressions, equations, and relationships - model and solve one-variable equations with variables on both sides of the equal sign that represent mathematical and real-world problems using rational number coefficients and constants',
      'Expressions, equations, and relationships - Solve equations involving like terms',
      'Expressions, equations, and relationships - Solve equations with variables on both sides',
      'Expressions, equations, and relationships - Solve equations with variables on both sides: fractional coefficients',
      'Expressions, equations, and relationships - Solve equations with variables on both sides: word problems',
      'Expressions, equations, and relationships - Solve equations with the distributive property',
      'Expressions, equations, and relationships - Solve multi-step equations',
      'Expressions, equations, and relationships - Solve multi-step equations with fractional coefficients',
      'Expressions, equations, and relationships - Solve equations: mixed review',
      'Expressions, equations, and relationships - Solve multi-step equations: complete the solution',
      'Expressions, equations, and relationships - use informal arguments to establish facts about the angle sum and exterior angle of triangles, the angles created when parallel lines are cut by a transversal, and the angle-angle criterion for similarity of triangles',
      'Expressions, equations, and relationships - Identify alternate interior and alternate exterior angles',
      'Expressions, equations, and relationships - Transversals of parallel lines: name angle pairs',
      'Expressions, equations, and relationships - Transversals of parallel lines: find angle measures',
      'Expressions, equations, and relationships - Transversals of parallel lines: solve for x',
      'Expressions, equations, and relationships - Find missing angles in triangles',
      'Expressions, equations, and relationships - Find missing angles in triangles using ratios',
      'Expressions, equations, and relationships - Triangle Angle-Sum Theorem',
      'Expressions, equations, and relationships - Exterior Angle Theorem',
      'Expressions, equations, and relationships - Angle-angle criterion for similar triangles',
      'Expressions, equations, and relationships - Checkpoint: Triangles and transversals',
      'Expressions, equations, and relationships - Checkpoint: Problem solving with equations and inequalities',
      'Expressions, equations, and relationships - identify and verify the values of x and y that simultaneously satisfy two linear equations in the form y = mx + b from the intersections of the graphed equations',
      'Expressions, equations, and relationships - Is (x, y) a solution to the system of equations?',
      'Expressions, equations, and relationships - Solve a system of equations by graphing: word problems',
      'Expressions, equations, and relationships - Solve a system of equations by graphing',
      'Expressions, equations, and relationships - Checkpoint: Systems of equations'
    ],
    'Maths - Two-dimensional shapes': [
      'Two-dimensional shapes - generalize the properties of orientation and congruence of rotations, reflections, translations, and dilations of two-dimensional shapes on a coordinate plane',
      'Two-dimensional shapes - Determine if two figures are congruent: justify your answer',
      'Two-dimensional shapes - Dilations: find the scale factor',
      'Two-dimensional shapes - differentiate between transformations that preserve congruence and those that do not',
      'Two-dimensional shapes - Identify reflections, rotations, and translations',
      'Two-dimensional shapes - explain the effect of translations, reflections over the x- or y-axis, and rotations limited to 90°, 180°, 270°, and 360° as applied to two-dimensional shapes on a coordinate plane using an algebraic representation',
      'Two-dimensional shapes - Identify reflections, rotations, and translations',
      'Two-dimensional shapes - Translations: graph the image',
      'Two-dimensional shapes - Translations: find the coordinates',
      'Two-dimensional shapes - Translations: write the rule',
      'Two-dimensional shapes - Reflections over the x- and y-axes: graph the image',
      'Two-dimensional shapes - Reflections over the x- and y-axes: find the coordinates',
      'Two-dimensional shapes - Rotations: graph the image',
      'Two-dimensional shapes - Rotations: find the coordinates',
      'Two-dimensional shapes - Reflections and rotations: write the rule',
      'Two-dimensional shapes - Describe transformations',
      'Two-dimensional shapes - model the effect on linear and area measurements of dilated two-dimensional shapes',
      'Two-dimensional shapes - Area and perimeter of similar figures',
      'Two-dimensional shapes - Checkpoint: Translations, reflections, and rotations on the coordinate plane',
      'Two-dimensional shapes - Checkpoint: Properties of transformations'
    ],
    'Maths - Measurement and data': [
      'Measurement and data - construct a scatterplot and describe the observed data to address questions of association such as linear, non-linear, and no association between bivariate data',
      'Measurement and data - Create scatter plots',
      'Measurement and data - Identify trends with scatter plots',
      'Measurement and data - Outliers in scatter plots',
      'Measurement and data - determine the mean absolute deviation and use this quantity as a measure of the average distance data are from the mean using a data set of no more than 10 data points',
      'Measurement and data - Calculate mean absolute deviation',
      'Measurement and data - Interpret mean and mean absolute deviation',
      'Measurement and data - simulate generating random samples of the same size from a population with known characteristics to develop the notion of a random sample being representative of the population from which it was selected',
      'Measurement and data - Which simulation represents the situation?',
      'Measurement and data - Identify representative, random, and biased samples',
      'Measurement and data - Checkpoint: Scatter plots',
      'Measurement and data - Checkpoint: Mean absolute deviation'
    ],
    'Maths - Personal financial literacy': [
      'Personal financial literacy - solve real-world problems comparing how interest rate and loan length affect the cost of credit',
      'Personal financial literacy - calculate the total cost of repaying a loan, including credit cards and easy access loans, under various rates of interest and over different periods using an online calculator',
      'Personal financial literacy - explain how small amounts of money invested regularly, including money saved for college and retirement, grow over time',
      'Personal financial literacy - calculate and compare simple interest and compound interest earnings',
      'Personal financial literacy - Simple interest',
      'Personal financial literacy - Compound interest',
      'Personal financial literacy - identify and explain the advantages and disadvantages of different payment methods',
      'Personal financial literacy - Identify advantages and disadvantages of payment methods',
      'Personal financial literacy - Debit cards and credit cards',
      'Personal financial literacy - analyze situations to determine if they represent financially responsible decisions and identify the benefits of financial responsibility and the costs of financial irresponsibility',
      'Personal financial literacy - estimate the cost of a two-year and four-year college education, including family contribution, and devise a periodic savings plan for accumulating the money needed to contribute to the total cost of attendance for at least the first year of college'
    ],
    'Science - Scientific investigation and reasoning': [
      'Laboratory safety: Demonstrate safe practices during laboratory and field investigations as outlined in TEA-approved safety standards',
      'Conservation of resources: Practice appropriate use and conservation of resources, including disposal, reuse, or recycling of materials',
      'Scientific investigations: Plan and implement comparative and descriptive investigations by making observations, asking well-defined questions, and using appropriate equipment and technology',
      'Scientific investigations: The process of scientific inquiry',
      'Scientific investigations: Identify laboratory tools',
      'Experimental design: Design and implement experimental investigations by making observations, asking well-defined questions, formulating testable hypotheses, and using appropriate equipment and technology',
      'Experimental design: Identify control and experimental groups',
      'Experimental design: Identify independent and dependent variables',
      'Experimental design: Identify the experimental question',
      'Experimental design: Identify questions that can be investigated with a set of materials',
      'Experimental design: Understand an experimental protocol about plant growth',
      'Experimental design: Understand an experimental protocol about diffusion',
      'Experimental design: Understand an experimental protocol about evaporation',
      'Data collection: Collect and record data using the International System of Units (SI) and qualitative means such as labeled drawings, writing, and graphic organizers',
      'Data collection: Choose metric units of distance, mass, and volume',
      'Data analysis: Construct tables and graphs, using repeated trials and means, to organize data and identify patterns',
      'Data analysis: Use data from tests to compare engineering-design solutions',
      'Data analysis: Analyze data to formulate reasonable explanations, communicate valid conclusions supported by the data, and predict trends',
      'Critical thinking: Analyze, evaluate, and critique scientific explanations by using empirical evidence, logical reasoning, and experimental and observational testing',
      'Critical thinking: Coral reef biodiversity and human uses: evaluate solutions',
      'Models: Use models to represent aspects of the natural world such as an atom, a molecule, space, or a geologic feature',
      'Models: How are substances represented by chemical formulas and models?',
      'Models: Identify chemical formulas for ball-and-stick models',
      'Models: Classify elementary substances and compounds using models',
      'Models: Label Earth features at tectonic plate boundaries',
      'Models: Analyze models of the Earth-Sun-Moon system',
      'Models: Identify phases of the Moon',
      'Models: What causes the seasons on Earth?',
      'Advantages and limitations of models: Analyze models of the Earth-Sun-Moon system',
      'Impact of research: Explore the engineering-design process: going to the Moon!',
      'Laboratory tools: Identify laboratory tools',
      'Safety equipment: Laboratory safety equipment'
    ],
    'Science - Matter and energy': [
      'Atoms and elements: Describe the structure of atoms, including the masses, electrical charges, and locations, of protons and neutrons in the nucleus and electrons in the electron cloud',
      'Atoms and elements: What are atoms and chemical elements?',
      'Atoms and elements: Identify that protons determine an element\'s identity and valence electrons determine its chemical properties, including reactivity',
      'Periodic Table: Interpret the arrangement of the Periodic Table, including groups and periods, to explain how properties are used to classify elements',
      'Chemical formulas: Recognize that chemical formulas are used to identify substances and determine the number of atoms of each element in chemical formulas containing subscripts',
      'Chemical formulas: How are substances represented by chemical formulas and models?',
      'Chemical formulas: Identify chemical formulas for ball-and-stick models',
      'Chemical formulas: Describe the atomic composition of molecules',
      'Chemical reactions: Investigate how evidence of chemical reactions indicates that new substances with different properties are formed and how that relates to the law of conservation of mass',
      'Chemical reactions: Identify reactants and products',
      'Chemical reactions: Count atoms and molecules in chemical reactions',
      'Chemical reactions: Calculate amounts of reactants or products in chemical reactions',
      'Chemical reactions: Describe energy changes in chemical reactions',
      'Chemical reactions: Compare physical and chemical changes'
    ],
    'Science - Force, motion, and energy': [
      'Forces and motion: Demonstrate and calculate how unbalanced forces change the speed or direction of an object\'s motion',
      'Forces and motion: How does mass affect force and acceleration?',
      'Forces and motion: Balanced and unbalanced forces',
      'Speed, velocity, and acceleration: Differentiate between speed, velocity, and acceleration',
      'Speed, velocity, and acceleration: Calculate velocity from time and distance',
      'Speed, velocity, and acceleration: Calculate distance from velocity and time',
      'Speed, velocity, and acceleration: Calculate time from velocity and distance',
      'Speed, velocity, and acceleration: Calculate velocity, distance, and time I',
      'Speed, velocity, and acceleration: Calculate velocity, distance, and time II',
      'Speed, velocity, and acceleration: Identify whether objects are accelerating',
      'Newton\'s laws: Investigate and describe applications of Newton\'s three laws of motion such as in vehicle restraints, sports activities, amusement park rides, Earth\'s tectonic activities, and rocket launches',
      'Newton\'s laws: How does mass affect force and acceleration?',
      'Newton\'s laws: Predict forces using Newton\'s third law',
      'Newton\'s laws: Balanced and unbalanced forces',
      'Newton\'s laws: Collisions and car safety features'
    ],
    'Science - Earth and space': [
      'Earth, Sun, and Moon: Model and illustrate how the tilted Earth rotates on its axis, causing day and night, and revolves around the Sun, causing changes in seasons',
      'Earth, Sun, and Moon: Analyze models of the Earth-Sun-Moon system',
      'Earth, Sun, and Moon: What causes the seasons on Earth?',
      'Lunar cycle: Demonstrate and predict the sequence of events in the lunar cycle',
      'Lunar cycle: Identify phases of the Moon',
      'Tides: Relate the positions of the Moon and Sun to their effect on ocean tides',
      'Universe: Describe components of the universe, including stars, nebulae, and galaxies, and use models such as the Hertzsprung-Russell diagram for classification',
      'Universe: Recognize that the Sun is a medium-sized star located in a spiral arm of the Milky Way galaxy and that the Sun is many thousands of times closer to Earth than any other star',
      'Universe: Identify how different wavelengths of the electromagnetic spectrum such as visible light and radio waves are used to gain information about components in the universe',
      'Universe: Applications of infrared waves',
      'Universe: Effects of ultraviolet waves',
      'Universe: Research how scientific data are used as evidence to develop scientific theories to describe the origin of the universe',
      'Plate tectonics: Describe the historical development of evidence that supports plate tectonic theory',
      'Plate tectonics: Relate plate tectonics to the formation of crustal features',
      'Plate tectonics: Label Earth features at tectonic plate boundaries',
      'Plate tectonics: Describe tectonic plate boundaries around the world',
      'Weathering and erosion: Interpret topographic maps and satellite views to identify land and erosional features and predict how these features may be reshaped by weathering',
      'Weathering and erosion: Identify Earth\'s land features using satellite images',
      'Weathering and erosion: Select parts of a topographic map',
      'Climate: Recognize that the Sun provides the energy that drives convection within the atmosphere and oceans, producing winds',
      'Climate: Factors affecting climate: distance from the ocean',
      'Climate: Factors affecting climate: surface ocean currents',
      'Climate: Identify how global patterns of atmospheric movement influence local weather using weather maps that show high and low pressures and fronts',
      'Climate: Explore air masses',
      'Climate: Identify and compare air masses',
      'Climate: How do air masses form?',
      'Weather systems: Identify the role of the oceans in the formation of weather systems such as hurricanes',
      'Weather systems: Label parts of water cycle diagrams',
      'Weather systems: Select parts of water cycle diagrams'
    ],
    'Science - Organisms and environments': [
      'Ecosystem dependencies: Investigate how organisms and populations in an ecosystem depend on and may compete for biotic factors such as food and abiotic factors such as quantity of light, water, range of temperatures, or soil composition',
      'Ecosystem dependencies: Use food chains to predict changes in populations',
      'Ecosystem dependencies: Classify symbiotic relationships',
      'Environmental changes: Explore how short- and long-term environmental changes affect organisms and traits in subsequent populations',
      'Environmental changes: How do genes and the environment affect plant growth?',
      'Environmental changes: Introduction to natural selection',
      'Environmental changes: Construct explanations of natural selection',
      'Human impact: Recognize human dependence on ocean systems and explain how human activities such as runoff, artificial reefs, or use of resources have modified these systems',
      'Human impact: Coral reef biodiversity and human uses: explore a problem',
      'Human impact: Coral reef biodiversity and human uses: evaluate solutions'
    ]
  },
  'Grade 9': {
    Mathematics: [
      'Solving linear equations and inequalities',
      'Systems of equations and inequalities',
      'Functions (domain, range, notation)',
      'Graphing linear functions',
      'Slope and rate of change',
      'Forms of linear equations (slope-intercept, point-slope, standard)',
      'Arithmetic and geometric sequences',
      'Exponential functions',
      'Polynomial expressions (addition, subtraction, multiplication)',
      'Factoring polynomials',
      'Quadratic functions and equations',
      'Graphing quadratic functions',
      'Solving quadratic equations (factoring, quadratic formula)',
      'Completing the square',
      'Data analysis and statistical measures',
      'Probability distributions',
      'Transformations of functions',
      'Radical expressions and equations',
      'Rational expressions and equations',
      'Introduction to trigonometry (sine, cosine, tangent)',
      'Modeling with functions'
    ],
    Science: [
      'Introduction to biology',
      'Cell structure and function',
      'Cellular processes (photosynthesis, respiration, transport)',
      'Genetics and heredity (Mendelian genetics, DNA structure)',
      'Biotechnology and genetic engineering',
      'Evolution and natural selection',
      'Classification and biodiversity',
      'Ecology (populations, communities, ecosystems)',
      'Biomes and aquatic ecosystems',
      'Human body systems (overview)',
      'Introduction to chemistry',
      'Atomic structure and bonding',
      'Chemical reactions and stoichiometry',
      'Solutions and solubility',
      'Acids, bases, and pH',
      'Introduction to physics',
      'Motion and forces',
      'Work and energy',
      'Waves and sound',
      'Light and optics',
      'Scientific inquiry and experimental design'
    ]
  },
  'Grade 10': {
    Mathematics: [
      'Quadratic functions and equations (advanced)',
      'Polynomial functions (graphs, zeros, end behavior)',
      'Rational functions (graphs, asymptotes, domain, range)',
      'Radical functions',
      'Exponential and logarithmic functions',
      'Properties of logarithms',
      'Solving exponential and logarithmic equations',
      'Trigonometric functions (unit circle, graphs)',
      'Trigonometric identities',
      'Solving trigonometric equations',
      'Geometry (circles, polygons, solids)',
      'Proofs in geometry',
      'Coordinate geometry',
      'Conic sections (parabolas, circles, ellipses, hyperbolas)',
      'Probability (conditional probability, independence)',
      'Statistics (sampling, distributions, hypothesis testing)',
      'Sequences and series',
      'Vectors',
      'Matrices',
      'Complex numbers',
      'Modeling with geometry'
    ],
    Science: [
      'Biology',
      'Biochemistry (organic molecules, enzymes)',
      'Cellular reproduction (mitosis, meiosis)',
      'Genetics (DNA replication, protein synthesis, mutations)',
      'Molecular biology and biotechnology',
      'Evolution (evidence, mechanisms, speciation)',
      'Ecology (energy flow, nutrient cycles, population dynamics)',
      'Human impact on ecosystems',
      'Human body systems (detailed study)',
      'Chemistry',
      'Thermochemistry and thermodynamics',
      'Chemical kinetics and equilibrium',
      'Organic chemistry (introduction)',
      'Nuclear chemistry',
      'Physics',
      'Motion in two dimensions',
      'Forces and Newton\'s laws (applications)',
      'Momentum and impulse',
      'Circular motion and gravitation',
      'Energy (conservation, work-energy theorem)',
      'Electricity and magnetism (circuits, fields, induction)',
      'Electromagnetic waves'
    ]
  },
  'Grade 11': {
    Mathematics: [
      'Functions (advanced transformations, inverses)',
      'Polynomial functions (division, remainder theorem, factor theorem)',
      'Rational functions (advanced analysis)',
      'Exponential and logarithmic functions (applications)',
      'Trigonometric functions (law of sines, law of cosines)',
      'Trigonometric identities and equations (advanced)',
      'Vectors (operations, dot product, cross product)',
      'Matrices (operations, determinants, inverses)',
      'Systems of equations and matrices',
      'Conic sections (advanced)',
      'Sequences and series (convergence, divergence)',
      'Limits (introduction)',
      'Derivatives (introduction, rules)',
      'Applications of derivatives (rates of change, optimization)',
      'Integrals (introduction, basic techniques)',
      'Probability and statistics (advanced)',
      'Data analysis and regression',
      'Complex numbers (polar form, De Moivre\'s theorem)',
      'Parametric equations',
      'Polar coordinates'
    ],
    Science: [
      'Biology',
      'Molecular genetics (gene regulation, genomics)',
      'Biotechnology (cloning, gene therapy, GMOs)',
      'Evolutionary biology (population genetics, phylogeny)',
      'Ecology (conservation biology, global change)',
      'Human physiology (homeostasis, feedback mechanisms)',
      'Chemistry',
      'Chemical bonding (VSEPR theory, molecular orbital theory)',
      'Gases (gas laws, kinetic molecular theory)',
      'Solutions (colligative properties)',
      'Acids and bases (equilibrium, buffers)',
      'Electrochemistry',
      'Organic chemistry (functional groups, reactions)',
      'Physics',
      'Fluid mechanics',
      'Thermodynamics',
      'Oscillations and waves',
      'Optics (diffraction, interference)',
      'Quantum mechanics (introduction)',
      'Nuclear physics',
      'Special relativity (introduction)'
    ]
  },
  'Grade 12': {
    Mathematics: [
      'Limits (advanced)',
      'Derivatives (advanced techniques, applications)',
      'Integrals (advanced techniques, applications)',
      'Differential equations (introduction)',
      'Multivariable calculus (partial derivatives, multiple integrals)',
      'Vector calculus (vector fields, line integrals, surface integrals)',
      'Linear algebra (vector spaces, linear transformations)',
      'Abstract algebra (groups, rings, fields - introduction)',
      'Number theory (divisibility, congruences, prime numbers)',
      'Discrete mathematics (graph theory, combinatorics)',
      'Mathematical modeling',
      'Calculus-based probability and statistics',
      'Complex analysis (introduction)',
      'Real analysis (introduction)',
      'Topology (introduction)',
      'Numerical analysis',
      'History of mathematics',
      'Financial mathematics',
      'Actuarial science',
      'Operations research',
      'Cryptography'
    ],
    Science: [
      'Advanced topics in Biology, Chemistry, and Physics (depending on student\'s chosen path)',
      'Research projects',
      'Independent study',
      'AP or IB level courses in science',
      'Specialized science courses (e.g., anatomy, physiology, astronomy, environmental science, forensic science)',
      'Science, technology, and society',
      'Ethics in science',
      'Current events in science',
      'Preparation for college-level science',
      'Astronomy',
      'Cosmology',
      'Astrophysics',
      'Planetary science',
      'Environmental science',
      'Geology',
      'Meteorology',
      'Oceanography',
      'Forensic science',
      'Engineering (various disciplines)',
      'Computer science (advanced topics)'
    ]
  },
  'High School - Science': {
    'Biology': [
      'Cell Structure and Function',
      'Cell Transport',
      'Photosynthesis and Cellular Respiration',
      'Cellular Reproduction (Mitosis and Meiosis)',
      'DNA Structure and Replication',
      'RNA and Protein Synthesis',
      'Mendelian Genetics',
      'Non-Mendelian Genetics',
      'Evolution by Natural Selection',
      'Classification and Taxonomy',
      'Ecology and Ecosystems',
      'Biomes',
      'Population Dynamics',
      'Human Impact on the Environment',
      'Anatomy and Physiology of Major Systems',
      'Viruses and Bacteria',
      'Immune System',
      'Plant Structure and Function',
      'Biotechnology',
      'Genetic Engineering'
    ],
    'Chemistry': [
      'Atomic Structure and the Periodic Table',
      'Chemical Bonding',
      'Nomenclature and Chemical Formulas',
      'Chemical Reactions and Equations',
      'Stoichiometry',
      'States of Matter and Phase Changes',
      'Solutions and Solubility',
      'Acids, Bases, and pH',
      'Thermochemistry',
      'Reaction Rates and Chemical Equilibrium',
      'Oxidation-Reduction Reactions',
      'Organic Chemistry Basics',
      'Hydrocarbons and Functional Groups',
      'Nuclear Chemistry',
      'Gas Laws',
      'The Mole Concept',
      'Types of Chemical Reactions',
      'Properties of Water',
      'Chemical Kinetics',
      'Electrochemistry'
    ],
    'Physics': [
      'Kinematics (Motion in One and Two Dimensions)',
      'Forces and Newton\'s Laws of Motion',
      'Work, Energy, and Power',
      'Momentum and Impulse',
      'Circular Motion and Rotation',
      'Gravitation',
      'Simple Harmonic Motion',
      'Waves and Sound',
      'Electromagnetism',
      'Electric Charge and Electric Fields',
      'Electric Potential and Capacitance',
      'Electric Circuits',
      'Magnetic Fields and Forces',
      'Electromagnetic Induction',
      'Light and Optics',
      'Reflection and Refraction',
      'Thermodynamics',
      'Heat Transfer',
      'Quantum Physics basics',
      'Nuclear Physics basics'
    ],
    'AP®︎/College Environmental Science': [
      'Ecology and Ecosystems',
      'Biodiversity',
      'Population Ecology',
      'Water Resources and Pollution',
      'Air Pollution',
      'Climate Change',
      'Fossil Fuels and Energy Production',
      'Renewable Energy Sources',
      'Waste Management',
      'Soil and Agriculture',
      'Land Use and Conservation',
      'Environmental Laws and Regulations',
      'Environmental Toxicology',
      'Human Population Growth',
      'Sustainability',
      'Global Warming',
      'Ozone Depletion',
      'Deforestation',
      'Urbanization',
      'Environmental Ethics'
    ],
    'AP®︎/College Physics 1': [
      'Kinematics',
      'Dynamics',
      'Circular Motion and Gravitation',
      'Energy',
      'Momentum',
      'Simple Harmonic Motion',
      'Torque and Rotational Motion',
      'Mechanical Waves and Sound',
      'Electrostatics',
      'DC Circuits',
      'Simple Circuits',
      'Fluid Mechanics',
      'Thermodynamics',
      'Heat Transfer',
      'Work and Power',
      'Conservation Laws',
      'Wave Properties',
      'Resonance',
      'Superposition',
      'Interference'
    ],
    'AP®︎/College Physics 2': [
      'Electrostatics and Electric Fields',
      'Electric Potential and Capacitance',
      'Magnetic Fields',
      'Electromagnetic Induction',
      'AC Circuits',
      'Mechanical Waves and Sound',
      'Ray and Wave Optics',
      'Quantum Physics',
      'Atomic and Nuclear Physics',
      'Thermodynamics',
      'Fluid Mechanics',
      'Kinetic Theory and PV Diagrams',
      'Entropy and the Second Law of Thermodynamics',
      'Electric Circuits',
      'Magnetism',
      'Inductance',
      'Maxwell\'s Equations',
      'Electromagnetic Waves',
      'Geometric and Physical Optics',
      'Quantum Mechanics',
      'Atomic and Nuclear Physics',
      'Particle Physics',
      'Relativity'
    ],
  },
  'Computing': {
    'Intro to CS - Python': [
      'Basic Syntax and Data Types',
      'Variables and Operators',
      'Control Flow (if, else, loops)',
      'Functions',
      'Lists and Tuples',
      'Dictionaries',
      'String Manipulation',
      'File Input/Output',
      'Introduction to Modules',
      'Debugging Techniques',
      'Basic Algorithms (e.g., searching, sorting)',
      'Object-Oriented Programming Basics',
      'Working with Libraries',
      'Error Handling',
      'Basic Data Structures',
      'User Input',
      'Simple Game Development',
      'Data Visualization Basics',
      'Intro to Web Development with Python',
      'Version Control Basics (Git)'
    ],
    'Computer programming': [
      'Data Structures (Arrays, Linked Lists, Trees, Graphs)',
      'Algorithm Design and Analysis',
      'Object-Oriented Programming (OOP)',
      'Design Patterns',
      'Software Engineering Principles',
      'Databases and SQL',
      'Web Development (HTML, CSS, JavaScript)',
      'Version Control (Git)',
      'Testing and Debugging',
      'Software Architecture',
      'Operating Systems Concepts',
      'Networking Fundamentals',
      'Security Basics',
      'Concurrency and Parallelism',
      'Mobile App Development Basics',
      'Cloud Computing Basics',
      'Artificial Intelligence (AI) Basics',
      'Machine Learning Basics',
      'UI/UX Design Principles',
      'API Development and Usage'
    ],
  },
  'Arts & Humanities': {
    'AP®︎/College US History': [
      'Pre-Columbian America',
      'Colonial America',
      'The American Revolution',
      'The Constitution and the Early Republic',
      'The Civil War and Reconstruction',
      'The Gilded Age',
      'The Progressive Era',
      'World War I and the Roaring Twenties',
      'The Great Depression and the New Deal',
      'World War II',
      'The Cold War',
      'The Civil Rights Movement',
      'The Vietnam War',
      'The Reagan Era',
      'Post-Cold War America',
      '21st Century America',
      'Social and Cultural Developments',
      'Economic Transformations',
      'Political Changes and Reform',
      'Foreign Policy'
    ],
    'AP®︎/College US Government & Politics': [
      'Constitutional Principles',
      'Federalism',
      'Political Beliefs and Behaviors',
      'Political Parties and Interest Groups',
      'Elections and Voting Behavior',
      'The Legislative Branch',
      'The Executive Branch',
      'The Judicial Branch',
      'Civil Liberties and Civil Rights',
      'Public Policy',
      'The Media and Politics',
      'Political Participation',
      'State and Local Government',
      'Campaign Finance',
      'Political Ideologies',
      'Bureaucracy and Public Administration',
      'The Budget Process',
      'Foreign Policy and National Security',
      'Policy Analysis',
      'Contemporary Political Issues'
    ],
    'AP®︎/College World History': [
      'The Neolithic Revolution',
      'Early Civilizations',
      'Classical Civilizations',
      'The Rise of Major Religions',
      'The Post-Classical Era',
      'The Age of Exploration',
      'The Renaissance and Reformation',
      'The Scientific Revolution',
      'The Enlightenment',
      'The French Revolution',
      'The Industrial Revolution',
      'Imperialism and Colonialism',
      'World War I',
      'World War II',
      'The Cold War',
      'Decolonization',
      'Globalization',
      '20th and 21st Century Conflicts',
      'Global Economic Development',
      'Contemporary Global Issues'
    ],
    'Big History Project': [
      'The Big Bang and the Origin of the Universe',
      'The Formation of Stars and Planets',
      'The Origin of Life',
      'The Rise of Humans',
      'The Agricultural Revolution',
      'The Rise of Civilizations',
      'The Great Convergence',
      'The Anthropocene',
      'Future Scenarios',
      'Cosmic Evolution',
      'Biological Evolution',
      'Human Evolution',
      'The Role of Energy',
      'Complexity and Emergence',
      'Collective Learning',
      'The Global Impact of Humans',
      'Interdisciplinary Perspectives',
      'Scale of Time and Space',
      'Narrative and Storytelling',
      'Looking to the Future'
    ],
    'Climate project': [
      'The Science of Climate Change',
      'Greenhouse Gases and Global Warming',
      'Evidence of Climate Change',
      'Impacts of Climate Change',
      'Mitigation Strategies',
      'Adaptation Strategies',
      'Renewable Energy',
      'Carbon Capture and Storage',
      'Climate Policy and International Agreements',
      'Individual Actions to Reduce Carbon Footprint',
      'The Role of Technology in Climate Solutions',
      'Climate Justice',
      'Public Awareness and Engagement',
      'The Economics of Climate Change',
      'Sustainable Development',
      'Climate Modeling',
      'The Future of Climate Change',
      'The Politics of Climate Change',
      'Climate Change and Biodiversity',
      'Climate Change Communication'
    ],
    'AP®︎/College Art History': [
      'Prehistoric Art',
      'Ancient Art (Egypt, Mesopotamia, Greece, Rome)',
      'Medieval Art',
      'Renaissance Art',
      'Baroque Art',
      'Rococo Art',
      'Neoclassical Art',
      'Romantic Art',
      'Realism and Impressionism',
      'Post-Impressionism',
      'Early Modern Art',
      'Modern Art Movements (Cubism, Surrealism, etc.)',
      'Postmodern Art',
      'Contemporary Art',
      'Global Art Traditions',
      'Architectural History',
      'Sculptural Traditions',
      'The Role of Patronage',
      'Art and Social Change',
      'Art and Identity'
    ],
    'Constitution 101': [
      'The Constitutional Convention',
      'The Bill of Rights',
      'Separation of Powers',
      'Checks and Balances',
      'Federalism',
      'The Amendment Process',
      'Judicial Review',
      'Civil Liberties',
      'Civil Rights',
      'Constitutional Interpretation',
      'The Evolution of the Constitution',
      'Landmark Supreme Court Cases',
      'Constitutional Debates',
      'The Role of the Judiciary',
      'The Legislative Process',
      'The Executive Power',
      'Constitutional Rights',
      'The Constitution in Modern Society',
      'The Constitution and Democracy',
      'The Future of the Constitution'
    ],
  },
  'Economics': {
    'AP®︎/College Macroeconomics': [
      'Basic Economic Concepts',
      'Measuring Economic Performance',
      'National Income and Price Determination',
      'Aggregate Supply and Aggregate Demand',
      'Fiscal Policy',
      'Monetary Policy',
      'The Phillips Curve',
      'Economic Growth',
      'International Trade',
      'Foreign Exchange Markets',
      'The Balance of Payments',
      'Inflation and Unemployment',
      'The Business Cycle',
      'Long-Run Economic Growth',
      'Money and Banking',
      'Financial Markets',
      'Government Deficits and Debt',
      'Open Economy Macroeconomics',
      'Economic Indicators',
      'Economic Policy Debates'
    ],
    'AP®︎/College Microeconomics': [
      'Basic Economic Principles',
      'Supply and Demand',
      'Elasticity',
      'Consumer Choice and Utility',
      'Production and Costs',
      'Perfect Competition',
      'Monopoly',
      'Oligopoly',
      'Monopolistic Competition',
      'Market Structures',
      'Factor Markets',
      'Market Failure',
      'Externalities',
      'Public Goods',
      'Government Intervention',
      'Labor Markets',
      'Income Distribution',
      'Game Theory',
      'Behavioral Economics',
      'International Trade and Microeconomics'
    ]
  },
  'High School - Mathematics': { // Additional category for higher education
    'Integrated Math-1': [
      'Solving Linear Equations and Inequalities',
      'Functions and Their Representations',
      'Linear Functions and Graphs',
      'Systems of Linear Equations and Inequalities',
      'Properties of Exponents',
      'Introduction to Sequences',
      'Introduction to Statistics and Data Analysis',
      'Measures of Central Tendency and Variability',
      'Analyzing Linear Models',
      'Introduction to Geometric Transformations',
      'Congruence and Similarity',
      'Introduction to Geometry Proofs',
      'Modeling with Mathematics',
      'Introduction to Probability',
      'Solving Absolute Value Equations and Inequalities',
      'Introduction to Quadratic Functions',
      'Growth and Decay in Context',
      'Exploring Data: Frequency Tables and Histograms',
      'Compound Interest',
      'Linear Piecewise Functions',
      'Geometric Constructions',
      'Introduction to Scatter Plots and Correlation',
      'Problem Solving and Mathematical Practices',
      'Properties of Real Numbers',
      'Simplifying Algebraic Expressions'
    ],
    'Integrated Math-2': [
      'Quadratic Functions and Equations',
      'Complex Numbers',
      'Polynomials and Factoring',
      'Rational Exponents and Radicals',
      'Exponential Functions and Logarithms',
      'Transformations of Functions',
      'Comparing Linear, Exponential, and Quadratic Models',
      'Rational Functions',
      'Conic Sections (Circles, Parabolas)',
      'Probability Rules and Models',
      'Conditional Probability and Independence',
      'Data Analysis and Statistical Reasoning',
      'Similarity and Dilations',
      'Trigonometric Ratios and Functions',
      'Right Triangle Trigonometry',
      'Transformations and Symmetry',
      'Proof and Geometric Reasoning',
      'Geometric Constructions and Loci',
      'Congruence and Similarity in the Coordinate Plane',
      'Circles: Properties and Equations',
      'Modeling with Functions',
      'Arithmetic and Geometric Sequences',
      'Inverse Functions',
      'Systems of Equations and Inequalities (Linear and Quadratic)',
      'Modeling Periodic Phenomena'
    ],
    'Integrated Math-3': [
      'Polynomial Functions and Equations',
      'Rational Functions and Asymptotes',
      'Exponential and Logarithmic Functions',
      'Trigonometric Functions and Graphs',
      'Trigonometric Identities and Equations',
      'Sequences and Series',
      'Functions and Their Inverses',
      'Conic Sections (Ellipses and Hyperbolas)',
      'Vectors and Parametric Equations',
      'Complex Numbers and De Moivre\'s Theorem',
      'Introduction to Limits and Continuity',
      'Introduction to Derivatives',
      'Probability Distributions and Expected Value',
      'Statistical Inferences and Conclusions',
      'Modeling with Functions',
      'Piecewise and Step Functions',
      'Transformations of Functions',
      'Solving Nonlinear Systems of Equations',
      'Polar Coordinates and Graphs',
      'Matrices and Determinants',
      'Solving Equations with Rational Exponents',
      'Graphical Analysis of Functions',
      'Data Analysis and Statistical Reasoning',
      'Introduction to Series and Sigma Notation',
      'Modeling Periodic Phenomena',
      'Law of Sines and Cosines'
    ],
    'Algebra Basics': [
      'Order of Operations (PEMDAS)',
      'Basic Properties of Numbers (Associative, Commutative, Distributive)',
      'Simplifying Algebraic Expressions',
      'Solving One-Step Equations',
      'Solving Multi-Step Equations',
      'Understanding Variables and Constants',
      'Operations with Integers',
      'Fractions and Decimals',
      'Ratios and Proportions',
      'Percentages',
      'Introduction to Inequalities',
      'Graphing on the Number Line',
      'Evaluating Expressions',
      'Absolute Value Basics',
      'Basic Coordinate Plane Concepts',
      'Introduction to Functions',
      'Simple Word Problems',
      'Basic Exponent Rules',
      'Introduction to Polynomials',
      'Factoring Simple Expressions',
      'Units and Unit Conversions',
      'Introduction to Slope and Rate of Change',
      'Basic Mathematical Modeling',
      'Translating Words to Equations',
      'Basic Data Interpretation (Mean, Median, Mode)'
    ],
    'Trigonometry': [
      'Trigonometric Ratios (Sine, Cosine, Tangent)',
      'Unit Circle',
      'Graphs of Trigonometric Functions',
      'Trigonometric Identities',
      'Inverse Trigonometric Functions',
      'Solving Trigonometric Equations',
      'Law of Sines and Law of Cosines',
      'Radian and Degree Measure',
      'Trigonometric Applications (Solving Triangles)',
      'Amplitude, Period, and Phase Shift',
      'Trigonometric Form of Complex Numbers',
      'Polar Coordinates and Graphs',
      'Vectors and Their Applications',
      'Sum and Difference Formulas',
      'Double Angle and Half Angle Identities',
      'Product-to-Sum and Sum-to-Product Identities',
      'Trigonometric Equations and Inequalities',
      'Angular Velocity and Linear Velocity',
      'Harmonic Motion',
      'Circular Functions and Their Applications',
      'De Moivre\'s Theorem',
      'Graphical Transformations of Trigonometric Functions',
      'Trigonometric Modeling',
      'Trigonometric Limits and Precalculus Concepts',
      'Solving Right and Oblique Triangles',
      'Polar Form of Complex Numbers'
    ],
    'Statistics And Probability': [
      'Descriptive Statistics (Measures of Center and Spread)',
      'Probability Foundations and Axioms',
      'Random Variables (Discrete and Continuous)',
      'Probability Distributions (Binomial, Poisson, Normal)',
      'Combinatorics (Permutations and Combinations)',
      'Conditional Probability and Bayes\' Theorem',
      'Expected Value and Variance',
      'Law of Large Numbers and Central Limit Theorem',
      'Hypothesis Testing and p-Values',
      'Confidence Intervals and Estimation',
      'Correlation and Regression Analysis',
      'Analysis of Variance (ANOVA)',
      'Chi-Square Tests (Goodness-of-Fit, Independence)',
      'Sampling Methods and Distributions',
      'Standard Normal Distribution and Z-Scores',
      't-Distributions and Small Sample Inference',
      'F-Distributions',
      'Nonparametric Tests',
      'Probability Generating Functions',
      'Markov Chains (Introduction)',
      'Monte Carlo Methods (Introduction)',
      'Empirical Rule and Chebyshev\'s Theorem',
      'Transformations of Random Variables',
      'Statistical Inference and Decision Making',
      'Power and Sample Size Calculations',
      'Bootstrap Methods',
      'Time Series Analysis (Basic Concepts)'
    ],
    'College Algebra': [
      'Functions and Their Properties',
      'Polynomial and Rational Functions',
      'Exponential and Logarithmic Functions',
      'Systems of Equations (Linear and Nonlinear)',
      'Matrices and Determinants',
      'Complex Numbers',
      'Sequences and Series',
      'Conic Sections',
      'Inequalities and Absolute Value',
      'Binomial Theorem',
      'Partial Fractions',
      'Graphing Techniques and Transformations',
      'Inverse Functions',
      'Introduction to Limits',
      'Operations on Functions',
      'Higher Degree Polynomial Equations',
      'Rational Root Theorem',
      'Synthetic Division',
      'Exponential Growth and Decay Models',
      'Piecewise Functions',
      'Parametric Equations',
      'Introduction to Vectors',
      'Introduction to Trigonometric Functions',
      'Modeling with Algebraic Functions',
      'Applications and Problem Solving',
      'Polynomial Division',
      'Systems of Inequalities'
    ],
    'Multivariable Calculus': [
      'Vectors and Vector-Valued Functions',
      'Multivariable Functions and Their Graphs',
      'Partial Derivatives and Gradient Vectors',
      'Multiple Integration (Double and Triple Integrals)',
      'Line Integrals and Surface Integrals',
      'Vector Fields and Flows',
      'Divergence and Curl',
      'Green\'s Theorem, Stokes\' Theorem, and Divergence Theorem',
      'Parametric Surfaces and Surface Area',
      'Coordinate Systems (Cartesian, Cylindrical, Spherical)',
      'Lagrange Multipliers and Optimization',
      'Chain Rule for Multivariable Functions',
      'Directional Derivatives and Tangent Planes',
      'Jacobians and Change of Variables',
      'Applications of Multiple Integrals (Volume, Mass, Center of Mass)',
      'Conservative Fields and Potential Functions',
      'Arc Length and Curvature',
      'Improper Integrals in Multiple Dimensions',
      'Differential Equations in Several Variables',
      'Taylor Series for Multivariable Functions',
      'Gradient, Divergence, and Laplacian Operators',
      'Differential Forms and Integration',
      'Applications to Physics and Engineering',
      'Vector Calculus Identities',
      'Triple Integrals in Various Coordinate Systems',
      'Surface Integrals and Flux',
      'Parametric Equations for Surfaces'
    ],
    'Differential Equations': [
      'First-Order Differential Equations',
      'Separable Equations',
      'Linear Differential Equations',
      'Exact Equations and Integrating Factors',
      'Second-Order Linear Differential Equations',
      'Homogeneous Equations with Constant Coefficients',
      'Nonhomogeneous Equations and Method of Undetermined Coefficients',
      'Variation of Parameters',
      'Laplace Transforms',
      'Series Solutions of Differential Equations',
      'Systems of Differential Equations',
      'Phase Plane Analysis',
      'Stability and Autonomous Equations',
      'Fourier Series and Boundary Value Problems',
      'Partial Differential Equations',
      'Eigenvalues and Eigenvectors Applications',
      'Modeling with Differential Equations',
      'Numerical Methods (Euler\'s Method, Runge-Kutta Methods)',
      'Linearization and Stability of Equilibria',
      'Sturm-Liouville Theory',
      'Green\'s Functions',
      'Applications to Mechanics and Circuit Theory',
      'Direction Fields and Qualitative Methods',
      'Existence and Uniqueness Theorems',
      'Bifurcation Theory (Introduction)',
      'Applications to Population Dynamics',
      'Chaos and Nonlinear Systems (Introduction)'
    ],
    'Linear Algebra': [
      'Systems of Linear Equations',
      'Matrices and Matrix Operations',
      'Determinants and Their Properties',
      'Vector Spaces and Subspaces',
      'Basis and Dimension',
      'Linear Independence and Dependence',
      'Linear Transformations and Their Matrices',
      'Eigenvalues and Eigenvectors',
      'Diagonalization of Matrices',
      'Inner Product Spaces',
      'Orthogonality and Orthogonal Projections',
      'Gram-Schmidt Process',
      'Least Squares Solutions',
      'Quadratic Forms and Conic Sections',
      'Spectral Theorem',
      'Rank and Nullity Theorems',
      'Change of Basis and Similarity',
      'Applications to Differential Equations',
      'Singular Value Decomposition (SVD)',
      'Polar Decomposition',
      'Matrix Factorizations (LU, QR)',
      'Cayley-Hamilton Theorem',
      'Applications to Computer Graphics',
      'Complex Vector Spaces',
      'Dual Spaces and Transposes',
      'Bilinear and Quadratic Forms',
      'Norms and Metrics',
      'Positive Definite Matrices'
    ]
  },
  'High School - Algebra I': {
    'Mathematics - Linear functions, equations, and inequalities': [
      'Domain and range: Domain and range of relations',
      'Domain and range: Domain and range of linear functions: graphs',
      'Domain and range: Domain and range of linear functions: word problems',
      'Writing linear equations in various forms: Slope-intercept form: write an equation',
      'Writing linear equations in various forms: Write linear equations in standard form',
      'Writing linear equations in various forms: Point-slope form: write an equation',
      'Writing linear equations in various forms: Write equations for lines of best fit',
      'Writing linear equations from representations: Slope-intercept form: write an equation from a graph',
      'Writing linear equations from representations: Slope-intercept form: write an equation from a table',
      'Writing linear equations from representations: Point-slope form: write an equation from a graph',
      'Writing linear equations from representations: Write a linear function: word problems',
      'Direct variation equations: Find the constant of variation',
      'Direct variation equations: Write direct variation equations',
      'Direct variation equations: Write and solve direct variation equations',
      'Parallel lines: Slopes of parallel and perpendicular lines',
      'Parallel lines: Write an equation for a parallel or perpendicular line',
      'Perpendicular lines: Slopes of parallel and perpendicular lines',
      'Perpendicular lines: Write an equation for a parallel or perpendicular line',
      'Horizontal and vertical lines: Equations of horizontal and vertical lines',
      'Writing linear inequalities: Write a linear inequality from a graph',
      'Writing linear inequalities: Write two-variable inequalities: word problems',
      'Writing systems of equations: Solve a system of equations by graphing: word problems',
      'Writing systems of equations: Solve a system of equations using substitution: word problems',
      'Writing systems of equations: Solve a system of equations using elimination: word problems',
      'Writing systems of equations: Solve a system of equations using any method: word problems',
      'Writing systems of equations: Write a system of equations given a graph'
    ],
    'Mathematics - Quadratic functions and equations': [
      'Domain and range of quadratics: Domain and range of quadratic functions: graphs',
      'Domain and range of quadratics: Domain and range of quadratic functions: equations',
      'Writing quadratic functions in vertex form: Write a quadratic function from its vertex and another point',
      'Writing quadratic functions from solutions: Match quadratic functions and graphs',
      'Writing quadratic functions from solutions: Write a quadratic function from its x-intercepts and another point',
      'Graphing quadratic functions: Characteristics of quadratic functions: graphs',
      'Graphing quadratic functions: Characteristics of quadratic functions: equations',
      'Graphing quadratic functions: Graph quadratic functions in vertex form',
      'Graphing quadratic functions: Graph quadratic functions in standard form',
      'Graphing quadratic functions: Match quadratic functions and graphs',
      'Linear factors and zeros: Solve a quadratic equation using the zero product property',
      'Transformations of quadratics: Transformations of quadratic functions',
      'Solving quadratic equations: Solve a quadratic equation using square roots',
      'Solving quadratic equations: Solve a quadratic equation using the zero product property',
      'Solving quadratic equations: Solve a quadratic equation by factoring',
      'Solving quadratic equations: Complete the square',
      'Solving quadratic equations: Solve a quadratic equation by completing the square',
      'Solving quadratic equations: Solve a quadratic equation using the quadratic formula',
      'Solving quadratic equations: Solve quadratic equations: word problems',
      'Solving quadratic equations: Using the discriminant'
    ],
    'Mathematics - Exponential functions and equations': [
      'Domain and range of exponentials: Domain and range of exponential functions: graphs',
      'Domain and range of exponentials: Domain and range of exponential functions: equations',
      'Values of a and b in exponentials: Exponential growth and decay: word problems',
      'Writing exponential functions: Write exponential functions: word problems',
      'Writing exponential functions: Exponential growth and decay: word problems',
      'Graphing exponential functions: Graph exponential functions',
      'Graphing exponential functions: Match exponential functions and graphs I',
      'Graphing exponential functions: Match exponential functions and graphs II',
      'Exponential regression: Exponential regression'
    ],
    'Mathematics - Number and algebraic methods': [
      'Polynomial operations: Add and subtract polynomials using algebra tiles',
      'Polynomial operations: Add polynomials to find perimeter',
      'Polynomial operations: Add and subtract polynomials',
      'Polynomial operations: Multiply a polynomial by a monomial',
      'Polynomial operations: Multiply two binomials using algebra tiles',
      'Polynomial operations: Multiply two binomials',
      'Polynomial operations: Multiply two binomials: special cases',
      'Polynomial operations: Multiply polynomials using area models',
      'Polynomial operations: Multiply polynomials',
      'Polynomial operations: Divide polynomials by monomials',
      'Polynomial operations: Divide polynomials using long division',
      'Polynomial expressions: Distributive property',
      'Factoring trinomials: Factor quadratics using algebra tiles',
      'Factoring trinomials: Factor quadratics with leading coefficient 1',
      'Factoring trinomials: Factor quadratics with other leading coefficients',
      'Factoring trinomials: Factor quadratics: special cases',
      'Difference of squares: Factor quadratics: special cases',
      'Simplifying radicals: Simplify radical expressions',
      'Simplifying radicals: Add and subtract radical expressions',
      'Simplifying radicals: Simplify radical expressions using the distributive property',
      'Simplifying radicals: Simplify radical expressions using conjugates',
      'Laws of exponents: Powers with integer bases',
      'Laws of exponents: Powers with decimal and fractional bases',
      'Laws of exponents: Negative exponents',
      'Laws of exponents: Multiplication rule for exponents',
      'Laws of exponents: Division rule for exponents',
      'Laws of exponents: Power rule for exponents',
      'Laws of exponents: Simplify exponential expressions using the multiplication rule',
      'Laws of exponents: Simplify exponential expressions using the division rule',
      'Laws of exponents: Simplify exponential expressions using the multiplication and division rules',
      'Laws of exponents: Simplify exponential expressions using the power rule',
      'Laws of exponents: Evaluate expressions using exponent rules',
      'Laws of exponents: Identify equivalent exponential expressions I',
      'Laws of exponents: Identify equivalent exponential expressions II',
      'Laws of exponents: Evaluate integers raised to positive rational exponents',
      'Laws of exponents: Evaluate integers raised to rational exponents',
      'Laws of exponents: Multiplication with rational exponents',
      'Laws of exponents: Division with rational exponents',
      'Laws of exponents: Power rule with rational exponents',
      'Laws of exponents: Simplify expressions involving rational exponents',
      'Functions and relations: Identify functions',
      'Functions and relations: Identify functions: vertical line test',
      'Function notation: Evaluate a function',
      'Function notation: Complete a function table from an equation',
      'Function notation: Evaluate piecewise-defined functions',
      'Sequences: Evaluate variable expressions for number sequences',
      'Sequences: Evaluate recursive formulas for sequences',
      'Sequences: Write a formula for a recursive sequence',
      'Sequences: Write variable expressions for arithmetic sequences',
      'Sequences: Write variable expressions for geometric sequences',
      'Literal equations: Rearrange multi-variable equations',
      'Literal equations: Linear equations: solve for y'
    ]
  },
  'High School - Algebra II': {
    'Mathematics - Attributes of functions and their inverses': [
      'Graph the functions f(x)=√x, f(x)=1/x, f(x)=x³, f(x)=³√x, f(x)=b to the x power, f(x)=|x|, and f(x)=logb (x) where b is 2, 10, and e, and, when applicable, analyze the key attributes such as domain, range, intercepts, symmetries, asymptotic behavior, and maximum and minimum given an interval;',
      'Domain and range of absolute value functions: graphs',
      'Domain and range of absolute value functions: equations',
      'Domain and range of radical functions',
      'Rational functions: asymptotes and excluded values',
      'Match exponential functions and graphs',
      'Domain and range of logarithmic functions',
      'Graph logarithmic functions',
      'Graph and write the inverse of a function using notation such as f⁻¹(x);',
      'Find values of inverse functions from graphs',
      'Find inverse functions and relations',
      'Describe and analyze the relationship between a function and its inverse (quadratic and square root, logarithmic and exponential), including the restriction(s) on domain, which will restrict its range;',
      'Find values of inverse functions from tables',
      'Find values of inverse functions from graphs',
      'Find inverse functions and relations',
      'Use the composition of two functions, including the necessary restrictions on the domain, to determine if the functions are inverses of each other.',
      'Composition of linear functions: find a value',
      'Composition of linear functions: find an equation',
      'Composition of linear and quadratic functions: find a value',
      'Composition of linear and quadratic functions: find an equation',
      'Identify inverse functions'
    ],
    'Mathematics - Systems of equations and inequalities': [
      'Solve a system of equations by graphing: word problems',
      'Solve a system of equations using substitution: word problems',
      'Solve a system of equations using elimination: word problems',
      'Solve a system of equations using any method: word problems',
      'Solve a system of equations using augmented matrices: word problems',
      'Solve a system of three linear equations in three variables using substitution',
      'Solve a system of three linear equations in three variables using elimination',
      'Solve, algebraically, systems of two equations in two variables consisting of a linear equation and a quadratic equation;',
      'Solve a system of linear and quadratic equations: parabolas',
      'Solve a system of linear and quadratic equations by graphing: parabolas',
      'Formulate systems of at least two linear inequalities in two variables;',
      'Solve systems of two or more linear inequalities in two variables;',
      'Solve systems of linear inequalities by graphing',
      'Find the vertices of a solution set for a system of linear inequalities',
      'Linear programming',
      'Determine possible solutions in the solution set of systems of two or more linear inequalities in two variables.',
      'Is (x, y) a solution to the system of inequalities?'
    ],
    'Mathematics - Quadratic and square root functions, equations, and inequalities': [
      'Write the quadratic function given three specified points in the plane;',
      'Write a quadratic function from three points',
      'Write a quadratic function from its vertex and another point',
      'Write a quadratic function from its x-intercepts and another point',
      'Write equations of parabolas in vertex form from graphs',
      'Write equations of parabolas in vertex form using properties',
      'Graph square root functions',
      'Convert equations of parabolas from general to vertex form',
      'Find properties of a parabola from equations in general form',
      'Formulate quadratic and square root equations using technology given a table of data;',
      'Solve quadratic and square root equations;',
      'Solve a quadratic equation using square roots',
      'Solve a quadratic equation using the zero product property',
      'Solve a quadratic equation by factoring',
      'Solve a quadratic equation by completing the square',
      'Solve a quadratic equation using the quadratic formula',
      'Solve quadratic equations: word problems',
      'Solve radical equations',
      'Identify extraneous solutions of square root equations;',
      'Solve quadratic inequalities.',
      'Graph solutions to quadratic inequalities',
      'Solve quadratic inequalities'
    ],
    'Mathematics - Exponential and logarithmic functions and equations': [
      'Determine the effects on the key attributes on the graphs of f(x) = b to the x power and f(x) = logb (x) where b is 2, 10, and e when f(x) is replaced by af(x), f(x) + d, and f(x - c) for specific positive and negative real values of a, c, and d;',
      'Match exponential functions and graphs',
      'Formulate exponential and logarithmic equations that model real-world situations, including exponential relationships written in recursive notation;',
      'Write exponential functions: word problems',
      'Exponential growth and decay: word problems',
      'Compound interest: word problems',
      'Continuously compounded interest: word problems',
      'Rewrite exponential equations as their corresponding logarithmic equations and logarithmic equations as their corresponding exponential equations;',
      'Convert between exponential and logarithmic form: rational bases',
      'Convert between natural exponential and logarithmic form',
      'Convert between exponential and logarithmic form: all bases',
      'Solve exponential equations by rewriting the base',
      'Solve exponential equations using common logarithms',
      'Solve exponential equations using natural logarithms',
      'Solve logarithmic equations I',
      'Determine the reasonableness of a solution to a logarithmic equation.'
    ],
    'Mathematics - Cubic, cube root, absolute value and rational functions, equations, and inequalities': [
      'Analyze the effect on the graphs of f(x) = x³ and f(x) = ³√x when f(x) is replaced by af(x), f(bx), f(x - c), and f(x) + d for specific positive and negative real values of a, b, c, and d;',
      'Graph cube root functions',
      'Solve cube root equations that have real roots;',
      'Analyze the effect on the graphs of f(x) = |x| when f(x) is replaced by af(x), f(bx), f(x-c), and f(x) + d for specific positive and negative real values of a, b, c, and d;',
      'Graph an absolute value function',
      'Transformations of absolute value functions',
      'Formulate absolute value linear equations;',
      'Write absolute value equations from graphs',
      'Solve absolute value linear equations;',
      'Solve absolute value equations',
      'Graph solutions to absolute value equations',
      'Solve absolute value linear inequalities;',
      'Solve absolute value inequalities',
      'Graph solutions to absolute value inequalities',
      'Analyze the effect on the graphs of f(x) = 1/x when f(x) is replaced by af(x), f(bx), f(x - c), and f(x) + d for specific positive and negative real values of a, b, c, and d;',
      'Formulate rational equations that model real-world situations;',
      'Solve rational equations that have real solutions;',
      'Solve rational equations',
      'Determine the reasonableness of a solution to a rational equation;',
      'Solve rational equations',
      'Determine the asymptotic restrictions on the domain of a rational function and represent domain and range using interval notation, inequalities, and set notation;',
      'Rational functions: asymptotes and excluded values',
      'Formulate and solve equations involving inverse variation.',
      'Write and solve inverse variation equations'
    ],
    'Mathematics - Number and algebraic methods': [
      'Add, subtract, and multiply complex numbers;',
      'Add and subtract complex numbers',
      'Complex conjugates',
      'Multiply complex numbers',
      'Add, subtract, and multiply polynomials;',
      'Add and subtract polynomials',
      'Multiply polynomials',
      'Determine the quotient of a polynomial of degree three and of degree four when divided by a polynomial of degree one and of degree two;',
      'Divide polynomials using long division',
      'Divide polynomials using synthetic division',
      'Determine the linear factors of a polynomial function of degree three and of degree four using algebraic methods;',
      'Factor polynomials',
      'Determine linear and quadratic factors of a polynomial expression of degree three and of degree four, including factoring the sum and difference of two cubes and factoring by grouping;',
      'Factor using a quadratic pattern',
      'Factor by grouping',
      'Factor sums and differences of cubes',
      'Factor polynomials',
      'Determine the sum, difference, product, and quotient of rational expressions with integral exponents of degree one and of degree two;',
      'Multiply and divide rational expressions',
      'Add and subtract rational expressions',
      'Rewrite radical expressions that contain variables to equivalent forms;',
      'Simplify radical expressions with variables I',
      'Simplify radical expressions with variables II',
      'Convert between rational exponents and radicals',
      'Solve equations involving rational exponents;',
      'Solve equations with rational exponents',
      'Write the domain and range of a function in interval notation, inequalities, and set notation.',
      'Domain and range',
      'Domain and range of quadratic functions: graphs',
      'Domain and range of polynomials',
      'Domain and range of radical functions',
      'Domain and range of logarithmic functions'
    ],
    'Mathematics - Data': [
      'Analyze data to select the appropriate model from among linear, quadratic, and exponential models;',
      'Identify linear, quadratic, and exponential functions from graphs',
      'Identify linear, quadratic, and exponential functions from tables',
      'Use regression methods available through technology to write a linear function, a quadratic function, and an exponential function from a given set of data;',
      'Find the equation of a regression line',
      'Interpret regression lines',
      'Analyze a regression line of a data set',
      'Predict and make decisions and critical judgments from a given set of data using linear, quadratic, and exponential models.',
      'Interpret regression lines',
      'Analyze a regression line of a data set'
    ]
  },
  'High School - Geometry': {
    'Geometry - Coordinate and transformational geometry': [
      'Midpoints - determine the coordinates of a point that is a given fractional distance less than one from one end of a line segment to the other in one- and two-dimensional coordinate systems, including finding the midpoint;',
      'Midpoints - Midpoint formula: find the midpoint',
      'Midpoints - Partition a line segment in a given ratio',
      'Slope, Distance, Midpoint - derive and use the distance, slope, and midpoint formulas to verify geometric relationships, including congruence of segments and parallelism or perpendicularity of pairs of lines;',
      'Slope, Distance, Midpoint - Midpoint formula: find the midpoint',
      'Slope, Distance, Midpoint - Distance formula',
      'Slope, Distance, Midpoint - Slopes of lines',
      'Slope, Distance, Midpoint - Slopes of parallel and perpendicular lines',
      'Slope, Distance, Midpoint - Classify shapes on the coordinate plane: justify your answer',
      'Slope, Distance, Midpoint - Determine if a point lies on a circle',
      'Equations of lines - determine an equation of a line parallel or perpendicular to a given line that passes through a given point.',
      'Equations of lines - Equations of parallel and perpendicular lines',
      'Equations of lines - Checkpoint: Partition a line segment',
      'Equations of lines - Checkpoint: Coordinate proofs',
      'Equations of lines - Checkpoint: Parallel and perpendicular lines',
      'Transformations - describe and perform transformations of figures in a plane using coordinate notation;',
      'Transformations - Translations: graph the image',
      'Transformations - Translations: find the coordinates',
      'Transformations - Translations: write the rule',
      'Transformations - Reflections: graph the image',
      'Transformations - Reflections: find the coordinates',
      'Transformations - Rotations: graph the image',
      'Transformations - Rotations: find the coordinates',
      'Transformations - Reflections and rotations: write the rule',
      'Transformations - Congruence transformations: mixed review',
      'Compositions - determine the image or pre-image of a given two-dimensional figure under a composition of rigid transformations, a composition of non-rigid transformations, and a composition of both, including dilations where the center can be any point in the plane;',
      'Compositions - Sequences of congruence transformations: graph the image',
      'Transformations sequence - identify the sequence of transformations that will carry a given pre-image onto an image on and off the coordinate plane;',
      'Transformations sequence - Classify congruence transformations',
      'Transformations sequence - Sequences of congruence transformations: choose the sequence',
      'Transformations sequence - Sequences of congruence transformations: find the rules',
      'Symmetry - identify and distinguish between reflectional and rotational symmetry in a plane figure.',
      'Symmetry - Line symmetry',
      'Symmetry - Rotational symmetry',
      'Symmetry - Checkpoint: Transformations of geometric figures'
    ],
    'Geometry - Logical argument and constructions': [
      'Statements - distinguish between undefined terms, definitions, postulates, conjectures, and theorems;',
      'Statements - Lines, line segments, and rays',
      'Conditional statements - identify and determine the validity of the converse, inverse, and contrapositive of a conditional statement and recognize the connection between a biconditional statement and a true conditional statement with a true converse;',
      'Conditional statements - Identify hypotheses and conclusions',
      'Conditional statements - Conditionals',
      'Conditional statements - Converses, inverses, and contrapositives',
      'Conditional statements - Biconditionals',
      'Conditional statements - Truth tables',
      'Conditional statements - Truth values',
      'Counterexamples - verify that a conjecture is false using a counterexample;',
      'Counterexamples - Counterexamples',
      'Euclidean and spherical geometries - compare geometric relationships between Euclidean and spherical geometries, including parallel lines and the sum of the angles in a triangle.',
      'Conjectures - investigate patterns to make conjectures about geometric relationships, including angles formed by parallel lines cut by a transversal, criteria required for triangle congruence, special segments of triangles, diagonals of quadrilaterals, interior and exterior angles of polygons, and special segments and angles of circles choosing from a variety of tools;',
      'Conjectures - Interior angles of polygons',
      'Constructions - construct congruent segments, congruent angles, a segment bisector, an angle bisector, perpendicular lines, the perpendicular bisector of a line segment, and a line parallel to a given line through a point not on a line using a compass and a straightedge;',
      'Constructions - Construct a congruent segment',
      'Constructions - Construct the midpoint or perpendicular bisector of a segment',
      'Constructions - Construct an angle bisector',
      'Constructions - Construct a congruent angle',
      'Constructions - Construct a perpendicular line',
      'Constructions - Construct parallel lines',
      'Constructions - Construct an equilateral triangle or regular hexagon',
      'Constructions - Construct a square',
      'Constructions - Construct the circumcenter or incenter of a triangle',
      'Constructions - Construct the centroid or orthocenter of a triangle',
      'Geometric relationships - use the constructions of congruent segments, congruent angles, angle bisectors, and perpendicular bisectors to make conjectures about geometric relationships;',
      'Geometric relationships - Congruent line segments',
      'Geometric relationships - Perpendicular Bisector Theorem',
      'Geometric relationships - Angle bisectors',
      'Triangle Inequality - verify the Triangle Inequality theorem using constructions and apply the theorem to solve problems.',
      'Triangle Inequality - Triangle Inequality Theorem',
      'Triangle Inequality - Checkpoint: Geometric constructions'
    ],
    'Geometry - Proof and congruence': [
      'Intersection of lines and line segments - verify theorems about angles formed by the intersection of lines and line segments, including vertical angles, and angles formed by parallel lines cut by a transversal and prove equidistance between the endpoints of a segment and points on its perpendicular bisector and apply these relationships to solve problems;',
      'Intersection of lines and line segments - Perpendicular Bisector Theorem',
      'Intersection of lines and line segments - Find measures of complementary, supplementary, vertical, and adjacent angles',
      'Intersection of lines and line segments - Angle diagrams: solve for the variable',
      'Intersection of lines and line segments - Proofs involving angles',
      'Intersection of lines and line segments - Transversals of parallel lines: find angle measures',
      'Intersection of lines and line segments - Transversals of parallel lines: solve for x',
      'Intersection of lines and line segments - Proofs involving parallel lines I',
      'Intersection of lines and line segments - Proofs involving parallel lines II',
      'Congruent triangles - prove two triangles are congruent by applying the Side-Angle-Side, Angle-Side-Angle, Side-Side-Side, Angle-Angle-Side, and Hypotenuse-Leg congruence conditions;',
      'Congruent triangles - SSS and SAS Theorems',
      'Congruent triangles - Proving triangles congruent by SSS and SAS',
      'Congruent triangles - ASA and AAS Theorems',
      'Congruent triangles - Proving triangles congruent by ASA and AAS',
      'Congruent triangles - SSS, SAS, ASA, and AAS Theorems',
      'Congruent triangles - SSS Theorem in the coordinate plane',
      'Congruent triangles - Proving triangles congruent by SSS, SAS, ASA, and AAS',
      'Congruent triangles - Hypotenuse-Leg Theorem',
      'Congruent figures - apply the definition of congruence, in terms of rigid transformations, to identify congruent figures and their corresponding sides and angles;',
      'Congruent figures - Congruence statements and corresponding parts',
      'Congruent figures - Identify congruent figures',
      'Congruent figures - Determine if two figures are congruent: justify your answer',
      'Congruent figures - Prove triangles are congruent using rigid motions',
      'Congruent figures - Proofs involving corresponding parts of congruent triangles',
      'Relationships in triangles - verify theorems about the relationships in triangles, including proof of the Pythagorean Theorem, the sum of interior angles, base angles of isosceles triangles, midsegments, and medians, and apply these relationships to solve problems;',
      'Relationships in triangles - Triangle Angle-Sum Theorem',
      'Relationships in triangles - Congruency in isosceles and equilateral triangles',
      'Relationships in triangles - Proofs involving isosceles triangles',
      'Relationships in triangles - Midsegments of triangles',
      'Relationships in triangles - Identify medians, altitudes, angle bisectors, and perpendicular bisectors',
      'Relationships in triangles - Find the centroid of a triangle',
      'Relationships in triangles - Proofs involving triangles I',
      'Relationships in triangles - Pythagorean theorem',
      'Relationships in triangles - Prove the Pythagorean theorem',
      'Relationships in triangles - Converse of the Pythagorean theorem',
      'Quadrilaterals - prove a quadrilateral is a parallelogram, rectangle, square, or rhombus using opposite sides, opposite angles, or diagonals and apply these relationships to solve problems;',
      'Quadrilaterals - Classify quadrilaterals I',
      'Quadrilaterals - Classify quadrilaterals II',
      'Quadrilaterals - Find missing angles in quadrilaterals',
      'Quadrilaterals - Properties of parallelograms',
      'Quadrilaterals - Proving a quadrilateral is a parallelogram',
      'Quadrilaterals - Properties of rhombuses',
      'Quadrilaterals - Properties of squares and rectangles',
      'Quadrilaterals - Classify quadrilaterals on the coordinate plane: justify your answer',
      'Quadrilaterals - Checkpoint: Line and angle theorems',
      'Quadrilaterals - Checkpoint: Parallelogram theorems',
      'Quadrilaterals - Checkpoint: Rigid motion and congruence',
      'Quadrilaterals - Checkpoint: Triangle theorems'
    ],
    'Geometry - Similarity, proof, and trigonometry': [
      'Similar figures - apply the definition of similarity in terms of a dilation to identify similar figures and their proportional sides and the congruent corresponding angles;',
      'Similar figures - Ratios in similar figures',
      'Similar figures - Similarity statements',
      'Similar figures - Identify similar figures',
      'Similar figures - Side lengths and angle measures in similar figures',
      'Similar figures - Similar triangles and similarity transformations',
      'Similar figures - Similarity of circles',
      'Similar figures - Similar solids: find the missing length',
      'Similar triangles - apply the Angle-Angle criterion to verify similar triangles and apply the proportionality of the corresponding sides to solve problems.',
      'Similar triangles - Similar triangles and indirect measurement',
      'Similar triangles - Checkpoint: Similarity transformations',
      'Theorems about similar triangles - prove theorems about similar triangles, including the Triangle Proportionality theorem, and apply these theorems to solve problems;',
      'Theorems about similar triangles - Similarity rules for triangles',
      'Theorems about similar triangles - Triangle Proportionality Theorem',
      'Theorems about similar triangles - Prove similarity statements',
      'Theorems about similar triangles - Prove proportions or angle congruences using similarity',
      'Theorems about similar triangles - Proofs involving similarity in right triangles',
      'Theorems about similar triangles - Proofs involving triangles II',
      'Altitudes in right triangles - identify and apply the relationships that exist when an altitude is drawn to the hypotenuse of a right triangle, including the geometric mean, to solve problems.',
      'Altitudes in right triangles - Similarity and altitudes in right triangles',
      'Altitudes in right triangles - Proofs involving similarity in right triangles',
      'Altitudes in right triangles - Checkpoint: Triangle similarity and congruence',
      'Trigonometric ratios - determine the lengths of sides and measures of angles in a right triangle by applying the trigonometric ratios sine, cosine, and tangent to solve problems;',
      'Trigonometric ratios - Trigonometric ratios: sin, cos, and tan',
      'Trigonometric ratios - Trigonometric ratios with radicals: sin, cos, and tan',
      'Trigonometric ratios - Trigonometric ratios: find a side length',
      'Trigonometric ratios - Trigonometric ratios: find an angle measure',
      'Special right triangles - apply the relationships in special right triangles 30°-60°-90° and 45°-45°-90° and the Pythagorean theorem, including Pythagorean triples, to solve problems.',
      'Special right triangles - Pythagorean theorem',
      'Special right triangles - Special right triangles',
      'Special right triangles - Find trigonometric functions of special angles',
      'Special right triangles - Checkpoint: Right triangle trigonometry'
    ],
    'Geometry - Two-dimensional and three-dimensional figures': [
      'Cross-sections and rotations - identify the shapes of two-dimensional cross-sections of prisms, pyramids, cylinders, cones, and spheres and identify three-dimensional objects generated by rotations of two-dimensional shapes;',
      'Cross-sections and rotations - Cross sections of three-dimensional figures',
      'Cross-sections and rotations - Solids of revolution',
      'Dimensional changes - determine and describe how changes in the linear dimensions of a shape affect its perimeter, area, surface area, or volume, including proportional and non-proportional dimensional change.',
      'Dimensional changes - Perimeters of similar figures',
      'Dimensional changes - Areas of similar figures',
      'Dimensional changes - Area and perimeter of similar figures',
      'Dimensional changes - Perimeter and area: changes in scale',
      'Dimensional changes - Surface area and volume of similar solids',
      'Dimensional changes - Surface area and volume: changes in scale',
      'Dimensional changes - Perimeter, area, and volume: changes in scale',
      'Dimensional changes - Checkpoint: Cross sections and solids of revolution',
      'Area of regular polygons - apply the formula for the area of regular polygons to solve problems using appropriate units of measure;',
      'Area of regular polygons - Area of regular polygons',
      'Area of composite figures - determine the area of composite two-dimensional figures comprised of a combination of triangles, parallelograms, trapezoids, kites, regular polygons, or sectors of circles to solve problems using appropriate units of measure;',
      'Area of composite figures - Area of compound figures',
      'Area of composite figures - Area between two shapes',
      'Surface area - apply the formulas for the total and lateral surface area of three-dimensional figures, including prisms, pyramids, cones, cylinders, spheres, and composite figures, to solve problems using appropriate units of measure;',
      'Surface area - Lateral area of prisms and cylinders',
      'Surface area - Lateral area of pyramids and cones',
      'Surface area - Surface area of prisms and cylinders',
      'Surface area - Surface area of pyramids and cones',
      'Surface area - Surface area of spheres',
      'Volume - apply the formulas for the volume of three-dimensional figures, including prisms, pyramids, cones, cylinders, spheres, and composite figures, to solve problems using appropriate units of measure.',
      'Volume - Volume of prisms and cylinders',
      'Volume - Volume of pyramids and cones',
      'Volume - Volume of spheres',
      'Volume - Volume of compound figures',
      'Volume - Checkpoint: Surface area',
      'Volume - Checkpoint: Volume'
    ],
    'Geometry - Circles': [
      'Theorems about circles - apply theorems about circles, including relationships among angles, radii, chords, tangents, and secants, to solve non-contextual problems;',
      'Theorems about circles - Arcs and chords',
      'Theorems about circles - Tangent lines',
      'Theorems about circles - Perimeter of polygons with an inscribed circle',
      'Theorems about circles - Inscribed angles',
      'Theorems about circles - Angles in inscribed right triangles',
      'Theorems about circles - Angles in inscribed quadrilaterals I',
      'Theorems about circles - Angles in inscribed quadrilaterals II',
      'Theorems about circles - Angles formed by chords, secants, and tangents',
      'Theorems about circles - Segments formed by chords, secants, and tangents',
      'Arc length - apply the proportional relationship between the measure of an arc length of a circle and the circumference of the circle to solve problems;',
      'Arc length - Understand arc length and sector area of a circle',
      'Arc length - Arc length',
      'Sector area - apply the proportional relationship between the measure of the area of a sector of a circle and the area of the circle to solve problems;',
      'Sector area - Understand arc length and sector area of a circle',
      'Sector area - Area of sectors',
      'Radian measure - describe radian measure of an angle as the ratio of the length of an arc intercepted by a central angle and the radius of the circle;',
      'Radian measure - Convert between radians and degrees',
      'Radian measure - Radians and arc length',
      'Equation of a circle - show that the equation of a circle with center at the origin and radius r is x² + y² = r² and determine the equation for the graph of a circle with radius r and center (h, k), (x - h)² + (y - k)² =r².',
      'Equation of a circle - Derive equations of circles using the Pythagorean theorem',
      'Equation of a circle - Write equations of circles in standard form from graphs',
      'Equation of a circle - Checkpoint: Angles and lines in circles',
      'Equation of a circle - Checkpoint: Arc length and area of sectors',
      'Equation of a circle - Checkpoint: Equations of circles'
    ],
    'Geometry - Probability': [
      'Permutations and combinations - develop strategies to use permutations and combinations to solve contextual problems;',
      'Permutations and combinations - Permutations',
      'Permutations and combinations - Permutation and combination notation',
      'Permutations and combinations - Find probabilities using combinations and permutations',
      'Area probability - determine probabilities based on area to solve contextual problems;',
      'Area probability - Geometric probability',
      'Independent events - identify whether two events are independent and compute the probability of the two events occurring together with or without replacement;',
      'Independent events - Identify independent and dependent events',
      'Independent events - Probability of independent and dependent events',
      'Independent events - Identify independent events',
      'Conditional probability - apply conditional probability in contextual problems;',
      'Conditional probability - Find conditional probabilities',
      'Conditional probability - Find conditional probabilities using two-way frequency tables',
      'Independence - apply independence in contextual problems.',
      'Independence - Identify independent events',
      'Independence - Independence and conditional probability',
      'Independence - Checkpoint: Probability'
    ]
  },
  'High School - Precalculus': {
    'Mathematics - Functions': [
      'A: use the composition of two functions to model and solve real-world problems',
      'A: Composition of functions',
      'B: demonstrate that function composition is not always commutative',
      'C: represent a given function as a composite function of two or more functions',
      'D: describe symmetry of graphs of even and odd functions',
      'D: Even and odd functions',
      'D: Symmetry and periodicity of trigonometric functions',
      'E: determine an inverse function, when it exists, for a given function over its domain or a subset of its domain and represent the inverse using multiple representations',
      'E: Identify inverse functions',
      'E: Find values of inverse functions from tables',
      'E: Find values of inverse functions from graphs',
      'E: Find inverse functions and relations',
      'E: Check whether two rational functions are inverses',
      'F: graph exponential, logarithmic, rational, polynomial, power, trigonometric, inverse trigonometric, and piecewise defined functions, including step functions',
      'F: Graph piecewise-defined functions',
      'F: Graph a quadratic function',
      'F: Match polynomials and graphs',
      'F: Graph logarithmic functions',
      'F: Graph sine functions',
      'F: Graph cosine functions',
      'F: Graph sine and cosine functions',
      'G: graph functions, including exponential, logarithmic, sine, cosine, rational, polynomial, and power functions and their transformations, including af(x), f(x) + d, f(x - c), f(bx) for specific values of a, b, c, and d, in mathematical and real-world problems',
      'G: Function transformation rules',
      'G: Translations of functions',
      'G: Reflections of functions',
      'G: Dilations of functions',
      'G: Transformations of functions',
      'G: Describe function transformations',
      'G: Match polynomials and graphs',
      'G: Graph translations of sine functions',
      'G: Graph translations of cosine functions',
      'G: Graph translations of sine and cosine functions',
      'H: graph arcsin x and arccos x and describe the limitations on the domain',
      'I: determine and analyze the key features of exponential, logarithmic, rational, polynomial, power, trigonometric, inverse trigonometric, and piecewise defined functions, including step functions such as domain, range, symmetry, relative maximum, relative minimum, zeros, asymptotes, and intervals over which the function is increasing or decreasing',
      'I: Find the roots of factored polynomials',
      'I: Match polynomials and graphs',
      'I: Domain and range of polynomials',
      'I: Rational functions: asymptotes and excluded values',
      'I: Linear and exponential functions over unit intervals',
      'I: Domain and range of logarithmic functions',
      'I: Find properties of sine functions',
      'I: Find properties of cosine functions',
      'I: Symmetry and periodicity of trigonometric functions',
      'J: analyze and describe end behavior of functions, including exponential, logarithmic, rational, polynomial, and power functions, using infinity notation to communicate this characteristic in mathematical and real-world problems',
      'K: analyze characteristics of rational functions and the behavior of the function around the asymptotes, including horizontal, vertical, and oblique asymptotes',
      'K: Rational functions: asymptotes and excluded values',
      'K: Find the limit at a vertical asymptote of a rational function I',
      'K: Find the limit at a vertical asymptote of a rational function II',
      'L: determine various types of discontinuities in the interval (-∞, ∞) as they relate to functions and explore the limitations of the graphing calculator as it relates to the behavior of the function around discontinuities',
      'L: Rational functions: asymptotes and excluded values',
      'L: Find and analyze points of discontinuity using graphs',
      'M: describe the left-sided behavior and the right-sided behavior of the graph of a function around discontinuities',
      'N: analyze situations modeled by functions, including exponential, logarithmic, rational, polynomial, and power functions, to solve real-world problems',
      'N: Exponential growth and decay: word problems',
      'N: Compound interest: word problems',
      'N: Exponential regression',
      'O: develop and use a sinusoidal function that models a situation in mathematical and real-world problems',
      'O: Write equations of sine functions from graphs',
      'O: Write equations of sine functions using properties',
      'O: Write equations of cosine functions from graphs',
      'O: Write equations of cosine functions using properties',
      'P: determine the values of the trigonometric functions at the special angles and relate them in mathematical and real-world problems',
      'P: Find trigonometric ratios using right triangles',
      'P: Find trigonometric ratios using reference angles'
    ],
    'Mathematics - Relations and geometric reasoning': [
      'A: graph a set of parametric equations',
      'B: convert parametric equations into rectangular relations and convert rectangular relations into parametric equations',
      'C: use parametric equations to model and solve mathematical and real-world problems',
      'D: graph points in the polar coordinate system and convert between rectangular coordinates and polar coordinates',
      'D: Find the modulus and argument of a complex number',
      'D: Convert complex numbers from rectangular to polar form',
      'D: Convert complex numbers from polar to rectangular form',
      'D: Convert complex numbers between rectangular and polar form',
      'E: graph polar equations by plotting points and using technology',
      'E: Match polar equations and graphs',
      'F: determine the conic section formed when a plane intersects a double-napped cone',
      'G: make connections between the locus definition of conic sections and their equations in rectangular coordinates',
      'G: Write equations of parabolas in vertex form',
      'G: Write equations of parabolas in vertex form using properties',
      'G: Write equations of ellipses in standard form',
      'G: Use the characteristics of an ellipse to write the equation of an ellipse with center (h, k)',
      'G: Write equations of hyperbolas in standard form',
      'G: Use the characteristics of a hyperbola to write the equation of a hyperbola with center (h, k)'
    ],
    'Mathematics - Number and measure': [
      'A: determine the relationship between the unit circle and the definition of a periodic function to evaluate trigonometric functions in mathematical and real-world problems',
      'A: Find trigonometric ratios using the unit circle',
      'B: describe the relationship between degree and radian measure on the unit circle',
      'B: Convert between radians and degrees',
      'C: represent angles in radians or degrees based on the concept of rotation and find the measure of reference angles and angles in standard position',
      'C: Radians and arc length',
      'C: Quadrants',
      'C: Coterminal and reference angles',
      'D: represent angles in radians or degrees based on the concept of rotation in mathematical and real-world problems, including linear and angular velocity',
      'D: Radians and arc length',
      'E: determine the value of trigonometric ratios of angles and solve problems involving trigonometric ratios in mathematical and real-world problems',
      'E: Find trigonometric ratios using right triangles',
      'E: Find trigonometric ratios using the unit circle',
      'E: Find trigonometric ratios using reference angles',
      'E: Inverses of trigonometric functions',
      'E: Inverses of trigonometric functions using a calculator',
      'E: Solve trigonometric equations',
      'E: Trigonometric ratios: find a side length',
      'E: Trigonometric ratios: find an angle measure',
      'E: Solve a right triangle',
      'F: use trigonometry in mathematical and real-world problems, including directional bearing',
      'F: Solve a triangle',
      'F: Area of a triangle: sine formula',
      'F: Area of a triangle: Heron\'s formula',
      'G: use the Law of Sines in mathematical and real-world problems',
      'G: Law of Sines',
      'G: Solve a triangle',
      'H: use the Law of Cosines in mathematical and real-world problems',
      'H: Law of Cosines',
      'H: Solve a triangle',
      'I: use vectors to model situations involving magnitude and direction',
      'I: Find the magnitude of a vector',
      'I: Find the component form of a vector',
      'I: Find the direction angle of a vector',
      'I: Find the component form of a vector from its magnitude and direction angle',
      'I: Find the magnitude and direction of a vector sum',
      'I: Find the magnitude of a vector scalar multiple',
      'I: Determine the direction of a vector scalar multiple',
      'I: Find a unit vector',
      'J: represent the addition of vectors and the multiplication of a vector by a scalar geometrically and symbolically',
      'J: Graph a resultant vector using the triangle method',
      'J: Graph a resultant vector using the parallelogram method',
      'J: Add vectors',
      'J: Multiply a vector by a scalar',
      'J: Find the magnitude of a vector scalar multiple',
      'J: Determine the direction of a vector scalar multiple',
      'K: apply vector addition and multiplication of a vector by a scalar in mathematical and real-world problems',
      'K: Linear combinations of vectors'
    ],
    'Mathematics - Algebraic Reasoning': [
      'A: evaluate finite sums and geometric series, when possible, written in sigma notation',
      'A: Identify arithmetic and geometric series',
      'A: Find the sum of a finite geometric series',
      'B: represent arithmetic sequences and geometric sequences using recursive formulas',
      'B: Find terms of a recursive sequence',
      'B: Find a recursive formula',
      'B: Convert an explicit formula to a recursive formula',
      'C: calculate the nth term and the nth partial sum of an arithmetic series in mathematical and real-world problems',
      'C: Find terms of a sequence',
      'C: Find the sum of an arithmetic series',
      'C: Introduction to partial sums',
      'C: Partial sums of arithmetic series',
      'C: Partial sums: mixed review',
      'D: represent arithmetic series and geometric series using sigma notation',
      'D: Introduction to sigma notation',
      'E: calculate the nth term of a geometric series, the nth partial sum of a geometric series, and sum of an infinite geometric series when it exists',
      'E: Find terms of a sequence',
      'E: Partial sums of geometric series',
      'E: Partial sums: mixed review',
      'E: Convergent and divergent geometric series',
      'E: Find the value of an infinite geometric series',
      'E: Write a repeating decimal as a fraction',
      'F: apply the Binomial Theorem for the expansion of (a + b)^n in powers of a and b for a positive integer n, where a and b are any numbers',
      'F: Pascal\'s triangle',
      'F: Pascal\'s triangle and the Binomial Theorem',
      'F: Binomial Theorem I',
      'F: Binomial Theorem II',
      'G: use the properties of logarithms to evaluate or transform logarithmic expressions',
      'G: Evaluate logarithms',
      'G: Change of base formula',
      'G: Product property of logarithms',
      'G: Quotient property of logarithms',
      'G: Power property of logarithms',
      'G: Evaluate logarithms using properties',
      'H: generate and solve logarithmic equations in mathematical and real-world problems',
      'H: Solve logarithmic equations with one logarithm',
      'H: Solve logarithmic equations with multiple logarithms',
      'I: generate and solve exponential equations in mathematical and real-world problems',
      'I: Solve exponential equations by rewriting the base',
      'I: Solve exponential equations using logarithms',
      'I: Exponential growth and decay: word problems',
      'I: Compound interest: word problems',
      'J: solve polynomial equations with real coefficients by applying a variety of techniques in mathematical and real-world problems',
      'J: Solve quadratic equations: word problems',
      'J: Divide polynomials using long division',
      'J: Divide polynomials using synthetic division',
      'J: Evaluate polynomials using synthetic division',
      'J: Solve equations with sums and differences of cubes',
      'J: Solve equations using a quadratic pattern',
      'J: Find the roots of factored polynomials',
      'J: Rational root theorem',
      'J: Complex conjugate theorem',
      'J: Conjugate root theorems',
      'K: solve polynomial inequalities with real coefficients by applying a variety of techniques and write the solution set of the polynomial inequality in interval notation in mathematical and real-world problems',
      'K: Graph solutions to higher-degree inequalities',
      'K: Solve higher-degree inequalities',
      'L: solve rational inequalities with real coefficients by applying a variety of techniques and write the solution set of the rational inequality in interval notation in mathematical and real-world problems',
      'M: use trigonometric identities such as reciprocal, quotient, Pythagorean, cofunctions, even/odd, and sum and difference identities for cosine and sine to simplify trigonometric expressions',
      'M: Complementary angle identities',
      'M: Symmetry and periodicity of trigonometric functions',
      'M: Find trigonometric ratios using a Pythagorean or reciprocal identity',
      'M: Find trigonometric ratios using multiple identities',
      'M: Trigonometric sum and difference identities',
      'N: generate and solve trigonometric equations in mathematical and real-world problems',
      'N: Solve trigonometric equations',
      'N: Solve trigonometric equations using sum and difference identities'
    ]
  },
  'AP - Precalculus': {
    'Polynomial and Rational Functions': [
      'Change in Tandem',
      'Rates of Change',
      'Rates of Change in Linear and Quadratic Functions',
      'Polynomial Functions and Rates of Change',
      'Polynomial Functions and Complex Zeros',
      'Polynomial Functions and End Behavior',
      'Rational Functions and End Behavior',
      'Rational Functions and Zeros',
      'Rational Functions and Vertical Asymptotes',
      'Rational Functions and Holes',
      'Equivalent Representations of Polynomial and Rational Expressions',
      'Transformations of Functions',
      'Function Model Selection and Assumption Articulation',
      'Function Model Construction and Application'
    ],
    'Exponential and Logarithmic Functions': [
      'Change in Arithmetic and Geometric Sequences',
      'Change in Linear and Exponential Functions',
      'Exponential Functions',
      'Exponential Function Manipulation',
      'Exponential Function Context and Data Modeling',
      'Competing Function Model Validation',
      'Composition of Functions',
      'Inverse Functions',
      'Logarithmic Expressions',
      'Inverses of Exponential Functions',
      'Logarithmic Functions',
      'Logarithmic Function Manipulation',
      'Exponential and Logarithmic Equations and Inequalities',
      'Logarithmic Function Context and Data Modeling',
      'Semi-log Plots'
    ],
    'Trigonometric and Polar Functions': [
      'Periodic Phenomena',
      'Sine, Cosine, and Tangent',
      'Sine and Cosine Function Values',
      'Sine and Cosine Function Graphs',
      'Sinusoidal Functions',
      'Sinusoidal Function Transformations',
      'Sinusoidal Function Context and Data Modeling',
      'The Tangent Function',
      'Inverse Trigonometric Functions',
      'Trigonometric Equations and Inequalities',
      'The Secant, Cosecant, and Cotangent Functions',
      'Equivalent Representations of Trigonometric Functions',
      'Trigonometry and Polar Coordinates',
      'Polar Function Graphs',
      'Rates of Change in Polar Functions'
    ],
    'Functions Involving Parameters, Vectors, and Matrices': [
      'Parametric Functions',
      'Parametric Functions Modeling Planar Motion',
      'Parametric Functions and Rates of Change',
      'Parametrically Defined Circles and Lines',
      'Implicitly Defined Functions',
      'Parametrization of Implicitly Defined Functions',
      'Conic Sections',
      'Vectors',
      'Vector-Valued Functions',
      'Matrices',
      'The Inverse and Determinant of a Matrix',
      'Linear Transformations and Matrices',
      'Matrices as Functions',
      'Matrices Modeling Contexts'
    ]
  },
  'AP - Statistics': {
    'Exploring One-Variable Data': [
      '1.1 Introducing Statistics: What Can We Learn from Data?',
      '1.2 The Language of Variation: Variables',
      '1.3 Representing a Categorical Variable with Tables',
      '1.4 Representing a Categorical Variable with Graphs',
      '1.5 Representing a Quantitative Variable with Graphs',
      '1.6 Describing the Distribution of a Quantitative Variable',
      '1.7 Summary Statistics for a Quantitative Variable',
      '1.8 Graphical Representations of Summary Statistics',
      '1.9 Comparing Distributions of a Quantitative Variable',
      '1.10 The Normal Distribution'
    ],
    'Exploring Two-Variable Data': [
      '2.1 Introducing Statistics: Are Variables Related?',
      '2.2 Representing Two Categorical Variables',
      '2.3 Statistics for Two Categorical Variables',
      '2.4 Representing the Relationship Between Two Quantitative Variables',
      '2.5 Correlation',
      '2.6 Linear Regression Models',
      '2.7 Residuals',
      '2.8 Least Squares Regression',
      '2.9 Analyzing Departures from Linearity'
    ],
    'Collecting Data': [
      '3.1 Introducing Statistics: Do the Data We Collected Tell the Truth?',
      '3.2 Introduction to Planning a Study',
      '3.3 Random Sampling and Data Collection',
      '3.4 Potential Problems with Sampling',
      '3.5 Introduction to Experimental Design',
      '3.6 Selecting an Experimental Design',
      '3.7 Inference and Experiments'
    ],
    'Probability, Random Variables, and Probability Distributions': [
      '4.1 Introducing Statistics: Random and Non-Random Patterns?',
      '4.2 Estimating Probabilities Using Simulation',
      '4.3 Introduction to Probability',
      '4.4 Mutually Exclusive Events',
      '4.5 Conditional Probability',
      '4.6 Independent Events and Unions of Events',
      '4.7 Introduction to Random Variables and Probability Distributions',
      '4.8 Mean and Standard Deviation of Random Variables',
      '4.9 Combining Random Variables',
      '4.10 Introduction to the Binomial Distribution',
      '4.11 Parameters for a Binomial Distribution',
      '4.12 The Geometric Distribution'
    ],
    'Sampling Distributions': [
      '5.1 Introducing Statistics: Why Is My Sample Not Like Yours?',
      '5.2 The Normal Distribution, Revisited',
      '5.3 The Central Limit Theorem',
      '5.4 Biased and Unbiased Point Estimates',
      '5.5 Sampling Distributions for Sample Proportions',
      '5.6 Sampling Distributions for Differences in Sample Proportions',
      '5.7 Sampling Distributions for Sample Means',
      '5.8 Sampling Distributions for Differences in Sample Means'
    ],
    'Inference for Categorical Data: Proportions': [
      '6.1 Introducing Statistics: Why Be Normal?',
      '6.2 Constructing a Confidence Interval for a Population Proportion',
      '6.3 Justifying a Claim Based on a Confidence Interval for a Population Proportion',
      '6.4 Setting Up a Test for a Population Proportion',
      '6.5 Interpreting p-Values',
      '6.6 Concluding a Test for a Population Proportion',
      '6.7 Potential Errors When Performing Tests',
      '6.8 Confidence Intervals for the Difference of Two Proportions',
      '6.9 Justifying a Claim Based on a Confidence Interval for a Difference of Population Proportions',
      '6.10 Setting Up a Test for the Difference of Two Population Proportions',
      '6.11 Carrying Out a Test for the Difference of Two Population Proportions'
    ],
    'Inference for Quantitative Data: Means': [
      '7.1 Introducing Statistics: Should I Worry About Error?',
      '7.2 Constructing a Confidence Interval for a Population Mean',
      '7.3 Justifying a Claim About a Population Mean Based on a Confidence Interval',
      '7.4 Setting Up a Test for a Population Mean',
      '7.5 Carrying Out a Test for a Population Mean',
      '7.6 Confidence Intervals for the Difference of Two Means',
      '7.7 Justifying a Claim About the Difference of Two Means Based on a Confidence Interval',
      '7.8 Setting Up a Test for the Difference of Two Population Means',
      '7.9 Carrying Out a Test for the Difference of Two Population Means',
      '7.10 Skills Focus: Selecting, Implementing, and Communicating Inference Procedures'
    ],
    'Inference for Categorical Data: Chi-Square': [
      '8.1 Introducing Statistics: Are My Results Unexpected?',
      '8.2 Setting Up a Chi-Square Goodness of Fit Test',
      '8.3 Carrying Out a Chi-Square Test for Goodness of Fit',
      '8.4 Expected Counts in Two-Way Tables',
      '8.5 Setting Up a Chi-Square Test for Homogeneity or Independence',
      '8.6 Carrying Out a Chi-Square Test for Homogeneity or Independence',
      '8.7 Skills Focus: Selecting an Appropriate Inference Procedure for Categorical Data'
    ],
    'Inference for Quantitative Data: Slopes': [
      '9.1 Introducing Statistics: Do Those Points Align?',
      '9.2 Confidence Intervals for the Slope of a Regression Model',
      '9.3 Justifying a Claim About the Slope of a Regression Model Based on a Confidence Interval',
      '9.4 Setting Up a Test for the Slope of a Regression Model',
      '9.5 Carrying Out a Test for the Slope of a Regression Model',
      '9.6 Skills Focus: Selecting an Appropriate Inference Procedure'
    ]
  },
  'AP - Calculus AB': {
    'Limits and Continuity': [
      '1.1 Introducing Calculus: Can Change Occur at an Instant?',
      '1.2 Defining Limits and Using Limit Notation',
      '1.3 Estimating Limit Values from Graphs',
      '1.4 Estimating Limit Values from Tables',
      '1.5 Determining Limits Using Algebraic Properties of Limits',
      '1.6 Determining Limits Using Algebraic Manipulation',
      '1.7 Selecting Procedures for Determining Limits',
      '1.8 Determining Limits Using the Squeeze Theorem',
      '1.9 Connecting Multiple Representations of Limits',
      '1.10 Exploring Types of Discontinuities',
      '1.11 Defining Continuity at a Point',
      '1.12 Confirming Continuity over an Interval',
      '1.13 Removing Discontinuities',
      '1.14 Connecting Infinite Limits and Vertical Asymptotes',
      '1.15 Connecting Limits at Infinity and Horizontal Asymptotes',
      '1.16 Working with the Intermediate Value Theorem (IVT)'
    ],
    'Differentiation: Definition and Basic Derivative Rules': [
      '2.1 Defining Average and Instantaneous Rates of Change at a Point',
      '2.2 Defining the Derivative of a Function and Using Derivative Notation',
      '2.3 Estimating Derivatives of a Function at a Point',
      '2.4 Connecting Differentiability and Continuity: Determining When Derivatives Do and Do Not Exist',
      '2.5 Applying the Power Rule',
      '2.6 Derivative Rules: Constant, Sum, Difference, and Constant Multiple',
      '2.7 Derivatives of cos x, sin x, eˣ, and ln x',
      '2.8 The Product Rule',
      '2.9 The Quotient Rule',
      '2.10 Finding the Derivatives of Tangent, Cotangent, Secant, and/or Cosecant Functions'
    ],
    'Differentiation: Composite, Implicit, and Inverse Functions': [
      '3.1 The Chain Rule',
      '3.2 Implicit Differentiation',
      '3.3 Differentiating Inverse Functions',
      '3.4 Differentiating Inverse Trigonometric Functions',
      '3.5 Selecting Procedures for Calculating Derivatives',
      '3.6 Calculating Higher-Order Derivatives'
    ],
    'Contextual Applications of Differentiation': [
      '4.1 Interpreting the Meaning of the Derivative in Context',
      '4.2 Straight-Line Motion: Connecting Position, Velocity, and Acceleration',
      '4.3 Rates of Change in Applied Contexts Other Than Motion',
      '4.4 Introduction to Related Rates',
      '4.5 Solving Related Rates Problems',
      '4.6 Approximating Values of a Function Using Local Linearity and Linearization',
      '4.7 Using L’Hospital’s Rule for Determining Limits of Indeterminate Forms'
    ],
    'Analytical Applications of Differentiation': [
      '5.1 Using the Mean Value Theorem',
      '5.2 Extreme Value Theorem, Global Versus Local Extrema, and Critical Points',
      '5.3 Determining Intervals on Which a Function Is Increasing or Decreasing',
      '5.4 Using the First Derivative Test to Determine Relative (Local) Extrema',
      '5.5 Using the Candidates Test to Determine Absolute (Global) Extrema',
      '5.6 Determining Concavity of Functions over Their Domains',
      '5.7 Using the Second Derivative Test to Determine Extrema',
      '5.8 Sketching Graphs of Functions and Their Derivatives',
      '5.9 Connecting a Function, Its First Derivative, and Its Second Derivative',
      '5.10 Introduction to Optimization Problems',
      '5.11 Solving Optimization Problems',
      '5.12 Exploring Behaviors of Implicit Relations'
    ],
    'Integration and Accumulation of Change': [
      '6.1 Exploring Accumulations of Change',
      '6.2 Approximating Areas with Riemann Sums',
      '6.3 Riemann Sums, Summation Notation, and Definite Integral Notation',
      '6.4 The Fundamental Theorem of Calculus and Accumulation Functions',
      '6.5 Interpreting the Behavior of Accumulation Functions Involving Area',
      '6.6 Applying Properties of Definite Integrals',
      '6.7 The Fundamental Theorem of Calculus and Definite Integrals',
      '6.8 Finding Antiderivatives and Indefinite Integrals: Basic Rules and Notation',
      '6.9 Integrating Using Substitution',
      '6.10 Integrating Functions Using Long Division and Completing the Square',
      '6.11 Integrating Using Integration by Parts',
      '6.12 Using Linear Partial Fractions',
      '6.13 Evaluating Improper Integrals',
      '6.14 Selecting Techniques for Antidifferentiation'
    ],
    'Differential Equations': [
      '7.1 Modeling Situations with Differential Equations',
      '7.2 Verifying Solutions for Differential Equations',
      '7.3 Sketching Slope Fields',
      '7.4 Reasoning Using Slope Fields',
      '7.5 Approximating Solutions Using Euler’s Method',
      '7.6 Finding General Solutions Using Separation of Variables',
      '7.7 Finding Particular Solutions Using Initial Conditions and Separation of Variables',
      '7.8 Exponential Models with Differential Equations',
      '7.9 Logistic Models with Differential Equations'
    ],
    'Applications of Integration': [
      '8.1 Finding the Average Value of a Function on an Interval',
      '8.2 Connecting Position, Velocity, and Acceleration of Functions Using Integrals',
      '8.3 Using Accumulation Functions and Definite Integrals in Applied Contexts',
      '8.4 Finding the Area Between Curves Expressed as Functions of x',
      '8.5 Finding the Area Between Curves Expressed as Functions of y',
      '8.6 Finding the Area Between Curves That Intersect at More Than Two Points',
      '8.7 Volumes with Cross Sections: Squares and Rectangles',
      '8.8 Volumes with Cross Sections: Triangles and Semicircles',
      '8.9 Volume with Disc Method: Revolving Around the x- or y-Axis',
      '8.10 Volume with Disc Method: Revolving Around Other Axes',
      '8.11 Volume with Washer Method: Revolving Around the x- or y-Axis',
      '8.12 Volume with Washer Method: Revolving Around Other Axes',
      '8.13 The Arc Length of a Smooth, Planar Curve and Distance Traveled'
    ],
    'Parametric Equations, Polar Coordinates, and Vector-Valued Functions': [
      '9.1 Defining and Differentiating Parametric Equations',
      '9.2 Second Derivatives of Parametric Equations',
      '9.3 Finding Arc Lengths of Curves Given by Parametric Equations',
      '9.4 Defining and Differentiating Vector-Valued Functions',
      '9.5 Integrating Vector-Valued Functions',
      '9.6 Solving Motion Problems Using Parametric and Vector-Valued Functions',
      '9.7 Defining Polar Coordinates and Differentiating in Polar Form',
      '9.8 Finding the Area of a Polar Region or the Area Bounded by a Single Polar Curve',
      '9.9 Finding the Area of the Region Bounded by Two Polar Curves'
    ],
    'Infinite Sequences and Series': [
      '10.1 Defining Convergent and Divergent Infinite Series',
      '10.2 Working with Geometric Series',
      '10.3 The nth Term Test for Divergence',
      '10.4 Integral Test for Convergence',
      '10.5 Harmonic Series and p-Series',
      '10.6 Comparison Tests for Convergence',
      '10.7 Alternating Series Test for Convergence',
      '10.8 Ratio Test for Convergence',
      '10.9 Determining Absolute or Conditional Convergence',
      '10.10 Alternating Series Error Bound',
      '10.11 Finding Taylor Polynomial Approximations of Functions',
      '10.12 Lagrange Error Bound',
      '10.13 Radius and Interval of Convergence of Power Series',
      '10.14 Finding Taylor or Maclaurin Series for a Function',
      '10.15 Representing Functions as Power Series'
    ]
  },
  'AP - Calculus BC': {
    'Limits and Continuity': [
      'Change: Introducing Calculus: Can Change Occur at an Instant?',
      'Limits: Defining Limits and Using Limit Notation',
      'Change: Estimating Limit Values from Graphs',
      'Limits: Estimating Limit Values from Tables',
      'Limits: Determining Limits Using Algebraic Properties of Limits',
      'Limits: Determining Limits Using Algebraic Manipulation',
      'Limits: Selecting Procedures for Determining Limits',
      'Limits: Determining Limits Using the Squeeze Theorem',
      'Limits: Connecting Multiple Representations of Limits',
      'Limits: Exploring Types of Discontinuities',
      'Analysis of Functions: Defining Continuity at a Point',
      'Limits: Confirming Continuity over an Interval',
      'Limits: Removing Discontinuities',
      'Limits: Connecting Infinite Limits and Vertical Asymptotes',
      'Limits: Connecting Limits at Infinity and Horizontal Asymptotes',
      'Limits: Working with the Intermediate Value Theorem (IVT)'
    ],
    'Differentiation: Definition and Basic Derivative Rules': [
      'Change: Defining Average and Instantaneous Rates of Change at a Point',
      'Analysis of Functions: Defining the Derivative of a Function and Using Derivative Notation',
      'Change: Estimating Derivatives of a Function at a Point',
      'Analysis of Functions: Connecting Differentiability and Continuity: Determining When Derivatives Do and Do Not Exist',
      'Analysis of Functions: Applying the Power Rule',
      'Analysis of Functions: Derivative Rules: Constant, Sum, Difference, and Constant Multiple',
      'Analysis of Functions: Derivatives of cos x, sin x, e^x, and ln x',
      'Analysis of Functions: The Product Rule',
      'Analysis of Functions: The Quotient Rule',
      'Analysis of Functions: Finding the Derivatives of Tangent, Cotangent, Secant, and/or Cosecant Functions (BC ONLY)'
    ],
    'Differentiation: Composite, Implicit, and Inverse Functions': [
      'Analysis of Functions: The Chain Rule',
      'Analysis of Functions: Implicit Differentiation',
      'Analysis of Functions: Differentiating Inverse Functions',
      'Analysis of Functions: Differentiating Inverse Trigonometric Functions',
      'Analysis of Functions: Selecting Procedures for Calculating Derivatives',
      'Analysis of Functions: Calculating Higher-Order Derivatives'
    ],
    'Contextual Applications of Differentiation': [
      'Change: Interpreting the Meaning of the Derivative in Context',
      'Change: Straight-Line Motion: Connecting Position, Velocity, and Acceleration',
      'Change: Rates of Change in Applied Contexts Other Than Motion',
      'Change: Introduction to Related Rates',
      'Change: Solving Related Rates Problems',
      'Change: Approximating Values of a Function Using Local Linearity and Linearization',
      'Limits: Using L’Hospital’s Rule for Determining Limits of Indeterminate Forms'
    ],
    'Analytical Applications of Differentiation': [
      'Analysis of Functions: Using the Mean Value Theorem',
      'Analysis of Functions: Extreme Value Theorem, Global Versus Local Extrema, and Critical Points',
      'Analysis of Functions: Determining Intervals on Which a Function Is Increasing or Decreasing',
      'Analysis of Functions: Using the First Derivative Test to Determine Relative (Local) Extrema',
      'Analysis of Functions: Using the Candidates Test to Determine Absolute (Global) Extrema',
      'Analysis of Functions: Determining Concavity of Functions over Their Domains',
      'Analysis of Functions: Using the Second Derivative Test to Determine Extrema',
      'Analysis of Functions: Sketching Graphs of Functions and Their Derivatives',
      'Analysis of Functions: Connecting a Function, Its First Derivative, and Its Second Derivative',
      'Analysis of Functions: Introduction to Optimization Problems',
      'Analysis of Functions: Solving Optimization Problems',
      'Analysis of Functions: Exploring Behaviors of Implicit Relations'
    ],
    'Integration and Accumulation of Change': [
      'Change: Exploring Accumulations of Change',
      'Limits: Approximating Areas with Riemann Sums',
      'Limits: Riemann Sums, Summation Notation, and Definite Integral Notation',
      'Analysis of Functions: The Fundamental Theorem of Calculus and Accumulation Functions',
      'Analysis of Functions: Interpreting the Behavior of Accumulation Functions Involving Area',
      'Analysis of Functions: Applying Properties of Definite Integrals',
      'Analysis of Functions: The Fundamental Theorem of Calculus and Definite Integrals',
      'Analysis of Functions: Finding Antiderivatives and Indefinite Integrals: Basic Rules and Notation',
      'Analysis of Functions: Integrating Using Substitution',
      'Analysis of Functions: Integrating Functions Using Long Division and Completing the Square',
      'Analysis of Functions: Integrating Using Integration by Parts (BC ONLY)',
      'Analysis of Functions: Using Linear Partial Fractions (BC ONLY)',
      'Limits: Evaluating Improper Integrals (BC ONLY)',
      'Analysis of Functions: Selecting Techniques for Antidifferentiation'
    ],
    'Differential Equations': [
      'Analysis of Functions: Modeling Situations with Differential Equations',
      'Analysis of Functions: Verifying Solutions for Differential Equations',
      'Analysis of Functions: Sketching Slope Fields',
      'Analysis of Functions: Reasoning Using Slope Fields',
      'Analysis of Functions: Approximating Solutions Using Euler’s Method (BC ONLY)',
      'Analysis of Functions: Finding General Solutions Using Separation of Variables',
      'Analysis of Functions: Finding Particular Solutions Using Initial Conditions and Separation of Variables',
      'Analysis of Functions: Exponential Models with Differential Equations',
      'Analysis of Functions: Logistic Models with Differential Equations (BC ONLY)'
    ],
    'Applications of Integration': [
      'Change: Finding the Average Value of a Function on an Interval',
      'Change: Connecting Position, Velocity, and Acceleration of Functions Using Integrals',
      'Change: Using Accumulation Functions and Definite Integrals in Applied Contexts',
      'Change: Finding the Area Between Curves Expressed as Functions of x',
      'Change: Finding the Area Between Curves Expressed as Functions of y',
      'Change: Finding the Area Between Curves That Intersect at More Than Two Points',
      'Change: Volumes with Cross Sections: Squares and Rectangles',
      'Change: Volumes with Cross Sections: Triangles and Semicircles',
      'Change: Volume with Disc Method: Revolving Around the x- or y-Axis',
      'Change: Volume with Disc Method: Revolving Around Other Axes',
      'Change: Volume with Washer Method: Revolving Around the x- or y-Axis',
      'Change: Volume with Washer Method: Revolving Around Other Axes',
      'Change: The Arc Length of a Smooth, Planar Curve and Distance Traveled (BC ONLY)'
    ],
    'Parametric Equations, Polar Coordinates, and Vector-Valued Functions': [
      'Change: Defining and Differentiating Parametric Equations',
      'Change: Second Derivatives of Parametric Equations',
      'Change: Finding Arc Lengths of Curves Given by Parametric Equations',
      'Change: Defining and Differentiating Vector-Valued Functions',
      'Analysis of Functions: Integrating Vector-Valued Functions',
      'Analysis of Functions: Solving Motion Problems Using Parametric and Vector-Valued Functions',
      'Analysis of Functions: Defining Polar Coordinates and Differentiating in Polar Form',
      'Change: Finding the Area of a Polar Region or the Area Bounded by a Single Polar Curve',
      'Change: Finding the Area of the Region Bounded by Two Polar Curves'
    ],
    'Infinite Sequences and Series': [
      'Limits: Defining Convergent and Divergent Infinite Series',
      'Limits: Working with Geometric Series',
      'Limits: The nth Term Test for Divergence',
      'Limits: Integral Test for Convergence',
      'Limits: Harmonic Series and p-Series',
      'Limits: Comparison Tests for Convergence',
      'Limits: Alternating Series Test for Convergence',
      'Limits: Ratio Test for Convergence',
      'Limits: Determining Absolute or Conditional Convergence',
      'Limits: Alternating Series Error Bound',
      'Limits: Finding Taylor Polynomial Approximations of Functions',
      'Limits: Lagrange Error Bound',
      'Limits: Radius and Interval of Convergence of Power Series',
      'Limits: Finding Taylor or Maclaurin Series for a Function',
      'Limits: Representing Functions as Power Series'
    ]
  },
  'AP - Computer Science A': {
    'Primitive Types': [
      '1.1 Why Programming? Why Java?',
      '1.2 Variables and Data Types',
      '1.3 Expressions and Assignment Statements',
      '1.4 Compound Assignment Operators',
      '1.5 Casting and Ranges of Variables'
    ],
    'Using Objects': [
      '2.1 Objects: Instances of Classes',
      '2.2 Creating and Storing Objects (Instantiation)',
      '2.3 Calling a Void Method',
      '2.4 Calling a Void Method with Parameters',
      '2.5 Calling a Non-void Method',
      '2.6 String Objects: Concatenation, Literals, and More',
      '2.7 String Methods',
      '2.8 Wrapper Classes: Integer and Double',
      '2.9 Using the Class Math'
    ],
    'Boolean Expressions and if Statements': [
      '3.1 Boolean Expressions',
      '3.2 if Statements and Control Flow',
      '3.3 if-else Statements',
      '3.4 else if Statements',
      '3.5 Compound Boolean Expressions',
      '3.6 Equivalent Boolean Expressions',
      '3.7 Comparing Objects'
    ],
    'Iteration': [
      '4.1 while Loops',
      '4.2 for Loops',
      '4.3 Developing Algorithms Using Strings',
      '4.4 Nested Iteration',
      '4.5 Informal Code Analysis'
    ],
    'Writing Classes': [
      '5.1 Anatomy of a Class',
      '5.2 Constructors',
      '5.3 Documentation with Comments',
      '5.4 Accessor Methods',
      '5.5 Mutator Methods',
      '5.6 Writing Methods',
      '5.7 Static Variables and Methods',
      '5.8 Scope and Access',
      '5.9 this Keyword',
      '5.10 Ethical and Social Implications of Computing Systems'
    ],
    'Array': [
      '6.1 Array Creation and Access',
      '6.2 Traversing Arrays',
      '6.3 Enhanced for Loop for Arrays',
      '6.4 Developing Algorithms Using Arrays'
    ],
    'ArrayList': [
      '7.1 Introduction to ArrayList',
      '7.2 ArrayList Methods',
      '7.3 Traversing ArrayLists',
      '7.4 Developing Algorithms Using ArrayLists',
      '7.5 Searching',
      '7.6 Sorting',
      '7.7 Ethical Issues Around Data Collection'
    ],
    '2D Array': [
      '8.1 2D Arrays',
      '8.2 Traversing 2D Arrays'
    ],
    'Inheritance': [
      '9.1 Creating Superclasses and Subclasses',
      '9.2 Writing Constructors for Subclasses',
      '9.3 Overriding Methods',
      '9.4 Keyword super',
      '9.5 Creating References Using Inheritance Hierarchies',
      '9.6 Polymorphism',
      '9.7 Object Superclass'
    ],
    'Recursion': [
      '10.1 Recursion',
      '10.2 Recursive Searching and Sorting'
    ]
  },
  'AP - Computer Science Principles': {
    'Creative Development': [
      '1.1 Collaboration',
      '1.2 Program Function and Purpose',
      '1.3 Program Design and Development',
      '1.4 Identifying and Correcting Errors'
    ],
    'Data': [
      '2.1 Binary Numbers',
      '2.2 Data Compression',
      '2.3 Extracting Information from Data',
      '2.4 Using Programs with Data'
    ],
    'Algorithms and Programming': [
      '3.1 Variables and Assignments',
      '3.2 Data Abstraction',
      '3.3 Mathematical Expressions',
      'Strings',
      '3.5 Boolean Expressions',
      '3.6 Conditionals',
      '3.7 Nested Conditionals',
      '3.8 Iteration',
      '3.9 Developing Algorithms',
      'Lists',
      '3.11 Binary Search',
      'Developing Procedures',
      'Calling Procedures',
      'Random Values',
      '3.18 Undecidable Problems',
      'Algorithmic Efficiency',
      'Simulations',
      'Libraries'
    ],
    'Computer Systems and Networks': [
      '4.1 The Internet',
      '4.2 Fault Tolerance',
      '4.3 Parallel and Distributed Computing'
    ],
    'Impact of Computing': [
      '5.1 Beneficial and Harmful Effects',
      '5.2 Digital Divide',
      '5.3 Computing Bias',
      '5.4 Crowdsourcing',
      '5.5 Legal and Ethical Concerns',
      '5.6 Safe Computing'
    ]
  },
  'AP - Chemistry': {
  'Atomic Structure and Properties': [
    '1.1 Moles and Molar Mass',
    '1.2 Mass Spectra of Elements',
    '1.3 Elemental Composition of Pure Substances',
    '1.4 Composition of Mixtures',
    '1.5 Atomic Structure and Electron Configuration',
    '1.6 Photoelectron Spectroscopy',
    '1.7 Periodic Trends',
    '1.8 Valence Electrons and Ionic Compounds'
  ],
  'Compound Structure and Properties': [
    '2.1 Types of Chemical Bonds',
    '2.2 Intramolecular Force and Potential Energy',
    '2.3 Structure of Ionic Solids',
    '2.4 Structure of Metals and Alloys',
    '2.5 Lewis Diagrams',
    '2.6 Resonance and Formal Charge',
    '2.7 VSEPR and Hybridization'
  ],
  'Properties of Substances and Mixtures': [
    '3.1 Intermolecular and Interparticle Forces',
    '3.2 Properties of Solids',
    '3.3 Solids, Liquids, and Gases',
    '3.4 Ideal Gas Law',
    '3.5 Kinetic Molecular Theory',
    '3.6 Deviation from Ideal Gas Law',
    '3.7 Solutions and Mixtures',
    '3.8 Representations of Solutions',
    '3.9 Separation of Solutions and Mixtures',
    '3.10 Solubility',
    '3.11 Spectroscopy and the Electromagnetic Spectrum',
    '3.12 Properties of Photons',
    '3.13 Beer-Lambert Law'
  ],
  'Chemical Reactions': [
    '4.1 Introduction for Reactions',
    '4.2 Net Ionic Equations',
    '4.3 Representations of Reactions',
    '4.4 Physical and Chemical Changes',
    '4.5 Stoichiometry',
    '4.6 Introduction to Titration',
    '4.7 Types of Chemical Reactions',
    '4.8 Introduction to Acid-Base Reactions',
    '4.9 Oxidation-Reduction (Redox) Reactions'
  ],
  'Kinetics': [
    '5.1 Reaction Rates',
    '5.2 Introduction to Rate Law',
    '5.3 Concentration Changes Over Time',
    '5.4 Elementary Reactions',
    '5.5 Collision Model',
    '5.6 Reaction Energy Profile',
    '5.7 Introduction to Reaction Mechanisms',
    '5.8 Reaction Mechanism and Rate Law',
    '5.9 Pre-Equilibrium Approximation',
    '5.10 Multistep Reaction Energy Profile',
    '5.11 Catalysis'
  ],
  'Thermochemistry': [
    '6.1 Endothermic and Exothermic Processes',
    '6.2 Energy Diagrams',
    '6.3 Heat Transfer and Thermal Equilibrium',
    '6.4 Heat Capacity and Calorimetry',
    '6.5 Energy of Phase Changes',
    '6.6 Introduction to Enthalpy of Reaction',
    '6.7 Bond Enthalpies',
    '6.8 Enthalpy of Formation',
    '6.9 Hess’s Law'
  ],
  'Equilibrium': [
    '7.1 Introduction to Equilibrium',
    '7.2 Direction of Reversible Reactions',
    '7.3 Reaction Quotient and Equilibrium Constant',
    '7.4 Calculating the Equilibrium Constant',
    '7.5 Magnitude of the Equilibrium Constant',
    '7.6 Properties of the Equilibrium Constant',
    '7.7 Calculating Equilibrium Concentrations',
    '7.8 Representations of Equilibrium',
    '7.9 Introduction to Le Châtelier’s Principle',
    '7.10 Reaction Quotient and Le Châtelier’s Principle',
    '7.11 Introduction to Solubility Equilibria',
    '7.12 Common-Ion Effect'
  ],
  'Acids and Bases': [
    '8.1 Introduction to Acids and Bases',
    '8.2 pH and pOH of Strong Acids and Bases',
    '8.3 Weak Acid and Base Equilibria',
    '8.4 Acid-Base Reactions and Buffers',
    '8.5 Acid-Base Titrations',
    '8.6 Molecular Structure of Acids and Bases',
    '8.7 pH and pKa',
    '8.8 Properties of Buffers',
    '8.9 Henderson-Hasselbalch Equation',
    '8.10 Buffer Capacity',
    '8.11 pH and Solubility'
  ],
  'Thermodynamics and Electrochemistry': [
    '9.1 Introduction to Entropy',
    '9.2 Absolute Entropy and Entropy Change',
    '9.3 Gibbs Free Energy and Thermodynamic Favorability',
    '9.4 Thermodynamic and Kinetic Control',
    '9.5 Free Energy and Equilibrium',
    '9.6 Free Energy of Dissolution',
    '9.7 Coupled Reactions',
    '9.8 Galvanic (Voltaic) and Electrolytic Cells',
    '9.9 Cell Potential and Free Energy',
    '9.10 Cell Potential Under Nonstandard Conditions',
    '9.11 Electrolysis and Faraday’s Law'
  ]
  },
  'AP - Biology': {
    'Chemistry of Life': [
      'SYI 1.1 Structure of Water and Hydrogen Bonding',
      'ENE Elements of Life',
      'SYI Introduction to Biological Macromolecules',
      'SYI 1.4 Properties of Biological Macromolecules',
      'SYI 1.5 Structure and Function of Biological Macromolecules',
      'IST 1.6 Nucleic Acids'
    ],
    'Cell Structure and Function': [
      'SYI Cell Structure: Subcellular Components',
      'SYI 2.2 Cell Structure and Function',
      'ENE 2.3 Cell Size',
      'ENE 2.4 Plasma Membranes',
      'ENE 2.5 Membrane Permeability',
      'ENE 2.6 Membrane Transport',
      'ENE 2.7 Facilitated Diffusion',
      'ENE 2.8 Tonicity and Osmoregulation',
      'ENE 2.9 Mechanisms of Transport',
      'ENE 2.10 Cell Compartmentalization',
      'EVO Origins of Cell Compartmentalization'
    ],
    'Cellular Energetics': [
      'ENE Enzyme Structure',
      'ENE 3.2 Enzyme Catalysis',
      'ENE 3.3 Environmental Impacts on Enzyme Function',
      'ENE 3.4 Cellular Energy',
      'ENE 3.5 Photosynthesis',
      'ENE 3.6 Cellular Respiration',
      'SYI 3.7 Fitness'
    ],
    'Cell Communication and Cell Cycle': [
      'IST 4.1 Cell Communication',
      'IST 4.2 Introduction to Signal Transduction',
      'IST 4.3 Signal Transduction',
      'IST Changes in Signal Transduction Pathways',
      'ENE 4.5 Feedback',
      'IST 4.6 Cell Cycle',
      'IST 4.7 Regulation of Cell Cycle'
    ],
    'Heredity': [
      'IST 5.1 Meiosis',
      'IST 5.2 Meiosis and Genetic Diversity',
      'EVO 5.3 Mendelian Genetics',
      'IST 5.4 Non-Mendelian Genetics',
      'SYI 5.5 Environmental Effects on Phenotype',
      'SYI 5.6 Chromosomal Inheritance'
    ],
    'Gene Expression and Regulation': [
      'IST 6.1 DNA and RNA Structure',
      'IST Replication',
      'IST 6.3 Transcription and RNA Processing',
      'IST Translation',
      'IST 6.5 Regulation of Gene Expression',
      'IST 6.6 Gene Expression and Cell Specialization',
      'IST 6.7 Mutations',
      'IST 6.8 Biotechnology'
    ],
    'Natural Selection': [
      'EVO 7.1 Introduction to Natural Selection',
      'EVO Natural Selection',
      'EVO 7.3 Artificial Selection',
      'EVO 7.4 Population Genetics',
      'EVO Hardy-Weinberg Equilibrium',
      'EVO 7.6 Evidence of Evolution',
      'EVO Common Ancestry',
      'EVO 7.8 Continuing Evolution',
      'EVO Phylogeny',
      'EVO Speciation',
      'EVO Extinction',
      'SYI 7.12 Variations in Populations',
      'SYI Origin of Life on Earth'
    ],
    'Ecology': [
      'ENE 8.1 Responses to the Environment',
      'ENE 8.2 Energy Flow Through Ecosystems',
      'SYI 8.3 Population Ecology',
      'SYI 8.4 Effect of Density of Populations',
      'ENE 8.5 Community Ecology',
      'SYI 8.6 Biodiversity',
      'EVO 8.7 Disruptions to Ecosystems'
    ]
  },
  'AP - Physics 1': {
    'Kinematics': [
      'Scalars and Vectors in One Dimension',
      'Displacement, Velocity, and Acceleration',
      'Representing Motion',
      'Reference Frames and Relative Motion',
      'Vectors and Motion in Two Dimensions'
    ],
    'Force and Translational Dynamics': [
      'Systems and Center of Mass',
      'Forces and Free-Body Diagrams',
      'Newton’s Third Law',
      'Newton’s First Law',
      'Newton’s Second Law',
      'Gravitational Force',
      'Kinetic and Static Friction',
      'Spring Forces',
      'Circular Motion'
    ],
    'Work, Energy, and Power': [
      'Translational Kinetic Energy',
      'Work',
      'Potential Energy',
      'Conservation of Energy',
      'Power'
    ],
    'Linear Momentum': [
      'Linear Momentum',
      'Change in Momentum and Impulse',
      'Conservation of Linear Momentum',
      'Elastic and Inelastic Collisions'
    ],
    'Torque and Rotational Dynamics': [
      'Rotational Kinematics',
      'Connecting Linear and Rotational Motion',
      'Torque',
      'Rotational Inertia',
      'Rotational Equilibrium and Newton’s First Law in Rotational Form',
      'Newton’s Second Law in Rotational Form'
    ],
    'Energy and Momentum of Rotating Systems': [
      'Rotational Kinetic Energy',
      'Torque and Work',
      'Angular Momentum and Angular Impulse',
      'Conservation of Angular Momentum',
      'Rolling',
      'Motion of Orbiting Satellites'
    ],
    'Oscillations': [
      'Defining Simple Harmonic Motion (SHM)',
      'Frequency and Period of SHM',
      'Representing and Analyzing SHM',
      'Energy of Simple Harmonic Oscillators'
    ],
    'Fluids': [
      'Internal Structure and Density',
      'Pressure',
      'Fluids and Newton’s Laws',
      'Fluids and Conservation Laws'
    ]
  },
  'AP - Physics 2': {
    'Thermodynamics': [
      'Kinetic Theory of Temperature and Pressure',
      'The Ideal Gas Law',
      'Thermal Energy Transfer and Equilibrium',
      'The First Law of Thermodynamics',
      'Specific Heat and Thermal Conductivity',
      'Entropy and the Second Law of Thermodynamics'
    ],
    'Electric Force, Field, and Potential': [
      'Electric Charge and Electric Force',
      'Conservation of Electric Charge and the Process of Charging',
      'Electric Fields',
      'Electric Potential Energy',
      'Electric Potential',
      'Capacitors',
      'Conservation of Electric Energy'
    ],
    'Electric Circuits': [
      'Electric Current',
      'Simple Circuits',
      'Resistance, Resistivity, and Ohm’s Law',
      'Electric Power',
      'Compound Direct Current (DC) Circuits',
      'Kirchhoff’s Loop Rule',
      'Kirchhoff’s Junction Rule',
      'Resistor-Capacitor (RC) Circuits'
    ],
    'Magnetism and Electromagnetism': [
      'Magnetic Fields',
      'Magnetism and Moving Charges',
      'Magnetism and Current-Carrying Wires',
      'Electromagnetic Induction and Faraday’s Law'
    ],
    'Geometric Optics': [
      'Reflection',
      'Images Formed by Mirrors',
      'Refraction',
      'Images Formed by Lenses'
    ],
    'Waves, Sound, and Physical Optics': [
      'Properties of Wave Pulses and Waves',
      'Periodic Waves',
      'Boundary Behavior of Waves and Polarization',
      'Electromagnetic Waves',
      'The Doppler Effect',
      'Wave Interference and Standing Waves',
      'Diffraction',
      'Double-Slit Interference and Diffraction Gratings',
      'Thin-Film Interference'
    ],
    'Modern Physics': [
      'Quantum Theory and Wave-Particle Duality',
      'The Bohr Model of Atomic Structure',
      'Emission and Absorption Spectra',
      'Blackbody Radiation',
      'The Photoelectric Effect',
      'Compton Scattering',
      'Fission, Fusion, and Nuclear Decay',
      'Types of Radioactive Decay'
    ]
  }
};